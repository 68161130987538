import { takeLatest } from "redux-saga/effects";

import { ServiceContainer } from "../../app/serviceContainer";

import { itemSearchQueryEntered } from "./itemSearchSlice";
import itemSearchSaga from "./sagas/itemSearchSaga";

export default function* itemSearchSagas(serviceContainer: ServiceContainer) {
  yield takeLatest(
    itemSearchQueryEntered.type,
    itemSearchSaga,
    serviceContainer
  );
}
