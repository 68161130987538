import { ZLayer } from "@ahlsell-group/ui-kit/util";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ErrorAuthInteractionRequiredModal from "../../features/error/components/ErrorAuthInteractionRequiredModal";
import UnhandledError from "../../features/error/components/UnhandledError";
import { selectSagaError } from "../../features/error/errorSelectors";
import useTriggerError from "../../features/error/useTriggerError";
import GettingStartedRoot from "../../features/getting-started/components/GettingStartedRoot";
import InstallModeRoot from "../../features/install-mode/components/InstallModeRoot";
import View from "../../features/routing/components/View";
import routes from "../../features/routing/routes";
import { selectAppInitializationState } from "../appSelectors";

import NavRoot from "./NavRoot";
import useServiceContainer from "./ServiceContainerContext";

const App: React.FC = function App() {
  useTriggerError();
  const initState = useSelector(selectAppInitializationState);
  const sagaError = useSelector(selectSagaError);
  const services = useServiceContainer();
  const [interactionRequiredScopes, setInteractionRequiredScopes] =
    useState<string[]>();

  useEffect(() => {
    const unsubscribe = services.authenticationService.onInteractionRequired(
      (scopes) => {
        setInteractionRequiredScopes(scopes);
      }
    );

    return unsubscribe;
  }, [services]);

  if (sagaError) {
    return (
      <UnhandledError
        error={sagaError}
        // A saga error makes it impossible for future sagas to run.
        onTryAgain={undefined}
      />
    );
  }

  switch (initState) {
    case "starting":
      return (
        <View route={routes.gettingStarted} component={GettingStartedRoot} />
      );

    case "ready":
      return (
        <>
          <NavRoot />

          {interactionRequiredScopes && (
            // Auth errors must be handled by redirecting, so this should
            // always be a modal. Override the Z-index, since this modal is
            // more important than all others.
            <ZLayer overrideZindex={100}>
              <ErrorAuthInteractionRequiredModal
                scopes={interactionRequiredScopes}
              />
            </ZLayer>
          )}
        </>
      );

    case "install-mode":
      return <InstallModeRoot />;

    default:
      return null;
  }
};

export default App;
