import gettingStartedRoutes from "../getting-started/gettingStartedRoutes";
import homeRoutes from "../home/homeRoutes";
import installModeRoutes from "../install-mode/installModeRoutes";
import inventoryLocationRoutes from "../inventory-location/inventoryLocationRoutes";
import itemInformationRoutes from "../item-information/itemInformationRoutes";
import myPageRoutes from "../my-page/myPageRoutes";
import stockTakingRoutes from "../stock-taking/stockTakingRoutes";

import { createRoutes } from "./utils";

export default createRoutes({
  ...homeRoutes,
  ...stockTakingRoutes,
  ...myPageRoutes,
  ...itemInformationRoutes,
  ...gettingStartedRoutes,
  ...installModeRoutes,
  ...inventoryLocationRoutes,
});
