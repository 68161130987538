import { Trans, useTranslation } from "@ahlsell-group/app-localization";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import GettingStartedPage from "../../getting-started/components/GettingStartedPage";
import { selectInstallPrompt } from "../installModeSelectors";
import { triggerInstallPrompt } from "../installModeSlice";

const InstallAndroid: React.FC = function InstallAndroid() {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const isInstallPrompt = useSelector(selectInstallPrompt);

  if (isInstallPrompt) {
    return (
      <GettingStartedPage
        data-cy="InstallAndroid"
        backButton
        actions={{
          install: {
            text: t("installMode.android.install"),
            primary: true,
            "data-cy": "InstallAndroid-install",
          },
        }}
        onAction={() => {
          dispatch(triggerInstallPrompt());
        }}
      >
        <div className="flex flex-col justify-center mt-10">
          <Typography variant="body-sm">
            <ol className="list-decimal pl-4">
              <li>
                <Trans t={t} i18nKey="installMode.android.weRecommendChrome">
                  <Typography className="inline" variant="heading-h5" />
                </Trans>
              </li>
              <li>{t("installMode.android.clickToInstall")}</li>
              <li>{t("installMode.android.openFromHome")}</li>
            </ol>
          </Typography>
        </div>
      </GettingStartedPage>
    );
  }

  return (
    <GettingStartedPage data-cy="InstallAndroid" backButton>
      <div className="flex flex-col justify-center mt-10">
        <Typography variant="body-sm">
          <ol className="list-decimal pl-4">
            <li>
              <Trans t={t} i18nKey="installMode.android.weRecommendChrome">
                <Typography className="inline" variant="heading-h5" />
              </Trans>
            </li>
            <li>{t("installMode.android.openBrowserSettings")}</li>
            <li>
              <Trans t={t} i18nKey="installMode.android.clickAddToHome">
                <Typography className="inline" variant="heading-h5" />
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey="installMode.android.clickAdd">
                <Typography className="inline" variant="heading-h5" />
              </Trans>
            </li>
            <li>{t("installMode.android.openFromHome")}</li>
          </ol>
        </Typography>
      </div>
    </GettingStartedPage>
  );
};

export default InstallAndroid;
