import { all, takeEvery } from "redux-saga/effects";

import { ServiceContainer } from "../../app/serviceContainer";

import { purchaseHoldRequired } from "./purchaseHoldSlice";
import loadPurchaseHoldSaga from "./sagas/loadPurchaseHoldSaga";

export function* purchaseHoldSagas(serviceContainer: ServiceContainer) {
  yield all([
    takeEvery(
      purchaseHoldRequired.type,
      loadPurchaseHoldSaga,
      serviceContainer
    ),
  ]);
}
