import React from "react";

import View from "../../routing/components/View";
import routes from "../../routing/routes";

import ManualStockTakingRoot from "./ManualStockTakingRoot";
import StockTakingMenu from "./StockTakingMenu";
import { SubmittedStockTake } from "./SubmittedStockTake";

const StockTakingRoot: React.FC = function () {
  return (
    <>
      <View component={StockTakingMenu} route={routes.stockTaking} exact />
      <View
        component={ManualStockTakingRoot}
        route={routes.stockTaking.manual}
      />
      <View
        route={routes.stockTaking.submitted}
        component={SubmittedStockTake}
        exact
      />
    </>
  );
};

export default StockTakingRoot;
