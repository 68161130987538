import {
  InventoryLocationItem,
  InventoryLocationItemInfo,
  PickStationWithLocations,
  SavedInventoryLocationChange,
  UpdatedInventoryLocationItem,
  GetInventoryLocationError,
} from "@ahlsell-group/store20-inventory-location-service";
import {
  GetItemErrorReason,
  Item,
} from "@ahlsell-group/store20-product-service";
import { GenericOperationError } from "@ahlsell-group/store20-service-core";
import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";

import ActionType from "../../util/ActionType";
import typescriptAssertNever from "../../util/typescriptAssertNever";
import navigatedFromSubTree from "../routing/navigatedFromSubTree";
import routes from "../routing/routes";

import { Notification } from "./types";

export interface ItemLocationRequiredPayload {
  warehouseId: number;
  itemId: string;
}

export interface ItemLocationLoadedPayload extends InventoryLocationItem {
  warehouseId: number;
  loadedAt: string;
}

export interface InventoryLocationItemWithDetails
  extends InventoryLocationItem {
  details: Item;
}

export interface ItemLocationLoadFailedPayload {
  warehouseId: number;
  itemId: string;
  reason: GetInventoryLocationError | GenericOperationError;
}

const name = "inventoryLocation";

export const itemScanned = createAction<{ barcode: string }>(
  `${name}/itemScanned`
);

export const locationsRequired = createAction(`${name}/locationsRequired`);

export type InventoryLocationSubmitState =
  | { type: "submitted"; items: UpdatedInventoryLocationItem[] }
  | { type: "idle" }
  | { type: "submitting" }
  | { type: "error"; error: ActionType<typeof submitError>["payload"] };

export type InventoryLocationItemState =
  | { type: "none" }
  | { type: "loading"; itemId: string }
  | { type: "loaded"; item: InventoryLocationItemInfo }
  | {
      type: "error";
      itemId: string;
      error: ActionType<typeof itemLoadError>["payload"]["error"];
    };

export type ItemLocationWithState =
  | ({ state: "idle" } & InventoryLocationItem & { loadedAt: string })
  | ({
      state: "loading";
    } & Partial<InventoryLocationItem>)
  | {
      state: "error";
      itemId: string;
      reason: GetItemErrorReason | GenericOperationError;
    };

export interface InventoryLocationState {
  pickStationsWithLocations?: PickStationWithLocations[];
  changeErrors: InventoryLocationItemWithDetails[];
  selectedPickStation?: string;
  selectedLocationId?: string;

  addedItems: InventoryLocationItemInfo[];
  newItem: InventoryLocationItemState;

  isLocationsLoading: boolean;
  loadLocationsError?: ActionType<typeof locationsLoadError>["payload"];

  notification?: Notification;

  submitState: InventoryLocationSubmitState;

  itemLocations: Record<string, ItemLocationWithState>;

  dismissedPendingChangeAlerts: string[];
}

const initialState: InventoryLocationState = {
  pickStationsWithLocations: undefined,
  changeErrors: [],
  selectedPickStation: undefined,
  selectedLocationId: undefined,
  addedItems: [],
  newItem: { type: "none" },

  isLocationsLoading: false,
  loadLocationsError: undefined,

  notification: undefined,

  submitState: { type: "idle" },

  itemLocations: {},

  dismissedPendingChangeAlerts: [],
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    locationsLoading(state) {
      state.isLocationsLoading = true;
      state.loadLocationsError = undefined;
    },
    locationsReceived(
      state,
      { payload }: PayloadAction<PickStationWithLocations[]>
    ) {
      state.pickStationsWithLocations = payload;
      state.isLocationsLoading = false;
      state.loadLocationsError = undefined;
    },
    locationsLoadError(
      state,
      { payload }: PayloadAction<{ reason: GenericOperationError }>
    ) {
      state.loadLocationsError = payload;
      state.isLocationsLoading = false;
    },
    pickStationSelected(state, { payload }: PayloadAction<string>) {
      state.selectedPickStation = payload;
      state.selectedLocationId = undefined;
    },
    locationIdSelected(state, { payload }: PayloadAction<string>) {
      state.selectedLocationId = payload;
    },
    itemAdded(state, { payload }: PayloadAction<InventoryLocationItemInfo>) {
      state.addedItems.unshift(payload);
      state.notification = undefined;
    },
    removeItem(state, { payload }: PayloadAction<{ itemId: string }>) {
      const { itemId } = payload;
      state.addedItems = state.addedItems.filter((x) => x.itemId !== itemId);
      state.notification = { severity: "info", itemId, type: "removed" };
    },
    clearInventoryLocationItems(state) {
      state.addedItems = [];
    },
    setNotification(state, { payload }: PayloadAction<Notification>) {
      state.notification = payload;
    },
    hideNotification(state) {
      state.notification = undefined;
    },
    submitInventoryLocationItems(state) {
      state.submitState = { type: "submitting" };
    },
    submissionsReceived(
      state,
      { payload }: PayloadAction<UpdatedInventoryLocationItem[]>
    ) {
      state.submitState = { type: "submitted", items: payload };
      state.itemLocations = {};
    },
    submitError(
      state,
      { payload }: PayloadAction<{ reason: GenericOperationError }>
    ) {
      state.submitState = { type: "error", error: payload };
    },
    itemRequired(state, { payload }: PayloadAction<{ itemId: string }>) {
      state.newItem = { type: "loading", itemId: payload.itemId };
    },
    itemReceived(state, { payload }: PayloadAction<InventoryLocationItemInfo>) {
      if (state.newItem.type === "loading") {
        state.newItem = { type: "loaded", item: payload };
      }
    },
    itemLoadError(
      state,
      {
        payload,
      }: PayloadAction<{
        itemId: string;
        error: { reason: GetItemErrorReason | GenericOperationError };
      }>
    ) {
      state.newItem = {
        type: "error",
        itemId: payload.itemId,
        error: payload.error,
      };
    },
    itemLocationRequired(
      state,
      { payload }: PayloadAction<ItemLocationRequiredPayload>
    ) {
      const { itemId, warehouseId } = payload;
      state.itemLocations[`${warehouseId}-${itemId}`] ??= {
        state: "loading",
      };
      state.itemLocations[`${warehouseId}-${itemId}`].state = "loading";
    },
    itemLocationLoaded(
      state,
      { payload }: PayloadAction<ItemLocationLoadedPayload>
    ) {
      const { warehouseId, itemId, ...rest } = payload;
      state.itemLocations[`${warehouseId}-${itemId}`] = {
        state: "idle",
        itemId,
        ...rest,
      };
    },
    itemLocationLoadFailed(
      state,
      { payload }: PayloadAction<ItemLocationLoadFailedPayload>
    ) {
      const { warehouseId, itemId, reason } = payload;
      state.itemLocations[`${warehouseId}-${itemId}`] = {
        state: "error",
        itemId,
        reason,
      };
    },
    restoreInventoryLocationChange(
      state,
      { payload }: PayloadAction<SavedInventoryLocationChange>
    ) {
      if (state.addedItems.length === 0 && payload.items) {
        state.addedItems = payload.items;
      }
      if (state.selectedPickStation === undefined) {
        state.selectedPickStation = payload.pickStationId;
      }
      if (
        state.selectedLocationId === undefined &&
        state.selectedPickStation !== undefined
      ) {
        state.selectedLocationId = payload.locationId;
      }
    },
    dismissPendingChangeAlert(state, { payload }: PayloadAction<string>) {
      if (!state.dismissedPendingChangeAlerts.includes(payload)) {
        state.dismissedPendingChangeAlerts.push(payload);
      }
    },
    receivedInventoryLocationChangeErrors(
      state,
      { payload }: PayloadAction<InventoryLocationItemWithDetails[]>
    ) {
      state.changeErrors = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        navigatedFromSubTree(routes.inventoryLocation.change.route),
        (state) => {
          state.loadLocationsError = undefined;
          if (state.submitState.type === "error") {
            state.submitState = { type: "idle" };
          }
        }
      )
      .addMatcher(
        navigatedFromSubTree(routes.inventoryLocation.change.submitted.route),
        (state) => {
          if (
            state.submitState.type === "idle" ||
            state.submitState.type === "submitting"
          ) {
            // Don't do anything.
          } else if (state.submitState.type === "submitted") {
            // The change was submitted. Clear info, so that a new change can
            // be made.
            state.submitState = { type: "idle" };
            state.addedItems = [];
            state.selectedPickStation = undefined;
            state.selectedLocationId = undefined;
          } else if (state.submitState.type === "error") {
            // The change submission failed. Don't clear any info, other than
            // the submission error.
            state.submitState = { type: "idle" };
          } else {
            typescriptAssertNever(state.submitState);
          }
        }
      )
      .addMatcher(
        navigatedFromSubTree(routes.inventoryLocation.change.items.new.route),
        (state) => {
          state.newItem = { type: "none" };
        }
      ),
});

export const {
  reducer: inventoryLocationReducer,
  actions: {
    locationsLoading,
    locationsReceived,
    locationsLoadError,
    pickStationSelected,
    locationIdSelected,
    itemAdded,
    removeItem,
    clearInventoryLocationItems,
    setNotification,
    hideNotification,
    submitInventoryLocationItems,
    submissionsReceived,
    submitError,
    itemRequired,
    itemReceived,
    itemLoadError,
    itemLocationRequired,
    itemLocationLoaded,
    itemLocationLoadFailed,
    restoreInventoryLocationChange,
    dismissPendingChangeAlert,
    receivedInventoryLocationChangeErrors,
  },
} = slice;
