import { useTranslation } from "@ahlsell-group/app-localization";
import { PageSubheadline } from "@ahlsell-group/app-ui/PageSubheadline";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Center } from "@ahlsell-group/ui-kit/layout";
import { LinkButton } from "@ahlsell-group/ui-kit/navigation";
import React from "react";
import { useSelector } from "react-redux";

import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import { selectInventoryLocationItems } from "../inventoryLocationSelectors";

import InventoryLocationListItem from "./InventoryLocationListItem";

const InventoryLocationList: React.FC<ViewComponentProps> = function () {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const items = useSelector(selectInventoryLocationItems);

  if (items) {
    return (
      <div
        className="bg-gray-100 h-full flex flex-col"
        data-cy="InventoryLocationList"
      >
        <PageSubheadline
          onBack={() => navigate.back()}
          actions={
            items.length ? (
              <LinkButton
                onClick={() => {
                  navigate(routes.inventoryLocation.change.items, {
                    modal: "clearConfirmation",
                  });
                }}
                data-cy="InventoryLocationList-clear"
              >
                {t("inventoryLocation.clear")}
              </LinkButton>
            ) : undefined
          }
        >
          {t("inventoryLocation.inventoryLocation_one")}
        </PageSubheadline>

        {items.length === 0 ? (
          <Center
            className="mx-14 text-center"
            data-cy="InventoryLocationList-empty"
          >
            <Typography variant="body-sm">
              {t("inventoryLocation.noItems")}
            </Typography>
          </Center>
        ) : (
          <div className="overflow-auto h-0 grow px-4 pb-6">
            <div className="py-4">
              <Typography variant="body-sm">
                {t("inventoryLocation.scannedItemsColon")}{" "}
                <Typography variant="heading-h5" className="inline">
                  {t("inventoryLocation.itemCount", {
                    itemCount: items.length,
                  })}
                </Typography>
              </Typography>
            </div>
            {items.map((item) => (
              <InventoryLocationListItem
                key={item.itemId}
                item={item}
                onSelect={() =>
                  navigate(routes.inventoryLocation.change.items.item, {
                    itemId: item.itemId,
                  })
                }
              />
            ))}
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default InventoryLocationList;
