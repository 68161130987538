/* eslint-disable no-console */

export interface IServiceWorkerService {
  /**
   * Should be called once when the app starts.
   */
  registerServiceWorker(): void;

  /**
   * Should be called whenever it is an opportune time to update the app. For
   * example:
   * - Upon page navigation
   * - When the app is idle
   *
   * Both fetching a new service worker version and actually reloading the
   * webpage are handled by this method.
   */
  allowUpdate(): void;
}

class ServiceWorkerService implements IServiceWorkerService {
  private registration?: ServiceWorkerRegistration;
  private hasNewVersion = false;
  private lastUpdateCheck = 0;

  constructor(
    private readonly url: string,
    private readonly updateIntervalMinutes: number
  ) {}

  registerServiceWorker() {
    if (document.readyState === "complete") {
      this.init();
    } else {
      window.addEventListener("load", () => this.init());
    }
  }

  allowUpdate() {
    if (!this.registration) return;

    if (this.hasNewVersion) {
      console.log("[SW] Reloading due to update...");
      window.location.reload();
    } else if (
      // We haven't gotten a new version yet. Fetch a new version, unless we
      // already did recently.
      this.lastUpdateCheck <
      Date.now() - this.updateIntervalMinutes * 60_000
    ) {
      console.log("[SW] Fetch update");
      this.registration.update();
      this.lastUpdateCheck = Date.now();
    }
  }

  private async register() {
    try {
      return await navigator.serviceWorker.register(this.url, {
        updateViaCache: "none",
      });
    } catch (error) {
      console.log("[SW] Registration failed", error);
      return undefined;
    }
  }

  private async tryUpdate(registration: ServiceWorkerRegistration) {
    try {
      await registration.update();
      return true;
    } catch (error) {
      return false;
    }
  }

  private async returns404() {
    try {
      return (await fetch(this.url)).status === 404;
    } catch (error) {
      return false;
    }
  }

  private async unregisterOn404(registration: ServiceWorkerRegistration) {
    if (await this.tryUpdate(registration)) return;
    if (!(await this.returns404())) return;

    await registration.unregister();

    // eslint-disable-next-line no-alert
    if (confirm("Unregistered service worker. Reload?")) {
      window.location.reload();
    }
  }

  private async init() {
    if (!navigator.serviceWorker) {
      return;
    }

    this.registration = await this.register();
    if (!this.registration) return;

    console.log("[SW] Registered");

    this.registration.addEventListener("updatefound", () => {
      console.log("[SW] Update found");
      this.hasNewVersion = true;
    });

    // Immediately check for updates, in case the app is stuck.
    this.allowUpdate();

    await this.unregisterOn404(this.registration);
  }
}

export default ServiceWorkerService;
