import { LogService, LogType } from "@ahlsell-group/store20-log-service";
import { Action } from "redux";

import { selectStateForErrorLogs } from "./errorSelectors";

export interface CustomErrorProps {
  message?: string;
  componentStack?: string;
  sagaStack?: string;
  action?: Action;
  state?: ReturnType<typeof selectStateForErrorLogs>;
}

const logUnhandledError = (
  logService: LogService,
  type: LogType,
  error: Error | ErrorEvent | unknown,
  customProps: CustomErrorProps = {}
) => {
  const fullType = `Uncaught ${type} error`;
  logService.log("critical", type, {
    error,
    ...customProps,
  });

  // Catching errors sometimes causes them to no longer be outputted in the
  // console. When that happens, re-output it.

  /* eslint-disable no-console */

  switch (type) {
    case "unhandled:global":
      console.log(fullType);
      break;

    case "unhandled:react":
      console.log(error);
      break;

    case "unhandled:redux":
      console.log(fullType);
      console.log(error);
      break;

    case "unhandled:saga":
      console.log(error);
      console.log(customProps.sagaStack);
      break;

    default:
      console.log(`Unknown uncaught error type ${type}`);
      console.log(error);
      break;
  }
  /* eslint-enable no-console */
};

export default logUnhandledError;
