import { State } from "../../types";

export const selectStateForErrorLogs = (state: State) => ({
  app: state.app,
  manualStockTaking: state.manualStockTaking,
  itemSearch: state.itemSearch,
  warehouse: state.warehouse,
  router: {
    "previousPath.path": state.router.previousRoute?.path,
    "route.path": state.router.route?.path,
    transitionError: state.router.transitionError,
  },
});

export const selectSagaError = (state: State) => state.error.sagaError;
