import { useTranslation } from "@ahlsell-group/app-localization";
import { PageHeadline } from "@ahlsell-group/app-ui/PageHeadline";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { ArrowRightIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppPage } from "../../page/components/AppPage";
import RouteLink from "../../routing/components/RouteLink";
import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import { selectInventoryLocationChangeErrors } from "../inventoryLocationSelectors";
import { locationsRequired } from "../inventoryLocationSlice";

import InventoryLocationMenuErrorItem from "./InventoryLocationMenuErrorItem";

const InventoryLocationMenu: React.FC<ViewComponentProps> = function () {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const changeErrors = useSelector(selectInventoryLocationChangeErrors);

  // Retrieve the locations already when displaying the menu, so that the user
  // doesn't perceive the long load time.
  useEffect(() => {
    dispatch(locationsRequired());
  }, [dispatch]);

  return (
    <AppPage
      data-cy="InventoryLocationMenu"
      headline={
        <PageHeadline>
          {t("inventoryLocation.inventoryLocation_one")}
        </PageHeadline>
      }
    >
      <PageSection>
        <RouteLink
          route={routes.inventoryLocation.change}
          variant="boxed"
          icon={ArrowRightIcon}
          data-cy="InventoryLocationMenu-overview"
        >
          {t("inventoryLocation.changeInventoryLocation")}
        </RouteLink>
        {changeErrors.length > 0 && (
          <>
            <Typography variant="form-label" color="gray" className="py-4">
              {t("inventoryLocation.locationChangeNotPerformed")}
            </Typography>
            <ul>
              {changeErrors.map((x) => (
                <li key={x.itemId}>
                  <InventoryLocationMenuErrorItem item={x} />
                </li>
              ))}
            </ul>
          </>
        )}
      </PageSection>
    </AppPage>
  );
};

export default InventoryLocationMenu;
