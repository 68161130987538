import { useTranslation } from "@ahlsell-group/app-localization";
import { Page } from "@ahlsell-group/app-ui/Page";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { PageSubheadline } from "@ahlsell-group/app-ui/PageSubheadline";
import { List, ListItem } from "@ahlsell-group/ui-kit/data-display";
import { Link } from "@ahlsell-group/ui-kit/navigation";
import React from "react";

import useNavigate from "../routing/useNavigate";

// If *any* of the URLs below change, this constant must be incremented.
// Latest update: 2021-05-20.
export const PRIVACY_POLICY_CURRENT_VERSION = "1";

const policies = [
  {
    name: "Ahlsell Group & Prevex (svenska)",
    url: "/assets/privacy-guideline-se-20210520.pdf",
  },
  {
    name: "Ahlsell Group & Prevex (English)",
    url: "/assets/privacy-guideline-en-20210520.pdf",
  },
  {
    name: "Ahlsell AS (norsk)",
    url: "/assets/privacy-guideline-no-2021.pdf",
  },
  {
    name: "Ahlsell OY (suomi)",
    url: "/assets/privacy-guideline-fi-20210520.pdf",
  },
];

const PrivacyPolicyList: React.FC = function () {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  return (
    <Page
      data-cy="PrivacyPolicyList"
      headline={
        <PageSubheadline
          onBack={() => navigate.back()}
          back-data-cy="PrivacyPolicyList-back"
        >
          {t("gettingStarted.privacyPolicy.title")}
        </PageSubheadline>
      }
    >
      <PageSection>
        <List>
          {policies.map(({ name, url }) => (
            <ListItem key={url} className="mb-4">
              <Link href={url} target="_blank" variant="inline">
                {name}
              </Link>
            </ListItem>
          ))}
        </List>
      </PageSection>
    </Page>
  );
};

export default PrivacyPolicyList;
