import { createRouter } from "router5";
import browserPlugin from "router5-plugin-browser";
import loggerPlugin from "router5-plugin-logger";

import routeTable from "./routeTable";
import { createRouter5Routes } from "./utils";

const router = createRouter(createRouter5Routes(routeTable));

router.usePlugin(browserPlugin());
router.usePlugin(loggerPlugin);

export default router;
