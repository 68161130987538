import { useZIndex } from "@ahlsell-group/ui-kit/util";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import routes from "../../routing/routes";
import { selectCurrentRoute } from "../../routing/routingSelectors";
import { selectShowCoachMark } from "../homeSelectors";
import { setCoachMark } from "../homeSlice";

interface CoachMarkProps {
  children: React.ReactNode;
}

const CoachMark: React.FC<CoachMarkProps> = function ({ children }) {
  const currentRoute = useSelector(selectCurrentRoute);
  const isHomePage = currentRoute?.path === routes.home.path;
  const dispatch = useDispatch();
  const showCoachMark = useSelector(selectShowCoachMark);
  const [zIndex] = useZIndex(showCoachMark);
  const isVisible = showCoachMark && isHomePage;

  useEffect(() => {
    if (!isVisible) {
      return undefined;
    }

    const listener = () => dispatch(setCoachMark(undefined));
    window.addEventListener("click", listener);
    return () => {
      window.removeEventListener("click", listener);
    };
  }, [dispatch, isVisible]);

  return isVisible ? (
    <>
      <div
        className="fixed inset-0 bg-black opacity-80 md:hidden"
        style={{ zIndex }}
      />
      <div className="relative" style={{ zIndex }}>
        <div className="rounded-full animate-delayed-highlight md:animate-none">
          <div className="flex items-center justify-center bg-white rounded-full border-white border-8 w-16 -my-2 animate-highlight md:animate-none">
            {children}
          </div>
        </div>
      </div>
    </>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

export default CoachMark;
