import { useTranslation } from "@ahlsell-group/app-localization";
import { ExclamationIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Alert } from "@ahlsell-group/ui-kit/feedback";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import typescriptAssertNever from "../../../util/typescriptAssertNever";
import { hideNotification } from "../inventoryLocationSlice";
import { Notification } from "../types";

export type InventoryLocationAlertProps = {
  notification: Notification;
};

const InventoryLocationAlert: React.FC<InventoryLocationAlertProps> =
  function ({
    notification: {
      itemId,
      locationId,
      pickStation,
      autoHideDuration = 3000,
      severity,
      type,
    },
  }) {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();

    useEffect(() => {
      const timer = setTimeout(() => {
        dispatch(hideNotification());
      }, autoHideDuration);

      return () => {
        clearTimeout(timer);
      };
    }, [autoHideDuration, dispatch]);

    let message;
    switch (type) {
      case "not-found":
        message = t("inventoryLocation.scannedItemNotFound");
        break;
      case "removed":
        message = t("inventoryLocation.itemRemoved", { itemId });
        break;
      case "submitted":
        message = t("inventoryLocation.itemsHaveBeenRelocated", {
          pickStation,
          locationId,
        });
        break;
      case "already-in-list":
        message = t("inventoryLocation.itemAlreadyScanned");
        break;
      case "unknown":
        message = t("errors.inventoryLocationItemSearch.unknown__detail");
        break;
      default:
        typescriptAssertNever(type);
        break;
    }

    return (
      <Alert
        severity={severity}
        icon={ExclamationIcon}
        className="w-full"
        data-cy={`InventoryLocationAlert-${type}`}
      >
        <Typography variant="body-sm">{message}</Typography>
      </Alert>
    );
  };

export default InventoryLocationAlert;
