import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectItemLocation } from "./inventoryLocationSelectors";
import { itemLocationRequired } from "./inventoryLocationSlice";

export default (warehouseId: number, itemId: string) => {
  const dispatch = useDispatch();
  const itemLocation = useSelector(selectItemLocation(warehouseId, itemId));

  useEffect(() => {
    const twentyMinutesAgo = new Date(Date.now() - 20 * 60 * 1000);
    if (
      !itemLocation ||
      (itemLocation.state === "idle" &&
        new Date(itemLocation.loadedAt) < twentyMinutesAgo)
    ) {
      dispatch(
        itemLocationRequired({
          warehouseId,
          itemId,
        })
      );
    }
  }, [itemLocation, dispatch, itemId, warehouseId]);

  return itemLocation;
};
