import { useCallback } from "react";

export type SplashHook = {
  hideSplashScreen(): void;
};

export default function useSplashScreen(): SplashHook {
  const hide = useCallback(() => {
    const splash = document.getElementById("splash");
    if (!splash) return;

    const didLoadQuickly = performance.now() < 200;

    if (didLoadQuickly) {
      splash.remove();
    } else {
      splash.classList.add("fade");
      setTimeout(() => splash.remove(), 200);
    }
  }, []);

  return {
    hideSplashScreen: hide,
  };
}
