import { put, select } from "redux-saga/effects";

import { Saga } from "../../../types";
import { ItemInWarehouse, selectItemsToExpire } from "../itemStockSelectors";
import { itemStockBalancesExpired } from "../itemStockSlice";

export default function* expireItemsSaga(): Saga {
  const cutoff = new Date(Date.now() - 60 * 1000);
  const items: ItemInWarehouse[] = yield select(selectItemsToExpire(cutoff));
  if (items.length) {
    yield put(
      itemStockBalancesExpired(
        items.map((item) => ({
          warehouseId: item.warehouseId,
          itemId: item.itemId,
        }))
      )
    );
  }
}
