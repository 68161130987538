import { InputMode } from "@ahlsell-group/app-ui/SearchPageKeyboardSelector";
import { StorageService } from "@ahlsell-group/store20-service-core";

import { SearchMode } from "./types";

const freeTextPreferenceStorageKey = "ItemSearch:InputMode:free-text";

export interface ItemSearchInputModeService {
  getPreference(searchMode: SearchMode): InputMode;
  setPreference(searchMode: SearchMode, inputMode: InputMode): void;
}

/** Gets/sets a prefered input mode. */
interface InputModePreference {
  value: InputMode;
}

/** Gets an updatable preferred input mode for the `free-text` search mode. */
const getFreeTextPreference = (
  storageService: StorageService
): InputModePreference => {
  // Default mode.
  let value: InputMode = "numeric";

  const object: InputModePreference = {
    get value() {
      return value;
    },
    set value(inputMode) {
      value = inputMode;
      storageService.set({
        key: freeTextPreferenceStorageKey,
        value: inputMode,
      });
    },
  };

  storageService
    .get({ key: freeTextPreferenceStorageKey })
    .then((storedValue) => {
      if (storedValue.value) {
        object.value = storedValue.value as InputMode;
      }
    });

  return object;
};

/** A non-updatable preferred input mode for the `id-only` search mode. */
const idOnlyPreference: InputModePreference = {
  get value(): InputMode {
    return "numeric";
  },
  set value(value) {
    // Unchangeable.
  },
};

export class ItemSearchInputModeServiceImplementation
  implements ItemSearchInputModeService
{
  private prefs: Record<SearchMode, InputModePreference>;

  constructor(storageService: StorageService) {
    this.prefs = {
      "free-text": getFreeTextPreference(storageService),
      "id-only": idOnlyPreference,
    };
  }

  getPreference(searchMode: SearchMode): InputMode {
    return this.prefs[searchMode].value;
  }

  setPreference(searchMode: SearchMode, inputMode: InputMode) {
    this.prefs[searchMode].value = inputMode;
  }
}
