import { StorageService } from "@ahlsell-group/store20-service-core";

import { State } from "../../types";

type PersistedState = {
  [key: string]: { data: Partial<State>; lifetime: "once" | "until-removed" };
};

export interface ReduxPersistenceService {
  persistOnce(key: string, value: Partial<State>): Promise<void>;
  persist(key: string, value: Partial<State>): Promise<void>;
  remove(key: string): Promise<void>;
  restore(): Promise<Partial<State>>;
}

export default class ReduxPersistenceServiceImpl
  implements ReduxPersistenceService
{
  constructor(private storageService: StorageService) {}

  private async getState(): Promise<PersistedState> {
    const state = await this.storageService.getAndParse<PersistedState>({
      key: "ace_persisted_state",
    });
    return state.value ?? JSON.parse("{}");
  }

  private async setState(persistedState: PersistedState) {
    await this.storageService.setAndStringify({
      key: "ace_persisted_state",
      value: persistedState,
    });
  }

  async persistOnce(key: string, value: Partial<State>): Promise<void> {
    await this.addToState(key, value, "once");
  }

  async persist(key: string, value: Partial<State>): Promise<void> {
    await this.addToState(key, value, "until-removed");
  }

  private async addToState(
    key: string,
    value: Partial<State>,
    lifetime: "once" | "until-removed"
  ) {
    const currentState = await this.getState();

    const newState = {
      ...currentState,
      [key]: { data: value, lifetime },
    };

    await this.setState(newState);
  }

  async remove(key: string): Promise<void> {
    const currentState = await this.getState();
    delete currentState[key];
    await this.setState(currentState);
  }

  async restore(): Promise<Partial<State>> {
    const currentState = await this.getState();
    let restoredState = {};
    Object.entries(currentState).forEach(async ([key, { data, lifetime }]) => {
      restoredState = {
        ...restoredState,
        ...data,
      };
      if (lifetime === "once") {
        await this.remove(key);
      }
    });
    return restoredState;
  }
}
