import { useTranslation } from "@ahlsell-group/app-localization";
import { InfoIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { LogoAceVertical } from "@ahlsell-group/ui-kit/data-display";
import { Alert } from "@ahlsell-group/ui-kit/feedback";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectUserLoginError } from "../../../app/appSelectors";
import { selectLastCompletedStep } from "../gettingStartedSelectors";
import { stepCompleted } from "../gettingStartedSlice";
import { StepComponent } from "../types";

import GettingStartedPage from "./GettingStartedPage";

const LoginInstructions: StepComponent = function LoginInstructions() {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const loginError = useSelector(selectUserLoginError);
  const lastCompletedStep = useSelector(selectLastCompletedStep);
  const isLoggingIn = lastCompletedStep === "loginInstructions" && !loginError;
  const onContinue = () => {
    dispatch(
      stepCompleted({
        type: "loginInstructions",
        hasViewedLoginInstructions: true,
      })
    );
  };

  return (
    <GettingStartedPage
      loading={isLoggingIn}
      data-cy="LoginInstructions"
      backButton={false}
      actions={{
        login: {
          text: t("gettingStarted.loginInstructions.loginButton"),
          primary: true,
          "data-cy": "LoginInstructions-login",
          disabled: isLoggingIn,
        },
      }}
      onAction={onContinue}
    >
      <Alert severity="info" icon={InfoIcon}>
        {t("gettingStarted.loginInstructions.authenticatorText")}
      </Alert>
      {!isLoggingIn && (
        <div className="flex justify-center mt-16">
          <LogoAceVertical width="5rem" />
        </div>
      )}
    </GettingStartedPage>
  );
};

export default LoginInstructions;
