import React from "react";

import OtherWarehousesItemStock from "../../item-stock/components/OtherWarehousesItemStock";
import View from "../../routing/components/View";
import routes from "../../routing/routes";

import ItemInformation from "./ItemInformation";

const ItemInformationRoot: React.FC = function () {
  return (
    <>
      <View
        route={routes.itemInformation.itemIdOrBarcode}
        component={ItemInformation}
        exact
      />
      <View
        route={routes.itemInformation.otherWarehousesItemStock}
        component={OtherWarehousesItemStock}
        exact
      />
    </>
  );
};

export default ItemInformationRoot;
