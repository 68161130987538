export default class PromiseSource<T = void> {
  public readonly promise: Promise<T>;
  #resolve?: (value: PromiseLike<T> | T) => void;
  #reject?: (reason?: any) => void;
  #resolution?: {
    value: PromiseLike<T> | T;
  };

  #rejection?: {
    reason?: any;
  };

  public constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      if (this.#resolution) {
        resolve(this.#resolution.value);
      } else if (this.#rejection) {
        reject(this.#rejection.reason);
      } else {
        this.#resolve = resolve;
        this.#reject = reject;
      }
    });
  }

  public resolve(value: PromiseLike<T> | T): void {
    if (this.#resolve) {
      this.#resolve(value);
    } else {
      this.#resolution = { value };
    }
  }

  public reject(reason?: any): void {
    if (this.#reject) {
      this.#reject(reason);
    } else {
      this.#rejection = { reason };
    }
  }
}
