import { useTranslation } from "@ahlsell-group/app-localization";
import { AceChip } from "@ahlsell-group/app-ui/AceChip";
import { PageHeadline } from "@ahlsell-group/app-ui/PageHeadline";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { ArrowRightIcon } from "@ahlsell-group/ui-kit-imagery-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AppPage } from "../../page/components/AppPage";
import RouteLink from "../../routing/components/RouteLink";
import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import useRoute from "../../routing/useRoute";
import { selectIsStockTakeInProgress } from "../manualStockTakingSelectors";

import StockTakenSubmissionList from "./StockTakenSubmissionList";

const StockTakingMenu: React.FC<
  ViewComponentProps<{ highlightVivaldiId: string }>
> = function ({ params }) {
  const { t } = useTranslation("common");
  const isInProgress = useSelector(selectIsStockTakeInProgress);
  const [highlightVivaldiIdCached] = useState(params.highlightVivaldiId);
  const [, navigateToSelf] = useRoute(routes.stockTaking);

  useEffect(() => {
    if (params.highlightVivaldiId) {
      navigateToSelf(undefined, { replace: true });
    }
  }, [navigateToSelf, params.highlightVivaldiId]);

  return (
    <AppPage
      data-cy="StockTakingMenu"
      headline={<PageHeadline>{t("stockTaking.stockTaking")}</PageHeadline>}
    >
      <PageSection>
        <RouteLink
          route={routes.stockTaking.manual}
          variant="boxed"
          icon={ArrowRightIcon}
          data-cy="manual-stock-taking"
          // TODO Should we really override these styles?
          className="!bg-theme-primary-dark !text-theme-primary-light relative"
        >
          {isInProgress ? (
            <>
              {t("stockTaking.resumeStockTaking")}
              <AceChip color="info" className="absolute -top-2 -right-1 mt-px">
                {t("stockTakingReview.inProgress")}
              </AceChip>
            </>
          ) : (
            <>{t("stockTaking.beginStockTaking")}</>
          )}
        </RouteLink>
        <StockTakenSubmissionList
          highlightVivaldiId={highlightVivaldiIdCached}
        />
      </PageSection>
    </AppPage>
  );
};

export default StockTakingMenu;
