import { Selector } from "../../types";

import { InstallModeState } from "./installModeSlice";

export const selectInstallModeState: Selector<InstallModeState> = (state) =>
  state.installMode;

export const selectInstallPrompt: Selector<Event | undefined> = (state) =>
  state.installMode.installPromptResult;

export const selectInstallModeBrowser: Selector<string | undefined> = (state) =>
  state.installMode.state === "loaded" ? state.installMode.browser : undefined;
