import { createRoutes } from "../routing/utils";

const myPageRoutes = createRoutes({
  myPage: {
    path: "/my-page?modal",
    children: {
      questionsAnswers: { path: "/questions-answers?q" },
      about: { path: "/about" },
      language: { path: "/language" },
    },
  },
} as const);

export default myPageRoutes;
