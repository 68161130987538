import { GetParsedResult } from "@ahlsell-group/store20-service-core";
import { call, select } from "redux-saga/effects";

import { selectVivaldiEmployeeNumber } from "../../../app/appSelectors";
import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import getStorageKey from "../getStorageKey";
import { LocalStorageConfig } from "../localStorageTypes";
import { Config } from "../types";

export default function* loadConfigSaga({
  configService,
  logService,
  storageService,
}: ServiceContainer): Saga<Config | undefined> {
  const employeeId: string = yield select(selectVivaldiEmployeeNumber);

  try {
    const config: Config = yield call([configService, "getConfig"]);
    const localStorageConfig: LocalStorageConfig = config;

    yield call([storageService, "setAndStringify"], {
      key: getStorageKey(employeeId),
      value: localStorageConfig,
    });
    return config;
  } catch (e) {
    yield call(
      [logService, "log"],
      "error",
      "saga:app:loadConfigSaga:getConfig",
      { error: e }
    );
  }

  try {
    const { value: localStorageConfig }: GetParsedResult<LocalStorageConfig> =
      yield call([storageService, "getAndParse"], {
        key: getStorageKey(employeeId),
      });

    return localStorageConfig;
  } catch (e) {
    yield call(
      [logService, "log"],
      "error",
      "saga:app:loadConfigSaga:getSavedConfig",
      { error: e }
    );
  }

  return undefined;
}
