import { useTranslation } from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import {
  LogoAceVertical,
  Typography,
} from "@ahlsell-group/ui-kit/data-display";
import React from "react";
import { useSelector } from "react-redux";

import getConfig from "../../../app/config";
import { AppPage } from "../../page/components/AppPage";
import RouteLink from "../../routing/components/RouteLink";
import routes from "../../routing/routes";
import { selectWarehouseName } from "../../warehouse/warehouseSelectors";

import CoachMarkMessage from "./CoachMarkMessage";
import HomeItemSearch from "./HomeItemSearch";
import UpdateIosAlert from "./UpdateIosAlert";

const Home: React.FC = function () {
  const { t } = useTranslation("common");
  const warehouseName = useSelector(selectWarehouseName);
  const { versionDisplay } = getConfig();
  return (
    <AppPage data-cy="Home">
      <PageSection className="flex-1">
        <div className="pt-5 pb-8 flex justify-between">
          <LogoAceVertical width="2.0625rem" height="2.9375rem" />
          <Typography variant="heading-h5" className="text-right">
            <div className="mb-1">{t("myPage.store")}</div>
            <div>
              <RouteLink
                variant="inline"
                route={routes.home.warehouse}
                data-cy="Home-warehouseDisplayName"
              >
                {warehouseName}
              </RouteLink>
            </div>
          </Typography>
        </div>
        <div className="flex-1 py-8">
          <HomeItemSearch />
          <UpdateIosAlert />
        </div>
        <CoachMarkMessage />
        <Typography variant="body-sm" color="gray" className="text-center">
          {t("myPage.version", { version: versionDisplay })}
        </Typography>
      </PageSection>
    </AppPage>
  );
};

export default Home;
