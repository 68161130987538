import i18n, {
  autonyms,
  Trans,
  transObject,
  useTranslation,
} from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { ArrowRightIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { ProfileCard } from "@ahlsell-group/ui-kit/data-display";
import { Link } from "@ahlsell-group/ui-kit/navigation";
import React from "react";
import { useSelector } from "react-redux";

import {
  selectLegalEntityId,
  selectUserName,
  selectVivaldiEmployeeNumber,
} from "../../../app/appSelectors";
import { AppPage } from "../../page/components/AppPage";
import PrivacyPolicyList from "../../privacy-policy/PrivacyPolicyList";
import RouteLink from "../../routing/components/RouteLink";
import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import WarehouseModal from "../../warehouse/components/WarehouseModal";
import { selectWarehouseName } from "../../warehouse/warehouseSelectors";
import { getPage, getQuestionHeaders } from "../content";
import { getEmergencyContactNumberByLegalEntity } from "../legalEntityInfo";

import AppBoxedArea from "./AppBoxedArea";

const MyPageMenu: React.FC<
  ViewComponentProps<{ modal?: "warehouse" | "privacyPolicy" }>
> = function ({ params }) {
  const { t } = useTranslation("common");
  const name = useSelector(selectUserName);
  const vivaldiEmployeeNumber = useSelector(selectVivaldiEmployeeNumber);
  const warehouseName = useSelector(selectWarehouseName);
  const contactEmail = t("myPage.contactEmail");
  const currentLanguage = autonyms[i18n.language];
  const hasAboutPage = !!getPage("general", "about");
  const hasQAndAPage = getQuestionHeaders().length !== 0;
  const legalEntity = useSelector(selectLegalEntityId);
  const emergencyContactNumber =
    getEmergencyContactNumberByLegalEntity(legalEntity);

  if (params.modal === "privacyPolicy") {
    return <PrivacyPolicyList />;
  }

  return (
    <AppPage data-cy="MyPageMenu">
      <PageSection>
        <div className="flex flex-col gap-4">
          <ProfileCard
            background="transparent"
            name={name}
            description={
              <>
                {t("myPage.storeColon")}{" "}
                <RouteLink
                  variant="inline"
                  route={routes.myPage}
                  routeParams={{ modal: "warehouse" }}
                  data-cy="MyPageMenu-warehouseName"
                >
                  {warehouseName}
                </RouteLink>
              </>
            }
            detail={vivaldiEmployeeNumber}
          />

          <RouteLink
            route={routes.myPage.language}
            variant="boxed"
            icon={ArrowRightIcon}
            data-cy="MyPageMenu-language"
          >
            {t("myPage.languageColon")} {currentLanguage}
          </RouteLink>

          {hasQAndAPage && (
            <RouteLink
              route={routes.myPage.questionsAnswers}
              variant="boxed"
              icon={ArrowRightIcon}
              data-cy="MyPageMenu-questionsAnswers"
            >
              {t("myPage.questionsAndAnswers")}
            </RouteLink>
          )}

          {hasAboutPage && (
            <RouteLink
              route={routes.myPage.about}
              variant="boxed"
              icon={ArrowRightIcon}
              data-cy="MyPageMenu-about"
            >
              {t("myPage.about")}
            </RouteLink>
          )}

          <RouteLink
            route={routes.myPage}
            routeParams={{ modal: "privacyPolicy" }}
            variant="boxed"
            icon={ArrowRightIcon}
            data-cy="MyPageMenu-privacyPolicy"
          >
            {t("gettingStarted.privacyPolicy.title")}
          </RouteLink>

          <AppBoxedArea>
            <div>
              <Trans t={t} i18nKey="myPage.contactDetails">
                <Link
                  variant="inline"
                  href={`mailto:${contactEmail}`}
                  className="whitespace-nowrap"
                >
                  {transObject({ contactEmail })}
                </Link>
              </Trans>
            </div>
            <div className="mt-4">
              <Trans t={t} i18nKey="myPage.emergencyContactDetails">
                <Link
                  variant="inline"
                  href={`tel:${emergencyContactNumber}`}
                  className="whitespace-nowrap"
                >
                  {transObject({ emergencyContactNumber })}
                </Link>
              </Trans>
            </div>
          </AppBoxedArea>
        </div>
        <WarehouseModal active={params.modal === "warehouse"} />
      </PageSection>
    </AppPage>
  );
};

export default MyPageMenu;
