import { all, takeEvery, takeLeading } from "redux-saga/effects";

import { appStarted } from "../../app/appSlice";
import { ServiceContainer } from "../../app/serviceContainer";

import { scanningModeChanged } from "./barcodeScannerSlice";
import loadScanningModeSaga from "./sagas/loadScanningModeSaga";
import saveScanningModeSaga from "./sagas/saveScanningModeSaga";

export default function* barcodeScannerSagas(
  serviceContainer: ServiceContainer
) {
  yield all([
    takeLeading(appStarted.match, loadScanningModeSaga, serviceContainer),
    takeEvery(
      scanningModeChanged.match,
      saveScanningModeSaga,
      serviceContainer
    ),
  ]);
}
