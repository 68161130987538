import {
  GetParsedResult,
  StorageService,
} from "@ahlsell-group/store20-service-core";
import { call, spawn } from "redux-saga/effects";

import { Saga } from "../../types";

export interface MemoAndUpdateSagaArgs<T> {
  storageService: StorageService;
  cacheKey: string;

  /** Determine whether it's possible to use the cached value. */
  canUseValue: (value: T) => boolean;

  /** Get a value asynchronously using the given saga. */
  getValueSaga: () => Saga<T>;

  /**
   * When a usable value is received, call the given saga. This will be called
   * twice if there is a cached value and `getValueSaga` returns a value.
   */
  onValueSaga: (value: T) => Saga;

  /**
   * When an error is thrown in `getValueSaga`, this function will be called.
   */
  onErrorSaga: (error: unknown) => Saga;
}

/**
 * Inner saga used in `memoAndUpdateSaga`.
 */
function* updateSaga<T>({
  storageService,
  cacheKey,
  canUseValue,
  getValueSaga,
  onValueSaga,
  onErrorSaga,
}: MemoAndUpdateSagaArgs<T>): Saga {
  let newValue: T;
  try {
    newValue = yield call(getValueSaga);
  } catch (e) {
    yield* onErrorSaga(e);
    return;
  }

  if (canUseValue(newValue)) {
    yield* onValueSaga(newValue);
    yield call([storageService, "setAndStringify"], {
      key: cacheKey,
      value: newValue,
    });
  }
}

/**
 * This function caches a single value. It allows you to use the cached value
 * while fetching an updated value.
 */
export function* memoAndUpdateSaga<T>(args: MemoAndUpdateSagaArgs<T>): Saga {
  const { storageService, cacheKey, canUseValue, onValueSaga } = args;

  const oldValue: GetParsedResult<T> = yield call(
    [storageService, "getAndParse"],
    { key: cacheKey }
  );

  let didUseOldValue = false;

  if (oldValue.value !== undefined && canUseValue(oldValue.value)) {
    yield call(onValueSaga, oldValue.value);
    didUseOldValue = true;
  }

  if (didUseOldValue) {
    // @ts-expect-error TypeScript doesn't understand the type parameter when called through `fork()`.
    yield spawn(updateSaga, args);
  } else {
    yield* updateSaga(args);
  }
}
