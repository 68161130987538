import router from "./router";

export const navigateBack = (steps: number = 1): Promise<void> =>
  new Promise<void>((resolve) => {
    const currentState = router.getState();
    const unsubscribe = router.subscribe((update) => {
      if (update.previousRoute.name === currentState.name) {
        (unsubscribe as () => void)();
        resolve();
      }
    });
    window.history.go(-steps);
  });
