import { AppConfig } from "@ahlsell-group/store20-config-service";
import { call, select } from "redux-saga/effects";

import {
  selectCompletedSteps,
  selectStepsWithInputRequired,
} from "../gettingStartedSelectors";
import { GettingStartedState, stepCompleted } from "../gettingStartedSlice";
import { Step } from "../types";

const loginInstructionsStep: Step<"loginInstructions"> = {
  type: "loginInstructions",
  *preStepSaga({ appConfigService }) {
    const appConfig: AppConfig = yield call([appConfigService, "getAppConfig"]);
    if (
      appConfig.hasSeenLoginInstructions ||
      appConfig.ace_last_used_version !== undefined
    ) {
      return stepCompleted({
        type: "loginInstructions",
        hasViewedLoginInstructions: true,
      });
    }
    return "input-required";
  },
  *postStepSaga(
    { authenticationService, reduxPersistenceService },
    { payload }
  ) {
    const steps: GettingStartedState["steps"] = yield select(
      selectCompletedSteps
    );
    const stepsWithInputRequired: GettingStartedState["stepsWithInputRequired"] =
      yield select(selectStepsWithInputRequired);

    if (payload.type === "loginInstructions" && payload.clearError) {
      yield call([authenticationService, "clearSession"]);
    }

    /* Because of the reload caused by logging in, save the current steps to be
     restored once app is loaded again.
     If the login doesn't reload, remove the saved state */

    yield call([reduxPersistenceService, "persist"], "login-instructions", {
      gettingStarted: {
        lastCompletedStep: "loginInstructions",
        stepsWithInputRequired,
        steps,
      },
    });

    yield call([authenticationService, "ensureLoggedIn"]);
  },
  *saveResultSaga({ appConfigService }, { loginInstructions }) {
    if (loginInstructions)
      yield call(
        [appConfigService, "setConfig"],
        "hasSeenLoginInstructions",
        loginInstructions.hasViewedLoginInstructions
      );
  },
};

export default loginInstructionsStep;
