import { Item } from "@ahlsell-group/store20-product-service";

import { Selector } from "../../types";

import { ItemInformationState, LoadItemError } from "./itemInformationSlice";

export const selectItemState: Selector<ItemInformationState> = (state) =>
  state.itemInformation;

export const selectState: Selector<ItemInformationState["state"]> = (state) =>
  state.itemInformation.state;

export const selectCurrentItem: Selector<Item | undefined> = (state) =>
  state.itemInformation.state === "item-open"
    ? state.itemInformation.currentItem
    : undefined;

export const selectError: Selector<LoadItemError | undefined> = (state) =>
  state.itemInformation.state === "error"
    ? state.itemInformation.error
    : undefined;
