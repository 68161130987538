import { Selector } from "../../types";

import { GettingStartedState, StepType } from "./gettingStartedSlice";

export const selectLastCompletedStep: Selector<"none" | StepType> = (state) =>
  state.gettingStarted.lastCompletedStep;

export const selectStepsWithInputRequired: Selector<StepType[]> = (state) =>
  state.gettingStarted.stepsWithInputRequired;

export const selectHasPreviousStepWithInputRequired =
  (step?: StepType): Selector<boolean> =>
  (state) => {
    if (!step) {
      return false;
    }

    // The step only has a previous if it itself is part of the list and it
    // isn't the first step.
    const index = state.gettingStarted.stepsWithInputRequired.indexOf(step);
    return index !== -1 && index !== 0;
  };

export const selectCompletedSteps: Selector<GettingStartedState["steps"]> = (
  state
) => state.gettingStarted.steps;
