import { useTranslation } from "@ahlsell-group/app-localization";
import {
  LogoAceVertical,
  Typography,
} from "@ahlsell-group/ui-kit/data-display";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as routerActions } from "redux-router5";

import GettingStartedPage, {
  GettingStartedPageSectionProps,
} from "../../getting-started/components/GettingStartedPage";
import useRouter from "../../routing/useRouter";
import { selectInstallModeState } from "../installModeSelectors";

const InstallLandingPage: React.FC = function InstallLandingPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const installModeState = useSelector(selectInstallModeState);
  const router = useRouter();
  const cameFromGettingStarted =
    router.getState().params.ref === "getting-started";

  useEffect(() => {
    if (
      cameFromGettingStarted &&
      installModeState.state !== "empty" &&
      installModeState.platform !== "unknown"
    ) {
      dispatch(
        routerActions.navigateTo(
          `install.${installModeState.platform}`,
          {},
          { replace: true }
        )
      );
    }
  }, [cameFromGettingStarted, dispatch, installModeState]);

  if (installModeState.state === "empty") {
    return null;
  }

  if (installModeState.isInPwaMode) {
    window.location.replace("/");
  }

  let actions: GettingStartedPageSectionProps["actions"];
  switch (installModeState.platform) {
    case "ios":
      actions = {
        ios: {
          text: t("installMode.landingPage.installationInstructions"),
          primary: true,
          "data-cy": "InstallLandingPage-ios",
        },
      };
      break;
    case "android":
      actions = {
        android: {
          text: t("installMode.landingPage.installationInstructions"),
          primary: true,
          "data-cy": "InstallLandingPage-android",
        },
      };
      break;
    default:
      actions = {
        ios: {
          text: t("installMode.landingPage.installationInstructionsIos"),
          primary: true,
          "data-cy": "InstallLandingPage-ios",
        },
        android: {
          text: t("installMode.landingPage.installationInstructionsAndroid"),
          primary: true,
          "data-cy": "InstallLandingPage-android",
        },
      };
      break;
  }

  const onAction = (action: string) => {
    dispatch(routerActions.navigateTo(`install.${action}`));
  };

  return (
    <GettingStartedPage
      backButton={cameFromGettingStarted}
      actions={actions}
      onAction={onAction}
      data-cy="InstallLandingPage"
    >
      <div className="flex flex-col items-center justify-center mt-16">
        <LogoAceVertical width="5rem" />

        <Typography variant="heading-h2" className="mt-4">
          {t("gettingStarted.welcome.welcomeText")}
        </Typography>
      </div>
    </GettingStartedPage>
  );
};

export default InstallLandingPage;
