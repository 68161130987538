import { Config } from "@ahlsell-group/store20-config-service";
import { call, put } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import { configUpdated } from "../configSlice";

import loadConfigSaga from "./loadConfigSaga";

/**
 * Update the config if needed.
 *
 * Also applies the new LogRocket config.
 */
export default function* updateConfigSaga(
  serviceContainer: ServiceContainer
): Saga<boolean> {
  const { appTelemetryService } = serviceContainer;

  // Load user config.
  const config: Config | undefined = yield call(
    loadConfigSaga,
    serviceContainer
  );
  if (!config) {
    return false;
  }

  // Use LogRocket if a user has a feature flag that indicates that it should be enabled.
  appTelemetryService.updateSessionInfo({
    logRocketEnabled: config.featureFlags.includes("LogRocket.Default"),
  });

  yield put(configUpdated({ config }));

  return true;
}
