import {
  ErrorKeysByCategory,
  useTranslation,
} from "@ahlsell-group/app-localization";
import { GenericOperationError } from "@ahlsell-group/store20-service-core";
import { NullableItem } from "@ahlsell-group/store20-stock-taking-service";
import { ModalAction, ModalActions } from "@ahlsell-group/ui-kit/feedback";
import React from "react";

import ErrorModal from "../../error/components/ErrorModal";

interface ManualStockTakingItemErrorModalProps {
  bestEffortItemId: string;
  item?: NullableItem;
  enableRemove: boolean;
  overrideError?: GenericOperationError | "barcode-not-found";
  onClose: () => void;
  onRemove: () => void;
}

const getErrorKey = (
  item?: NullableItem,
  overrideError?: GenericOperationError | "barcode-not-found"
): ErrorKeysByCategory<"stockTakingItem"> =>
  overrideError ??
  (item?.validationErrorType === "PreCheckFailed"
    ? "unknown"
    : item?.validationErrorType) ??
  "unknown";

const ManualStockTakingItemErrorModal: React.FC<ManualStockTakingItemErrorModalProps> =
  function ({
    item,
    bestEffortItemId,
    enableRemove,
    overrideError,
    onClose,
    onRemove,
  }) {
    const { t } = useTranslation("common");

    const modalActions = (
      <ModalActions>
        <ModalAction
          mode="button"
          onClick={onClose}
          data-cy="ManualStockTakingItemErrorModal-ok"
        >
          {t("ok")}
        </ModalAction>
        {enableRemove && (
          <ModalAction
            mode="button"
            onClick={onRemove}
            color="error"
            data-cy="ManualStockTakingItemErrorModal-remove"
          >
            {t("remove")}
          </ModalAction>
        )}
      </ModalActions>
    );

    const extraContent = (
      <>
        {t("stockTaking.item")}{" "}
        <strong data-cy={`ManualStockTakingItemErrorModal-${bestEffortItemId}`}>
          {bestEffortItemId}
        </strong>
      </>
    );

    return (
      <ErrorModal
        category="stockTakingItem"
        error={{ reason: getErrorKey(item, overrideError) }}
        onClose={onClose}
        data-cy="ManualStockTakingItemErrorModal"
        data-error-reason={overrideError ?? item?.validationErrorType}
        modalActions={modalActions}
        extraContent={extraContent}
      />
    );
  };

export default ManualStockTakingItemErrorModal;
