/* eslint-disable no-console */
import {
  BarcodeCaptureListener,
  CameraState,
  ScanningContext,
} from "../../ScanningContext";

export class ScanningContextMockImpl implements ScanningContext {
  enableBarcodeCapture(): Promise<void> {
    console.log("enable barcode capture");
    return Promise.resolve();
  }

  disableBarcodeCapture(): Promise<void> {
    console.log("disable barcode capture");
    return Promise.resolve();
  }

  addBarcodeCaptureListener(listener: BarcodeCaptureListener): void {
    console.log(`added listener: ${listener}`);
  }

  removeBarcodeCaptureListener(listener: BarcodeCaptureListener): void {
    console.log(`removed listener: ${listener}`);
  }

  switchCameraToDesiredState(state: CameraState): Promise<void> {
    console.log(`camera state: ${state}`);
    return Promise.resolve();
  }
}
