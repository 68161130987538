import { useTranslation } from "@ahlsell-group/app-localization";
import {
  Modal,
  ModalAction,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@ahlsell-group/ui-kit/feedback";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ViewComponentProps } from "../../routing/components/View";
import useNavigate from "../../routing/useNavigate";
import { selectItems } from "../manualStockTakingSelectors";
import { deleteAllItems } from "../manualStockTakingSlice";

const ManualStockTakingClearModal: React.FC<ViewComponentProps> = function ({
  params,
}) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector(selectItems);

  if (params.clear !== null) {
    return null;
  }

  return (
    <Modal onCancel={() => navigate.back()} open variant="card">
      <ModalHeader data-cy="ManualStockTakingClearModal">
        {t("stockTaking.clearList")}
      </ModalHeader>
      <ModalContent>
        {t("stockTaking.clearListDetails", { count: items.length })}
      </ModalContent>
      <ModalActions>
        <ModalAction
          mode="button"
          variant="tertiary"
          onClick={() => navigate.back()}
          data-cy="ManualStockTakingClearModal-cancel"
        >
          {t("cancel")}
        </ModalAction>
        <ModalAction
          mode="button"
          variant="secondary"
          onClick={() => {
            dispatch(deleteAllItems());
            navigate.back();
          }}
          data-cy="ManualStockTakingClearModal-clear"
        >
          {t("stockTaking.clear")}
        </ModalAction>
      </ModalActions>
    </Modal>
  );
};

export default ManualStockTakingClearModal;
