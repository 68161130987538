import { actions } from "redux-router5";
import { call, put, select, take } from "redux-saga/effects";
import { State } from "router5";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import ActionType from "../../../util/ActionType";
import { selectCurrentRoute } from "../../routing/routingSelectors";
import {
  selectCompletedSteps,
  selectLastCompletedStep,
} from "../gettingStartedSelectors";
import {
  GettingStartedState,
  stepCompleted,
  StepType,
  updateStepsWithInputRequired,
} from "../gettingStartedSlice";
import installationPromptStep from "../steps/installationPromptStep";
import languageSelectionStep from "../steps/languageSelectionStep";
import loginConfirmationStep from "../steps/loginConfirmationStep";
import loginInstructionsStep from "../steps/loginInstructionsStep";
import privacyPolicyStep from "../steps/privacyPolicyStep";
import welcomeStep from "../steps/welcomeStep";
import { PreStepResult, Step } from "../types";

const steps = [
  welcomeStep,
  languageSelectionStep,
  privacyPolicyStep,
  installationPromptStep,

  loginInstructionsStep,
  loginConfirmationStep,
];
const ORIGIN_ROUTE_KEY = "ace_getting_started_origin_url";

function* getNextStep() {
  const lastStep: StepType | "none" = yield select(selectLastCompletedStep);
  if (lastStep === "none") {
    return steps[0];
  }
  return steps[steps.findIndex((s) => s.type === lastStep) + 1];
}

export default function* runGettingStartedSaga(
  serviceContainer: ServiceContainer
): Saga {
  const originRouteJson: string | null =
    sessionStorage.getItem(ORIGIN_ROUTE_KEY);
  let originRoute: State | undefined;
  if (!originRouteJson) {
    originRoute = yield select(selectCurrentRoute);
    if (originRoute) {
      sessionStorage.setItem(ORIGIN_ROUTE_KEY, JSON.stringify(originRoute));
    }
  } else {
    try {
      originRoute = JSON.parse(originRouteJson);
    } catch (e) {
      // Remove the invalid value
      sessionStorage.removeItem(ORIGIN_ROUTE_KEY);
    }
  }

  /** This array is populated as we go. */
  const stepsWithInputRequired: StepType[] = [];

  let nextStep: Step<StepType> | undefined = yield call(getNextStep);

  while (nextStep !== undefined) {
    const stepResult: PreStepResult = yield nextStep.preStepSaga(
      serviceContainer
    );
    let stepCompletedAction: ActionType<typeof stepCompleted>;
    if (stepResult === "input-required") {
      if (!stepsWithInputRequired.includes(nextStep.type)) {
        stepsWithInputRequired.push(nextStep.type);
        yield put(updateStepsWithInputRequired(stepsWithInputRequired.slice()));
      }

      yield put(actions.navigateTo("gettingStarted", { step: nextStep.type }));
      stepCompletedAction = yield take(stepCompleted.type);
    } else {
      stepCompletedAction = stepResult;
      yield put(stepCompletedAction);
    }

    // This handles scenarios where the user has navigated back or forward
    const currentStep =
      steps[
        steps.findIndex((s) => s.type === stepCompletedAction.payload.type)
      ];

    if (currentStep.postStepSaga) {
      yield currentStep.postStepSaga(serviceContainer, stepCompletedAction);
    }

    nextStep = yield call(getNextStep);
  }

  const completedSteps: GettingStartedState["steps"] = yield select(
    selectCompletedSteps
  );

  for (let i = 0; i < steps.length; i += 1) {
    const step = steps[i];
    if (step.saveResultSaga) {
      yield call(step.saveResultSaga, serviceContainer, completedSteps);
    }
  }

  if (originRoute) {
    yield put(actions.navigateTo(originRoute.name, originRoute.params));
  }
  sessionStorage.removeItem(ORIGIN_ROUTE_KEY);
}
