import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import HomeRoot from "../../features/home/components/HomeRoot";
import InventoryLocationRoot from "../../features/inventory-location/components/InventoryLocationRoot";
import ItemInformationRoot from "../../features/item-information/components/ItemInformationRoot";
import MyPageRoot from "../../features/my-page/components/MyPageRoot";
import NotFound from "../../features/routing/components/NotFound";
import View from "../../features/routing/components/View";
import routes from "../../features/routing/routes";
import { selectIsRouteNotFound } from "../../features/routing/routingSelectors";
import StockTakingRoot from "../../features/stock-taking/components/StockTakingRoot";
import useSplashScreen from "../hooks/useSplashScreen";

const NavRoot: React.FC = function () {
  const { hideSplashScreen } = useSplashScreen();
  const isRouteNotFound = useSelector(selectIsRouteNotFound);

  useEffect(() => {
    hideSplashScreen();
  }, [hideSplashScreen]);

  if (isRouteNotFound) {
    return <NotFound />;
  }

  return (
    <>
      <View route={routes.home} component={HomeRoot} />
      <View route={routes.itemInformation} component={ItemInformationRoot} />
      <View route={routes.stockTaking} component={StockTakingRoot} />
      <View route={routes.myPage} component={MyPageRoot} />
      <View
        route={routes.inventoryLocation}
        component={InventoryLocationRoot}
      />
    </>
  );
};

export default NavRoot;
