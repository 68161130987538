import React from "react";
import { useDispatch } from "react-redux";

import ErrorModal from "../../error/components/ErrorModal";
import {
  dismissUpdateItemError,
  UpdateItemError,
} from "../manualStockTakingSlice";

interface ManualStockTakingUpdateItemErrorModalProps {
  error: UpdateItemError;
}

export const ManualStockTakingUpdateItemErrorModal: React.FC<ManualStockTakingUpdateItemErrorModalProps> =
  function ({ error }) {
    const dispatch = useDispatch();

    return (
      <ErrorModal
        data-cy="ManualStockTakingUpdateItemErrorModal"
        category="updateStockTakeItem"
        error={{ reason: error }}
        fallbackErrorKey="unknown"
        onClose={() => dispatch(dismissUpdateItemError())}
        overrideHtmlMetaThemeColor="backdrop-on-primary-surface-light"
      />
    );
  };
