import ScanningContextService, {
  ExternalScanningContextState,
  ScanningContextServiceBase,
} from "./ScanningContextService";
import { CameraError } from "./types";

export default class E2eScanningContextService
  extends ScanningContextServiceBase
  implements ScanningContextService
{
  private error: CameraError | undefined = undefined;

  constructor() {
    super();
    window.cypressOnScan = (result) => {
      this.triggerScan(result);
    };
    window.cypressCameraError = (error) => {
      this.error = error;
    };
  }

  get cameraError(): CameraError | undefined {
    return this.error;
  }

  connectElement(): void {
    // noop
  }

  disconnectElement(): void {
    // noop
  }

  initialize(): Promise<void> {
    return Promise.resolve(undefined);
  }

  startCamera(): Promise<void> {
    return Promise.resolve(undefined);
  }

  startScanning(): Promise<void> {
    return Promise.resolve(undefined);
  }

  get state(): ExternalScanningContextState {
    if (this.error !== undefined) {
      return { state: "error" };
    }
    return {
      state: "ready",
    };
  }

  stopCamera(): Promise<void> {
    return Promise.resolve(undefined);
  }

  stopScanning(): Promise<void> {
    return Promise.resolve(undefined);
  }
}
