import { FeatureFlag } from "@ahlsell-group/store20-config-service";
import { GetParsedResult } from "@ahlsell-group/store20-service-core";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import { selectFeatureFlags } from "../../config/configSelectors";
import { currentCoachMark, setCoachMark } from "../homeSlice";

export default function* coachMarkSaga({
  storageService,
}: ServiceContainer): Saga {
  const { value: isDismissed }: GetParsedResult<boolean> = yield call(
    [storageService, "getAndParse"],
    { key: currentCoachMark.storageName }
  );
  const featureFlags: FeatureFlag[] = yield select(selectFeatureFlags);

  if (!featureFlags.includes(currentCoachMark.featureFlag)) {
    return;
  }

  if (!isDismissed) {
    yield put(setCoachMark(currentCoachMark.name));
  }
}
