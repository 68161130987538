import { GenericOperationError } from "@ahlsell-group/store20-service-core";
import {
  StockTake,
  StockTakingServiceErrorTypes,
} from "@ahlsell-group/store20-stock-taking-service";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

const name = "stockTakenSubmission";

export type GetSubmittedStockTakesErrorReason =
  | StockTakingServiceErrorTypes["getSubmittedStockTakes"]
  | GenericOperationError;

export type StockTakenSubmissionStatus =
  | { type: "idle" }
  | { type: "loading" }
  | { type: "loaded"; submissions: StockTake[] }
  | {
      type: "error";
      error: GetSubmittedStockTakesErrorReason;
    };

export const submissionsRequested = createAction(
  `${name}/submissionsRequested`
);

export interface StockTakenSubmissionState {
  status: StockTakenSubmissionStatus;
}

const initialState: StockTakenSubmissionState = {
  status: { type: "idle" },
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    submissionsLoading(state) {
      state.status = { type: "loading" };
    },
    submissionsLoaded(state, { payload }: PayloadAction<StockTake[]>) {
      state.status = { type: "loaded", submissions: payload };
    },
    submissionsLoadingError(
      state,
      { payload }: PayloadAction<{ reason: GetSubmittedStockTakesErrorReason }>
    ) {
      state.status = { type: "error", error: payload.reason };
    },
  },
});

export const {
  reducer: stockTakenSubmissionReducer,
  actions: { submissionsLoading, submissionsLoaded, submissionsLoadingError },
} = slice;
