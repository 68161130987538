import { SearchSuggestion } from "@ahlsell-group/ui-kit/inputs";
import React, { useMemo } from "react";

import useItemImageUrl from "../item-image/useItemImageUrl";

import { ItemSearchResult } from "./types";

export const useItemSearchSuggestions = (
  searchResult: ItemSearchResult | undefined,
  onSelect: (itemId: string) => void
): JSX.Element[] | undefined => {
  const createItemImageUrl = useItemImageUrl();

  const searchResultItems = useMemo(
    () =>
      searchResult?.items.map((s) => (
        <SearchSuggestion
          title={s.id}
          description={s.name}
          imageUrl={createItemImageUrl(s.assetUrl)}
          onClick={() => onSelect(s.id)}
          data-cy={`item-search-result-${s.id}`}
        />
      )),
    [searchResult?.items, createItemImageUrl, onSelect]
  );

  return searchResultItems;
};
