import itemStockRoutes from "../item-stock/itemStockRoutes";
import { createRoutes } from "../routing/utils";

const itemInformationRoutes = createRoutes({
  itemInformation: {
    path: "/item-information",
    children: {
      itemIdOrBarcode: { path: "/:itemIdOrBarcode?barcode" },
      ...itemStockRoutes,
    },
  },
} as const);

export default itemInformationRoutes;
