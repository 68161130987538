import React from "react";
import { useSelector } from "react-redux";

import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import {
  selectManualStockTakingOpenItem,
  selectManualStockTakingStatus,
  selectItems,
} from "../manualStockTakingSelectors";

import ManualStockTakingItemErrorModal from "./ManualStockTakingItemErrorModal";
import ManualStockTakingItemLoading from "./ManualStockTakingItemLoading";
import ManualStockTakingValidItem from "./ManualStockTakingValidItem";

const ManualStockTakingItem: React.FC<ViewComponentProps> = function () {
  const navigate = useNavigate();
  const status = useSelector(selectManualStockTakingStatus);
  const openItem = useSelector(selectManualStockTakingOpenItem);
  const items = useSelector(selectItems);

  const handleClose = () => navigate.back();

  if (status === "loadingItem") {
    return <ManualStockTakingItemLoading onClose={handleClose} />;
  }

  if (!openItem) {
    // Internal error.
    throw new Error("Internal error, expected open item");
  }

  if (openItem.invalidItem || openItem.error) {
    return (
      <ManualStockTakingItemErrorModal
        item={openItem.invalidItem}
        bestEffortItemId={openItem.itemId}
        enableRemove={items.some((x) => x.itemId === openItem.itemId)}
        overrideError={openItem.error}
        onClose={handleClose}
        onRemove={() => {
          navigate(routes.stockTaking.manual.item.remove, {
            itemId: openItem.itemId,
          });
        }}
      />
    );
  }

  if (!openItem.validItem) {
    throw new Error("Internal error, expected valid item");
  }

  return <ManualStockTakingValidItem item={openItem.validItem} />;
};

export default ManualStockTakingItem;
