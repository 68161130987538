import { ItemSearchService } from "@ahlsell-group/store20-product-service";
import { call } from "redux-saga/effects";

import { Saga } from "../../../types";

export default function* getItemIdByBarcode(
  barcode: string,
  itemSearchService: ItemSearchService
): Saga<string | undefined> {
  return yield call([itemSearchService, "getItemIdByBarcode"], barcode, false);
}
