import {
  ErrorKeysByCategory,
  useErrorMessage,
  useTranslation,
} from "@ahlsell-group/app-localization";
import { ExclamationIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Alert } from "@ahlsell-group/ui-kit/feedback";
import { LinkButton } from "@ahlsell-group/ui-kit/navigation";
import React, { useState } from "react";
import { UAParser } from "ua-parser-js";

import RouteLink from "../../routing/components/RouteLink";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import { CameraError } from "../types";

interface CameraAccessAlertProps {
  error: CameraError;
}

const CameraAccessAlert: React.FC<CameraAccessAlertProps> = function ({
  error,
}) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const isIos = UAParser(navigator.userAgent).os.name?.toLowerCase() === "ios";

  const isNotAllowedError = error === "NotAllowedError";
  const errorKey: ErrorKeysByCategory<"cameraAccess"> = isNotAllowedError
    ? "not-allowed"
    : "other";

  const errorMessage = useErrorMessage("cameraAccess", errorKey);

  const reload = async () => {
    await navigate.back().then(() => {
      window.location.reload();
    });
  };

  if (!errorMessage || !show) return null;

  return (
    <Alert
      data-cy={`CameraAccessAlert-${error}`}
      className="m-4"
      severity="warning"
      icon={ExclamationIcon}
      onDismiss={() => setShow(false)}
    >
      <Typography className="mb-1" variant="body-sm">
        {t(errorMessage.headerKey)}
      </Typography>
      {isNotAllowedError && isIos ? (
        <LinkButton className="-ml-2" onClick={reload}>
          {t("error.reloadPage")}
        </LinkButton>
      ) : (
        <RouteLink
          className="-ml-2"
          variant="standalone"
          route={routes.myPage.questionsAnswers}
          routeParams={{
            q: isNotAllowedError ? "allow-camera-android" : "cannot-scan",
          }}
        >
          {t(errorMessage.detailKey)}
        </RouteLink>
      )}
    </Alert>
  );
};

export default CameraAccessAlert;
