/**
 * App installation = add-to-home-screen (A2HS) = PWA installation.
 */
export default class AppInstallationService {
  isInstalled() {
    // If it is already installed (=standalone), don't show a prompt.
    return Boolean(
      // Trusted web app (Android).
      document.referrer.startsWith("android-app://") ||
        // Non-standard Safari check.
        (navigator as any).standalone ||
        // Standard check.
        matchMedia("(display-mode:standalone)").matches
    );
  }
}
