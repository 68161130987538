import { App } from "@capacitor/app";

export interface NativeAppService {
  bootstrapEvents(): void;
}

export class NativeAppServiceImpl implements NativeAppService {
  bootstrapEvents() {
    // This event only happens on Android
    App.addListener("backButton", (event) => {
      if (event.canGoBack) {
        window.history.back();
      } else {
        App.exitApp();
      }
    });
  }
}
