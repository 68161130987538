export interface StockTakenSubmissionDateConfig {
  showSubmittedStockTakesForDays: number;
  date: Date;
}

export default function stockTakenSubmissionDateConfig(): StockTakenSubmissionDateConfig {
  const showSubmittedStockTakesForDays = 3;
  const date = new Date();
  date.setDate(date.getDate() - showSubmittedStockTakesForDays);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return { showSubmittedStockTakesForDays, date };
}
