import Listeners, { Unsubscribe } from "./Listeners";
import { Barcode, CameraError } from "./types";

export interface UninitializedScanningContextState {
  state: "uninitialized";
}

export interface InitializingScanningContextState {
  state: "initializing";
}

export interface ReadyScanningContextState {
  state: "ready" | "camera-active";
}

export interface ErrorScanningContextState {
  state: "error";
  error: CameraError;
}

export type ScanningContextState =
  | UninitializedScanningContextState
  | InitializingScanningContextState
  | ReadyScanningContextState
  | ErrorScanningContextState;

export type ExternalScanningContextState = Pick<ScanningContextState, "state">;

export type ScanningContextStateListener = (
  state: ScanningContextState,
  previousState: ScanningContextState
) => void;
export type OnScanListener = (newlyRecognizedBarcodes: Barcode[]) => void;

export default interface ScanningContextService {
  get state(): ExternalScanningContextState;

  onStateChange(listener: ScanningContextStateListener): Unsubscribe;

  onScan(listener: OnScanListener): Unsubscribe;

  initialize(): Promise<void>;

  connectElement(element: HTMLElement): void;

  disconnectElement(): void;

  startCamera(): Promise<void>;

  startScanning(): Promise<void>;

  stopScanning(): Promise<void>;

  stopCamera(): Promise<void>;

  get cameraError(): CameraError | undefined;
}

export abstract class ScanningContextServiceBase
  implements Pick<ScanningContextService, "onStateChange" | "onScan">
{
  private stateChangeListeners = new Listeners<ScanningContextStateListener>();
  private onScanListeners = new Listeners<OnScanListener>();

  onScan(listener: OnScanListener): Unsubscribe {
    return this.onScanListeners.add(listener);
  }

  protected triggerScan(newlyRecognizedBarcodes: Barcode[]) {
    this.onScanListeners.invoke(newlyRecognizedBarcodes);
  }

  onStateChange(listener: ScanningContextStateListener): Unsubscribe {
    return this.stateChangeListeners.add(listener);
  }

  protected triggerStateChange(
    newState: ScanningContextState,
    oldState: ScanningContextState
  ) {
    this.stateChangeListeners.invoke(newState, oldState);
  }
}
