import { useTranslation } from "@ahlsell-group/app-localization";
import {
  LoadingPage,
  LoadingPageProps,
} from "@ahlsell-group/app-ui/LoadingPage";
import React from "react";

export type AppLoadingPageProps = Omit<LoadingPageProps, "cancelLabel">;

/**
 * A `<LoadingPage>` that sets the cancel text automatically.
 */
export const AppLoadingPage: React.FC<AppLoadingPageProps> = function (args) {
  const { t } = useTranslation("common");
  return <LoadingPage cancelLabel={t("cancel")} {...args} />;
};
