import { LogService, LogType } from "@ahlsell-group/store20-log-service";
import { OperationFailedError } from "@ahlsell-group/store20-service-core";
import { call, Effect } from "redux-saga/effects";

import { Saga } from "../../../types";

export type HandleSagaErrorFn = ReturnType<typeof handleSagaErrorFactory>;

export type LogTypeFactory = (errorCode: string) => LogType;

const isEffect = (maybeEffect: any): maybeEffect is Effect =>
  "@@redux-saga/IO" in maybeEffect && maybeEffect["@@redux-saga/IO"] === true;

export default function handleSagaErrorFactory(logService: LogService) {
  return function* handleSagaError(
    errorType: LogType | LogTypeFactory,
    error: unknown,
    effectOrEffectMap: Effect | Record<string, Effect> = {}
  ): Saga {
    let errorCode: string = "unknown";
    if (error instanceof OperationFailedError) {
      errorCode = error.type;
    }

    yield call(
      [logService, "log"],
      errorCode === "unknown" ? "critical" : "warning",
      typeof errorType === "function" ? errorType(errorCode) : errorType,
      {
        error,
        errorCode,
      }
    );

    const effect = isEffect(effectOrEffectMap)
      ? effectOrEffectMap
      : effectOrEffectMap[errorCode] ?? effectOrEffectMap["*"];
    if (effect) {
      yield effect;
    }
  };
}
