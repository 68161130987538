import { NavigationOptions } from "router5";

import { navigateBack } from "./navigateBack";
import router from "./router";
import { AppRoute } from "./utils";

const navigate = (
  fullRoute: AppRoute,
  routeParams?: Record<string, any>,
  options?: NavigationOptions
) => router.navigate(fullRoute.route, routeParams!, options!);

navigate.back = navigateBack;

export default () => navigate;
