import { call, put } from "redux-saga/effects";

import { Saga } from "../../types";
import ActionType from "../../util/ActionType";
import { triggerLoginRedirect, userLoginFailed } from "../appSlice";
import { ServiceContainer } from "../serviceContainer";

/**
 * Must only be called when it is certain that a redirect *will* happen.
 */
function* triggerLoginRedirectSaga(
  { authenticationService, handleSagaError }: ServiceContainer,
  action: ActionType<typeof triggerLoginRedirect>
): Saga {
  try {
    yield call(
      [authenticationService, "ensureAccessToken"],
      action.payload.scopes
    );

    // Shouldn't be able to arrive here.
    throw new Error("ensureAccessToken didn't redirect");
  } catch (error) {
    yield* handleSagaError(
      "saga:login:triggerLoginRedirectSaga:unknown",
      error,
      put(userLoginFailed("unknown"))
    );

    throw error;
  }
}

export default triggerLoginRedirectSaga;
