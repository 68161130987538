import { useTranslation } from "@ahlsell-group/app-localization";
import { SearchIcon } from "@ahlsell-group/ui-kit-imagery-react/icons";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import React, { useCallback } from "react";

import BarcodeScannerModal from "../../barcode-scanner/components/BarcodeScannerModal";
import { OnBarcodesScannedHandler } from "../../barcode-scanner/types";
import RouteLink from "../../routing/components/RouteLink";
import View from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";

const HomeScan: React.FC = function () {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const handleScanNew: OnBarcodesScannedHandler = useCallback(
    async (scanResult) => {
      navigate(routes.itemInformation.itemIdOrBarcode, {
        itemIdOrBarcode: scanResult[0].data,
        barcode: true,
      });
    },
    [navigate]
  );

  return (
    <View
      route={routes.home.scan}
      renderMode="mount"
      element={
        <BarcodeScannerModal
          onBarcodesScanned={handleScanNew}
          onClose={() => navigate(routes.home)}
          leftActionContent={
            <Typography variant="heading-h6">
              <RouteLink
                variant="inline"
                route={routes.home.itemSearch}
                icon={SearchIcon}
                iconProps={{ size: "small" }}
                className="text-theme-primary-light"
                data-cy="scanner-item-search"
              >
                {t("barcodeScanning.searchItem")}
              </RouteLink>
            </Typography>
          }
          onError={(error) =>
            navigate(
              routes.home.itemSearch,
              { cameraError: error },
              { replace: true }
            )
          }
        />
      }
      exact
    />
  );
};

export default HomeScan;
