import { useTranslation } from "@ahlsell-group/app-localization";
import {
  LogoAceVertical,
  Typography,
} from "@ahlsell-group/ui-kit/data-display";
import { Center } from "@ahlsell-group/ui-kit/layout";
import React from "react";
import { useDispatch } from "react-redux";

import { stepCompleted } from "../gettingStartedSlice";
import { StepComponent } from "../types";

import GettingStartedPage from "./GettingStartedPage";

const Welcome: StepComponent = function Welcome({ hasPreviousStep }) {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const onContinue = () => {
    dispatch(stepCompleted({ type: "welcome" }));
  };

  return (
    <GettingStartedPage
      data-cy="Welcome"
      actions={{
        getStarted: {
          "data-cy": "Welcome-GetStarted",
          text: t("gettingStarted.welcome.getStartedButtonText"),
          primary: true,
        },
      }}
      onAction={onContinue}
      backButton={hasPreviousStep}
    >
      <Center variant="center" className="text-center">
        <LogoAceVertical width="5rem" className="inline-block" />
        <Typography variant="heading-h2" className="mt-4">
          {t("gettingStarted.welcome.welcomeText")}
        </Typography>
      </Center>
    </GettingStartedPage>
  );
};

export default Welcome;
