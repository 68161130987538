import { useTranslation } from "@ahlsell-group/app-localization";
import { ExclamationIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import React from "react";

import ItemImage from "../../item-image/components/ItemImage";
import RouteLink from "../../routing/components/RouteLink";
import routes from "../../routing/routes";
import { InventoryLocationItemWithDetails } from "../inventoryLocationSlice";

export interface InventoryLocationMenuErrorItemProps {
  item: InventoryLocationItemWithDetails;
}

const InventoryLocationMenuErrorItem: React.FC<InventoryLocationMenuErrorItemProps> =
  function ({ item: { itemId, details } }) {
    const { t } = useTranslation("common");

    return (
      <RouteLink
        variant="raw"
        route={routes.itemInformation.itemIdOrBarcode}
        routeParams={{ itemIdOrBarcode: itemId }}
        className="relative flex flex-row items-center gap-4 rounded w-full p-4 mb-2 bg-theme-text-primary-light"
        data-cy={`InventoryLocationMenuErrorItem-${itemId}`}
      >
        <div className="absolute top-1 right-1 p-1 bg-theme-text-primary-light">
          <ExclamationIcon className="text-theme-status-error" size="0.75rem" />
        </div>

        <ItemImage
          assetUrl={details.assetUrl}
          alt={details.partDescription2 ?? details.partDescription1}
          size="small"
        />

        <div className="flex flex-col grow gap-4">
          <Typography variant="body-sm" color="gray">
            <div>{details.partDescription1}</div>
            <Typography variant="body-xs">
              {t("stockTaking.articleNumberShort")} {itemId}
            </Typography>
          </Typography>
          <Typography variant="body-sm" color="error">
            {t("inventoryLocation.locationNotChanged")}
          </Typography>
        </div>
      </RouteLink>
    );
  };

export default InventoryLocationMenuErrorItem;
