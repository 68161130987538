import { Trans, useTranslation } from "@ahlsell-group/app-localization";
import {
  Modal,
  ModalAction,
  ModalActions,
  ModalHeader,
} from "@ahlsell-group/ui-kit/feedback";
import React from "react";
import { useSelector } from "react-redux";

import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import { selectInventoryLocationSubmittedItems } from "../inventoryLocationSelectors";
import { isFailedItemStatus } from "../util";

const InventoryLocationSomeItemsNotChangedModal: React.ComponentType =
  function () {
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const submittedItems = useSelector(selectInventoryLocationSubmittedItems);
    const failedItems = submittedItems?.filter((item) =>
      isFailedItemStatus(item.status)
    );

    return (
      <Modal onCancel={() => navigate.back()} open variant="card">
        <ModalHeader data-cy="InventoryLocationSomeItemsNotChangedModal">
          <Trans
            t={t}
            i18nKey="inventoryLocation.itemsFailed"
            count={failedItems?.length}
          >
            <b />
          </Trans>
        </ModalHeader>
        <ModalActions orientation="vertical">
          <ModalAction
            data-cy="InventoryLocationSomeItemsNotChangedModal-failed"
            mode="button"
            variant="tertiary"
            onClick={() =>
              navigate(
                routes.inventoryLocation.change.submitted,
                {
                  failed: true,
                },
                { replace: true }
              )
            }
          >
            {t("inventoryLocation.viewItems", { count: failedItems?.length })}
          </ModalAction>
          <ModalAction
            data-cy="InventoryLocationSomeItemsNotChangedModal-continue"
            mode="button"
            variant="primary"
            onClick={() => navigate.back()}
          >
            {t("continue")}
          </ModalAction>
        </ModalActions>
      </Modal>
    );
  };

export default InventoryLocationSomeItemsNotChangedModal;
