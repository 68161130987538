import i18n, { useTranslation } from "@ahlsell-group/app-localization";
import { StockDeliveryIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { LinkButton } from "@ahlsell-group/ui-kit/navigation";
import classNames from "classnames";
import React from "react";

import isTruthy from "../../../util/isTruthy";
import useItemStock from "../useItemStock";

export type ItemStockProps = {
  warehouseId: number;
  warehouseName?: string;
  itemId: string;
  size?: "medium" | "small";
};

const sizeStyles = {
  small: "body-sm",
  medium: "body",
} as const;

const ItemStock: React.FC<ItemStockProps> = function ItemStock({
  warehouseId,
  warehouseName,
  itemId,
  size = "medium",
}) {
  const { t } = useTranslation("common");
  const [itemStock, refresh] = useItemStock(warehouseId, itemId);

  if (!itemStock) {
    return null;
  }

  const { state, stockBalance, unit, notAvailableState } = itemStock;

  if (state === "error") {
    return (
      <Typography variant={sizeStyles[size]}>
        {t("itemStock.failedToLoadStock")}
        {itemStock.errorReason !== "not-found" && (
          <LinkButton onClick={refresh}>{t("tryAgain")}</LinkButton>
        )}
      </Typography>
    );
  }

  if (state === "loading") {
    return null;
  }

  const main = [
    stockBalance !== undefined &&
      t("itemStock.itemsInStock", {
        stockBalance: Intl.NumberFormat(i18n.language).format(stockBalance),
        unit,
      }),

    notAvailableState === "procurement-item" && t("itemStock.procurementItem"),
  ].find(isTruthy);

  if (!main) {
    return null;
  }

  const description = warehouseName ? `${main} (${warehouseName})` : main;

  return (
    <div className="flex flex-row gap-2 items-center">
      <StockDeliveryIcon
        size={size}
        className={classNames({
          "fill-success-fill text-success": stockBalance && stockBalance > 3,
          "fill-warning-fill text-warning":
            stockBalance && stockBalance > 0 && stockBalance <= 3,
          "fill-error-fill text-error":
            stockBalance === undefined || stockBalance === 0,
        })}
      />
      <Typography variant={sizeStyles[size]} data-cy="ItemStock-balance">
        {description}
      </Typography>
    </div>
  );
};

export default ItemStock;
