import { Page, PageProps } from "@ahlsell-group/app-ui/Page";
import React from "react";

import NavBar from "./NavBar";

export type AppPageProps = Omit<PageProps, "nav">;

/**
 * A `<Page>` that contains the app's nav bar.
 */
export const AppPage: React.FC<AppPageProps> = function (args) {
  return <Page nav={<NavBar />} {...args} />;
};
