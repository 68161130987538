import { all, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";

import {
  appReadyToStart,
  languageChangeRequested,
  triggerLoginRedirect,
} from "./appSlice";
import changeLanguageSaga from "./sagas/changeLanguageSaga";
import startAppSaga from "./sagas/startAppSaga";
import triggerLoginRedirectSaga from "./sagas/triggerLoginRedirectSaga";
import { ServiceContainer } from "./serviceContainer";

export default function* appSagas(serviceContainer: ServiceContainer) {
  yield all([
    takeLeading(appReadyToStart, startAppSaga, serviceContainer),
    takeEvery(languageChangeRequested, changeLanguageSaga, serviceContainer),
    takeLatest(
      triggerLoginRedirect,
      triggerLoginRedirectSaga,
      serviceContainer
    ),
  ]);
}
