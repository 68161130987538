import { miniSerializeError } from "@reduxjs/toolkit";
import createSagaMiddleware, {
  SagaMiddleware,
  SagaMiddlewareOptions,
} from "redux-saga";

import { unhandledSagaError } from "../../features/error/errorSlice";
import logUnhandledError from "../../features/error/logUnhandledError";
import { ServiceContainer } from "../serviceContainer";

import rootSaga from "./rootSaga";

type ErrorInfo = Parameters<Required<SagaMiddlewareOptions>["onError"]>[1];

export type RunSagaFn = (serviceContainer: ServiceContainer) => void;

export default function createSaga({
  logService,
  storeAccessor,
}: ServiceContainer): [middleware: SagaMiddleware, runSaga: RunSagaFn] {
  const onError = (error: Error, errorInfo: ErrorInfo) => {
    logUnhandledError(logService, "unhandled:saga", error, errorInfo);

    storeAccessor
      .getStore()
      .dispatch(
        unhandledSagaError({ ...miniSerializeError(error), ...errorInfo })
      );
  };

  const saga = createSagaMiddleware({
    onError,
  });

  const runSaga: RunSagaFn = (serviceContainer) =>
    saga.run(rootSaga, serviceContainer);

  return [saga, runSaga];
}
