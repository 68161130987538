import {
  BoxedLink,
  Link,
  LinkProps,
  BoxedLinkProps,
} from "@ahlsell-group/ui-kit/navigation";
import React from "react";

import useNavigate from "../useNavigate";
import useRouter from "../useRouter";
import { AppRoute } from "../utils";

interface RouteLinkPropsBase {
  route: AppRoute;
  routeParams?: Record<string, any>;
}

type InlineRouteLinkProps = RouteLinkPropsBase &
  Omit<LinkProps, "variant"> & {
    variant: "inline" | "standalone";
  };

type BoxedRouteLinkProps = RouteLinkPropsBase &
  BoxedLinkProps & {
    variant: "boxed";
  };

type RawRouteLinkProps = RouteLinkPropsBase &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    variant: "raw";
    size?: undefined;
    icon?: undefined;
    iconProps?: undefined;
  };

type RouteLinkProps = Omit<
  InlineRouteLinkProps | BoxedRouteLinkProps | RawRouteLinkProps,
  "href"
>;

const RouteLink: React.FC<RouteLinkProps> = function ({
  route,
  routeParams,
  onClick,
  variant,
  size,
  ...rest
}) {
  const router = useRouter();
  const navigate = useNavigate();
  const path = router.buildPath(route.route, routeParams);

  const hrefOnclick: React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    href: string;
    onClick: Function;
  } = {
    href: path,
    onClick(e) {
      onClick?.(e);

      // If the `onClick` handler has already prevented the default action,
      // don't navigate to the route.
      if (!e.defaultPrevented) {
        e.preventDefault();
        navigate(route, routeParams);
      }
    },
  };

  if (variant === "boxed") {
    return <BoxedLink size={size ?? "large"} {...hrefOnclick} {...rest} />;
  }

  if (variant === "raw") {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...hrefOnclick} {...rest} />;
  }

  return <Link variant={variant} {...hrefOnclick} {...rest} />;
};

export default React.memo(RouteLink);
