import { Selector } from "../../types";

import { PurchaseHoldWithState } from "./purchaseHoldSlice";

export const selectPurchaseHold =
  (
    stockId: number,
    itemId: string
  ): Selector<PurchaseHoldWithState | undefined> =>
  (state) =>
    state.purchaseHold.items[`${stockId}-${itemId}`];
