import { useTranslation } from "@ahlsell-group/app-localization";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import React from "react";
import { useSelector } from "react-redux";

import { selectShowCoachMark } from "../homeSelectors";

const CoachMarkMessage: React.FC = function () {
  const showCoachMark = useSelector(selectShowCoachMark);
  const { t } = useTranslation("common");

  if (!showCoachMark) return null;

  return (
    <div className="z-20 flex flex-col md:hidden">
      <Typography
        variant="card-heading"
        className="text-center font-['Caveat'] text-theme-status-attention mr-4 -rotate-2"
      >
        {t("temp.stockTakingReviewNowInAce")}
      </Typography>
      <div className="flex self-center">
        <svg width="72" height="73" viewBox="0 0 72 73" fill="none">
          <path
            d="M31.2239 48.2611C29.1718 51.4131 27.105 53.5282 24.0048 56.701C22.9714 57.7585 24.0342 58.7745 25.0823 58.7538C27.1786 58.7122 28.2414 59.7282 30.3376 59.6866C32.4338 59.6451 33.4966 60.6611 35.5928 60.6195C36.6409 60.5987 36.6262 59.5619 35.5634 58.5459C34.5006 57.5299 32.4044 57.5715 30.3082 57.613L29.2601 57.6338C30.3082 57.613 31.3563 57.5923 32.3897 56.5347C34.4712 55.4563 37.6008 54.3572 39.6823 53.2788C43.8306 50.0853 46.9161 45.8758 47.8906 40.671C48.8652 35.4662 46.6807 29.287 44.5257 25.1814C43.4481 23.1286 41.3078 20.0597 39.1822 18.0277C38.1194 17.0117 37.0565 15.9957 35.9937 14.9797C34.9309 13.9637 33.8681 12.9476 32.8053 11.9316C31.7572 11.9524 30.7091 11.9732 31.7719 12.9892C32.8347 14.0052 33.8975 15.0212 33.9122 16.058C34.975 17.074 36.0379 18.0901 37.1007 19.1061C39.2263 21.1381 40.3038 23.1909 41.3814 25.2437C43.5364 29.3494 45.7062 34.4918 44.7316 39.6965C43.757 44.9013 41.7197 49.0901 37.5567 51.2468C35.4752 52.3252 33.3937 53.4035 31.3121 54.4819C30.264 54.5027 29.2159 54.5234 29.2306 55.5602C30.264 54.5027 32.3308 52.3875 33.3642 51.3299C34.3682 48.1987 32.2426 46.1667 31.2239 48.2611Z"
            fill="#FF5900"
          />
        </svg>
      </div>
    </div>
  );
};

export default CoachMarkMessage;
