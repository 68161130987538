import { useTranslation } from "@ahlsell-group/app-localization";
import { PageSubheadline } from "@ahlsell-group/app-ui/PageSubheadline";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
} from "@ahlsell-group/ui-kit/surfaces";
import React from "react";

import { AppPage } from "../../page/components/AppPage";
import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import useRoute from "../../routing/useRoute";
import { getPage, getQuestionHeaders } from "../content";

const MyPageQuestionsAndAnswers: React.FC<ViewComponentProps<{ q?: string }>> =
  function ({ params }) {
    const openQuestion = params.q;
    const { t } = useTranslation("common");
    const [, navigateTo] = useRoute(routes.myPage.questionsAnswers);
    const navigate = useNavigate();
    const headers = getQuestionHeaders();

    return (
      <AppPage
        data-cy="MyPageQuestionsAndAnswers"
        headline={
          <PageSubheadline
            onBack={() => navigate.back()}
            back-data-cy="MyPageQuestionsAndAnswers-back"
          >
            {t("myPage.questionsAndAnswers")}
          </PageSubheadline>
        }
      >
        <AccordionGroup scrollOnExpand>
          {headers.map(({ slug, header }) => (
            <Accordion
              key={slug}
              expanded={openQuestion === slug}
              onChange={(expanded) => {
                const urlParams = expanded ? { q: slug } : undefined;
                navigateTo(urlParams, { replace: true });
              }}
            >
              <AccordionSummary
                data-cy={`MyPageQuestionsAndAnswers-question-${slug}`}
              >
                {header}
              </AccordionSummary>
              <AccordionDetails
                data-cy={`MyPageQuestionsAndAnswers-answer-${slug}`}
              >
                <Typography variant="body-sm" component="span">
                  {getPage("questionsAndAnswers", slug)?.detail}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </AccordionGroup>
      </AppPage>
    );
  };

export default MyPageQuestionsAndAnswers;
