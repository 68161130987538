import { call, delay } from "redux-saga/effects";

export default function* runEvery<S extends (...args: any[]) => any>(
  ms: number,
  saga: S,
  ...params: Parameters<S>
) {
  while (true) {
    yield delay(ms);
    yield call<S>(saga, ...params);
  }
}
