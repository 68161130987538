import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type BackgroundUpdateType = "config" | "inventoryLocationErrors";

interface BackgroundUpdatePayload {
  timestamp: number;
  types: BackgroundUpdateType[];
}

export type BackgroundUpdateStateLastPerformed = Partial<
  Record<BackgroundUpdateType, number>
>;

export interface BackgroundUpdateState {
  isRunning: boolean;
  lastPerformed: BackgroundUpdateStateLastPerformed;
}

const initialState: BackgroundUpdateState = {
  isRunning: false,
  lastPerformed: {},
};

const slice = createSlice({
  name: "backgroundUpdate",
  initialState,
  reducers: {
    backgroundUpdateStarted(state) {
      state.isRunning = true;
    },

    backgroundUpdatePerformed(
      state,
      { payload }: PayloadAction<BackgroundUpdatePayload>
    ) {
      state.isRunning = false;
      payload.types.forEach((type) => {
        state.lastPerformed[type] = payload.timestamp;
      });
    },
  },
});

export const {
  reducer: backgroundUpdateReducer,
  actions: { backgroundUpdateStarted, backgroundUpdatePerformed },
} = slice;
