import { OperationFailedError } from "@ahlsell-group/store20-service-core";
import { call, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import { selectCurrentStockTakeId } from "../manualStockTakingSelectors";

import { initializeManualStockTakingSaga } from "./initializeManualStockTakingSaga";

/**
 * Get the current stock-take ID, initializing if necessary.
 */
export function* getCurrentStockTakeId(
  serviceContainer: ServiceContainer
): Saga<string> {
  let stockTakeId: string | undefined = yield select(selectCurrentStockTakeId);

  if (!stockTakeId) {
    stockTakeId = yield call(initializeManualStockTakingSaga, serviceContainer);
  }

  if (!stockTakeId) {
    throw new OperationFailedError("getCurrentStockId-failed");
  }

  return stockTakeId;
}
