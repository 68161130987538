import {
  createAction,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { ErrorInfo as ReactErrorInfo } from "react";

export interface ErrorState {
  sagaError?: UnhandledErrorInfo;
}

interface SagaErrorInfo {
  sagaStack: string;
}

export type UnhandledErrorInfo = SerializedError &
  Partial<ReactErrorInfo> &
  Partial<SagaErrorInfo>;

const name = "error";

export const triggerSagaError = createAction(`${name}/triggerSagaError`);

const initialState: ErrorState = {};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    unhandledSagaError(state, action: PayloadAction<UnhandledErrorInfo>) {
      state.sagaError = action.payload;
    },
  },
});

export const {
  reducer: errorReducer,
  actions: { unhandledSagaError },
} = slice;
