/* eslint-disable no-console */
import {
  BarcodeScannerService,
  ScanningContextOptions,
} from "../../BarcodeScannerService";
import { ScanningContext } from "../../ScanningContext";

import { ScanningContextMockImpl } from "./ScanningContextMock";

export class BarcodeScannerServiceMockImpl implements BarcodeScannerService {
  getOrCreateScanningContext({
    element,
  }: ScanningContextOptions = {}): Promise<ScanningContext> {
    console.log(`creating context for element: ${element}`);

    return Promise.resolve(new ScanningContextMockImpl());
  }

  setElement(element: HTMLElement | null): void {
    console.log(`set element: ${element}`);
  }
}
