import { all, takeEvery } from "redux-saga/effects";

import { ServiceContainer } from "../../app/serviceContainer";
import { Saga } from "../../types";
import runEvery from "../../util/sagaHelpers/runEvery";

import { stockBalanceRequired } from "./itemStockSlice";
import { storesRequired } from "./nearbyStoresSlice";
import expireItemsSaga from "./sagas/expireItemsSaga";
import loadItemStockSaga from "./sagas/loadItemStockSaga";
import loadNearbyStoresSaga from "./sagas/loadNearbyStoresSaga";

export default function* itemStockSagas(
  serviceContainer: ServiceContainer
): Saga {
  yield all([
    takeEvery(stockBalanceRequired, loadItemStockSaga, serviceContainer),
    takeEvery(storesRequired, loadNearbyStoresSaga, serviceContainer),
    runEvery(1000 * 60, expireItemsSaga),
  ]);
}
