import { PageNavItem } from "@ahlsell-group/app-ui/PageNavItem";
import { IconComponent } from "@ahlsell-group/ui-kit-imagery-react";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { startsWithSegment } from "router5-helpers";

import { selectCurrentRoute } from "../routingSelectors";
import useRouter from "../useRouter";
import { AppRoute } from "../utils";

export type RoutingPageNavItemProps = React.PropsWithChildren<{
  route: AppRoute;
  params?: Record<string, any>;
  icon: IconComponent;
  badge?: "error" | "info";
}>;

const RoutingPageNavItem: React.FC<RoutingPageNavItemProps> = function ({
  route: { route },
  params,
  icon,
  children,
  badge,
  ...rest
}) {
  const router = useRouter();

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      router.navigate(route, params ?? {});
    },
    [router, route, params]
  );

  const href = useMemo(
    () => router.buildPath(route, params),
    [router, route, params]
  );

  const currentRoute = useSelector(selectCurrentRoute);

  const isActive =
    currentRoute !== undefined && startsWithSegment(currentRoute, route);

  return (
    <PageNavItem
      href={href}
      onClick={handleClick}
      icon={icon}
      isActive={isActive}
      badge={badge}
      {...rest}
    >
      {children}
    </PageNavItem>
  );
};

export default RoutingPageNavItem;
