import React from "react";
import ReactMarkdown, { Options } from "react-markdown";

interface ItemHtmlFieldProps {
  /** An item description that may contain basic HTML. */
  children: string;
}

const markdownOptions: Omit<Options, "children"> = {
  allowedElements: ["p", "strong", "em"],
  unwrapDisallowed: true,
};

const tagReplacements: Record<string, string | undefined> = {
  br: "\n\n", // <br/>
  b: "**", // <b>...</b>
  i: "*", // <i>...</i>
  // Non-standard tags.
  bold: "**", // <bold>...</bold>
  lt: "&lt;", // <lt/>
  gt: "&gt;", // <gt/>
  // <sup> and <sub> don't have Markdown equivalents, so they won't be supported.
};

/**
 * Render an item HTML field, i.e. description or technical specification. It
 * is rendered by interpreting it as Markdown, allowing only a few tags, always
 * without attributes: `br, b, i, sup, sub`.
 */
const ItemHtmlField: React.FC<ItemHtmlFieldProps> = function ({ children }) {
  // `<ReactMarkdown>` doesn't support HTML without including a heavy plugin,
  // so do some basic replacement here instead.
  const markdown = children.replace(
    /<\/?([a-z]+) ?\/?>/g,
    (match, tag) => tagReplacements[tag] ?? match
  );

  return <ReactMarkdown {...markdownOptions}>{markdown}</ReactMarkdown>;
};

export default ItemHtmlField;
