import React from "react";

import View from "../../routing/components/View";
import routes from "../../routing/routes";

import InventoryLocationFailedStatusInfo from "./InventoryLocationFailedStatusInfo";
import InventoryLocationNotification from "./InventoryLocationNotification";
import InventoryLocationSubmitted from "./InventoryLocationSubmitted";

const InventoryLocationSubmittedRoot: React.FC = function () {
  return (
    <>
      <div className="absolute px-4 flex items-center pt-4 w-full">
        <InventoryLocationNotification />
      </div>
      <View
        component={InventoryLocationSubmitted}
        route={routes.inventoryLocation.change.submitted}
        exact
      />

      <View
        component={InventoryLocationFailedStatusInfo}
        route={routes.inventoryLocation.change.submitted.item}
        exact
      />
    </>
  );
};

export default InventoryLocationSubmittedRoot;
