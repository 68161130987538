import { useEffect } from "react";

import useServiceContainer from "../../app/components/ServiceContainerContext";

import logUnhandledError from "./logUnhandledError";

const useUncaughtErrorLogger = () => {
  const { logService } = useServiceContainer();

  useEffect(() => {
    const listener = (event: ErrorEvent) => {
      logUnhandledError(logService, "unhandled:global", event);
    };

    window.addEventListener("error", listener);

    return () => {
      window.removeEventListener("error", listener);
    };
  }, [logService]);
};

export default useUncaughtErrorLogger;
