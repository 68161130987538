import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";

export type BootstrapAppState = {
  state: "bootstrapping";
};

export type StartingAppState = {
  state: "starting";
  config: BootstrapConfig;
  user?: User;
  loginError?: UserLoginError;
  language: string;
};

export type ReadyAppState = {
  state: "ready";
  config: BootstrapConfig;
  user: User;
  language: string;
};

export type InstallModeAppState = {
  state: "install-mode";
};

export type AppState =
  | BootstrapAppState
  | StartingAppState
  | ReadyAppState
  | InstallModeAppState;

export interface User {
  name?: string;
  email: string;
  warehouseId: number;
  legalEntityId: number;
  vivaldiEmployeeNumber: string;
}

export type UserLoginError =
  | "unknown"
  | "internal-server-error"
  | "unknown-init-error"
  | "invalid-legal-entity";

export const languageChangeRequested = createAction<string>(
  "app/languageChangeRequested"
);

export interface TriggerLoginRedirectPayload {
  scopes: string[];
}

export const triggerLoginRedirect = createAction<TriggerLoginRedirectPayload>(
  "app/triggerLoginRedirect"
);

const initialState = {
  state: "bootstrapping",
} satisfies AppState as AppState;

interface AppReadyToStartPayload {
  bootstrapConfig: BootstrapConfig;
  initialLanguage: string;
}

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    appReadyToStart(state, { payload }: PayloadAction<AppReadyToStartPayload>) {
      return {
        state: "starting",
        config: payload.bootstrapConfig,
        language: payload.initialLanguage,
      };
    },
    appStarted(state) {
      state.state = "ready";
    },
    appStartedInInstallMode(state) {
      state.state = "install-mode";
    },
    userLoginSucceeded(state, { payload }: PayloadAction<User>) {
      if (state.state !== "bootstrapping" && state.state !== "install-mode") {
        state.user = payload;
      }
    },
    userLoginFailed(state, { payload }: PayloadAction<UserLoginError>) {
      if (state.state === "starting") {
        state.loginError = payload;
      }
    },
    languageChanged(state, { payload: language }: PayloadAction<string>) {
      if (state.state !== "bootstrapping" && state.state !== "install-mode") {
        state.language = language;
      }
    },
  },
});

export const {
  reducer: appReducer,
  actions: {
    appReadyToStart,
    appStarted,
    appStartedInInstallMode,
    userLoginSucceeded,
    userLoginFailed,
    languageChanged,
  },
} = slice;
