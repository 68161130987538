import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ScanningMode = "continuous" | "manual";

export interface BarcodeScannerState {
  scanningMode: ScanningMode;
}

const slice = createSlice({
  name: "barcodeScanner",
  initialState: { scanningMode: "manual" } as BarcodeScannerState,
  reducers: {
    scanningModeChanged(state, action: PayloadAction<ScanningMode>) {
      state.scanningMode = action.payload;
    },
  },
});

export const {
  reducer: barcodeScannerReducer,
  actions: { scanningModeChanged },
} = slice;
