import { useTranslation } from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { PageSubheadline } from "@ahlsell-group/app-ui/PageSubheadline";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Fab } from "@ahlsell-group/ui-kit/inputs";
import { Center } from "@ahlsell-group/ui-kit/layout";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import isTruthy from "../../../util/isTruthy";
import { AppPage } from "../../page/components/AppPage";
import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import {
  selectInventoryLocationItems,
  selectInventoryLocationSubmittedItems,
  selectLocationId,
  selectPickStation,
} from "../inventoryLocationSelectors";
import { setNotification } from "../inventoryLocationSlice";
import { isFailedItemStatus, isSuccessItemStatus } from "../util";

import InventoryLocationFailedListItem from "./InventoryLocationFailedListItem";
import InventoryLocationListItem from "./InventoryLocationListItem";
import InventoryLocationSomeItemsNotChangedModal from "./InventoryLocationSomeItemsNotChangedModal";

type InventoryLocationSubmittedParams = {
  failed?: boolean;
  modal?: "confirmation";
};

const InventoryLocationSubmitted: React.FC<
  ViewComponentProps<InventoryLocationSubmittedParams>
> = function ({ params }) {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const showSuccessful = !params.failed;
  const { modal } = params;
  const pickStation = useSelector(selectPickStation);
  const locationId = useSelector(selectLocationId);
  const items = useSelector(selectInventoryLocationItems);
  const submittedItems = useSelector(selectInventoryLocationSubmittedItems);

  const statusByItemId = new Map(
    submittedItems?.map((x) => [x.itemId, x.status])
  );

  const itemsToShow = items.filter((x) => {
    const status = statusByItemId.get(x.itemId);

    return showSuccessful
      ? isSuccessItemStatus(status)
      : isFailedItemStatus(status);
  });

  const showAlert = showSuccessful && !modal && itemsToShow.length !== 0;

  useEffect(() => {
    if (showAlert) {
      dispatch(
        setNotification({
          severity: "success",
          type: "submitted",
          pickStation,
          locationId,
        })
      );
    }
  }, [dispatch, showAlert, pickStation, locationId]);

  if (!submittedItems) {
    // Should not be able to happen. Maybe the user navigated forward to this route?
    navigate(routes.inventoryLocation, {}, { replace: true });
    return null;
  }

  const header = [
    t("inventoryLocation.inventoryLocation_one"),
    pickStation,
    locationId,
  ]
    .filter(isTruthy)
    .join(" ");

  return (
    <AppPage
      data-cy="InventoryLocationSubmitted"
      headline={
        <PageSubheadline
          onBack={() => navigate.back()}
          back-data-cy="InventoryLocationSubmitted-back"
        >
          {header}
        </PageSubheadline>
      }
    >
      {modal === "confirmation" && (
        <InventoryLocationSomeItemsNotChangedModal />
      )}
      {showSuccessful && itemsToShow.length === 0 ? (
        <PageSection>
          <Center
            className="mx-14 text-center"
            data-cy="InventoryLocationSubmitted-empty"
          >
            <Typography variant="body-sm">
              {t("inventoryLocation.noItemsRelocated")}
            </Typography>
          </Center>
        </PageSection>
      ) : (
        <PageSection className="grow">
          <Typography
            data-cy="InventoryLocationSubmitted-label"
            variant="form-label"
            color="gray"
            className="py-4"
            component="p"
          >
            {showSuccessful
              ? t("inventoryLocation.newlyAddedItems")
              : t("inventoryLocation.canNotRelocateItems", {
                  count: itemsToShow.length,
                })}
          </Typography>
          {itemsToShow.map((item) =>
            showSuccessful ? (
              <InventoryLocationListItem
                disabled
                key={item.itemId}
                item={item}
              />
            ) : (
              <InventoryLocationFailedListItem item={item} key={item.itemId} />
            )
          )}
        </PageSection>
      )}
      {!showSuccessful && (
        <PageSection>
          <Fab
            variant="primary"
            size="large"
            className="grow"
            data-cy="InventoryLocationSubmitted-continue"
            onClick={() => navigate.back()}
          >
            {t("continue")}
          </Fab>
        </PageSection>
      )}
    </AppPage>
  );
};

export default InventoryLocationSubmitted;
