import { InventoryLocationItem } from "@ahlsell-group/store20-inventory-location-service";
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import {
  itemLocationLoaded,
  itemLocationLoadFailed,
  ItemLocationRequiredPayload,
} from "../inventoryLocationSlice";

export default function* loadItemLocationSaga(
  { inventoryLocationService, handleSagaError }: ServiceContainer,
  {
    payload: { itemId, warehouseId },
  }: PayloadAction<ItemLocationRequiredPayload>
) {
  try {
    const result: InventoryLocationItem = yield call(
      [inventoryLocationService, "getInventoryLocation"],
      itemId,
      warehouseId
    );
    yield put(
      itemLocationLoaded({
        ...result,
        warehouseId,
        loadedAt: new Date().toISOString(),
      })
    );
  } catch (e) {
    yield call(
      handleSagaError,
      (errorCode) =>
        `saga:inventory-location:loadItemLocationsSaga:${errorCode}`,
      e,
      put(
        itemLocationLoadFailed(toErrorActionPayload(e, { itemId, warehouseId }))
      )
    );
  }
}
