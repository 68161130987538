import {
  GetStockErrorReason,
  ItemStock,
} from "@ahlsell-group/store20-inventory-service";
import { GenericOperationError } from "@ahlsell-group/store20-service-core";
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

export interface ItemStockInfo {
  stockBalance?: number;
  unit?: string;
  notAvailableState?: ItemStock["notAvailableState"];
  updatedAt?: string;
  state: "loaded" | "expired" | "loading" | "error";
  errorReason?: GetStockErrorReason | GenericOperationError;
}

// TypeScript can sometimes cause errors if this is declared as an interface.
export type ItemStockState = {
  [warehouseId in number]: {
    [itemId in string]: ItemStockInfo;
  };
};

export interface StockBalanceRequiredPayload {
  warehouseId: number;
  itemId: string;
}

export interface StockBalanceReceivedPayload {
  warehouseId: number;
  itemId: string;
  stockBalance?: number;
  unit?: string;
  notAvailableState?: ItemStock["notAvailableState"];
  updatedAt: string;
}

export type ItemStockBalanceExpiredPayload = {
  warehouseId: number;
  itemId: string;
};

export type StockBalanceLoadFailedPayload = {
  warehouseId: number;
  itemId: string;
  reason: GetStockErrorReason | GenericOperationError;
};

const ensureItemInWarehouse = (
  warehouseId: number,
  itemId: string,
  state: Draft<ItemStockState>
) => {
  state[warehouseId] = state[warehouseId] ?? {};
  state[warehouseId][itemId] = state[warehouseId][itemId] ?? {};
};

const itemStockSlice = createSlice({
  name: "itemStock",
  initialState: {} as ItemStockState,
  reducers: {
    stockBalanceRequired(
      state,
      { payload }: PayloadAction<StockBalanceRequiredPayload>
    ) {
      ensureItemInWarehouse(payload.warehouseId, payload.itemId, state);
      state[payload.warehouseId][payload.itemId].state = "loading";
    },

    stockBalanceReceived(
      state,
      { payload }: PayloadAction<StockBalanceReceivedPayload>
    ) {
      ensureItemInWarehouse(payload.warehouseId, payload.itemId, state);
      state[payload.warehouseId][payload.itemId] = {
        state: "loaded",
        stockBalance: payload.stockBalance,
        unit: payload.unit,
        notAvailableState: payload.notAvailableState,
        updatedAt: payload.updatedAt,
      };
    },

    itemStockBalancesExpired(
      state,
      { payload }: PayloadAction<ItemStockBalanceExpiredPayload[]>
    ) {
      for (let i = 0; i < payload.length; i += 1) {
        const itemUpdate = payload[i];
        const item = state[itemUpdate.warehouseId]?.[itemUpdate.itemId];
        if (item) {
          if (item.state === "expired") {
            // If the item is already expired, and has not been required again, it can be removed from the state
            // That way, the state will be cleaned from items no longer needed
            delete state[itemUpdate.warehouseId][itemUpdate.itemId];
          } else {
            state[itemUpdate.warehouseId][itemUpdate.itemId].state = "expired";
          }
        }
      }
    },

    stockBalanceLoadFailed(
      state,
      { payload }: PayloadAction<StockBalanceLoadFailedPayload>
    ) {
      if (state[payload.warehouseId]?.[payload.itemId]) {
        const errorItem = state[payload.warehouseId][payload.itemId];
        errorItem.state = "error";
        errorItem.errorReason = payload.reason;
      }
    },
  },
});

export const {
  reducer: itemStockReducer,
  actions: {
    stockBalanceRequired,
    stockBalanceReceived,
    itemStockBalancesExpired,
    stockBalanceLoadFailed,
  },
} = itemStockSlice;
