import { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { useDebugObject } from "../debug";

import { triggerSagaError } from "./errorSlice";

const useTriggerReactError = () => {
  const shouldThrowInRenderRef = useRef(false);
  const [, forceRerender] = useState(0);

  if (shouldThrowInRenderRef.current) {
    // Starting in React 18, if React is forced to rerender the component, it
    // seems like errors are only caught during the *last* render. In order to
    // test render errors, we must set `shouldThrowInRenderRef` to `false` only
    // when the whole sync React render cycle is complete, i.e. asynchronously.
    Promise.resolve().then(() => {
      shouldThrowInRenderRef.current = false;
    });

    throw new Error("Threw in React render");
  }

  window.debug.triggerReactError = () => {
    shouldThrowInRenderRef.current = true;
    forceRerender((x) => x + 1);
  };
};

const useTriggerGlobalError = () => {
  const debug = useDebugObject();
  debug.current.triggerGlobalError = () =>
    setTimeout(() => {
      throw new Error("Threw after timeout");
    });
};

const useTriggerSagaError = () => {
  const debug = useDebugObject();
  const dispatch = useDispatch();
  debug.current.triggerSagaError = () => {
    dispatch(triggerSagaError());
  };
};

/**
 * Only used to experiment with triggering errors thrown in render or event.
 */
const useTriggerError = () => {
  useTriggerReactError();
  useTriggerGlobalError();
  useTriggerSagaError();
};

export default useTriggerError;
