import { useTranslation } from "@ahlsell-group/app-localization";
import { ExclamationIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import React from "react";

import { StepComponent } from "../types";

import GettingStartedPage from "./GettingStartedPage";

const LoginConfirmation: StepComponent = function LoginConfirmation() {
  const { t } = useTranslation("common");

  const onRetry = (action: string) => {
    if (import.meta.IS_DEV_MODE && action === "disableAuth") {
      sessionStorage.setItem("DISABLE_AUTH", "1");
    }

    // For some reason, login sometimes fails and this session storage key is
    // set to the string "undefined". This prevents login from working, even
    // upon reload.
    // The issue seems to be most frequent when directly navigating to a deep
    // URL.
    sessionStorage.removeItem("msal.interaction.status");

    window.location.reload();
  };

  return (
    <GettingStartedPage
      data-cy="LoginConfirmation"
      backButton={false}
      actions={{
        retry: {
          text: t("gettingStarted.loginConfirmation.tryAgainButton"),
          primary: true,
          "data-cy": "LoginConfirmation-retry",
        },
        ...(import.meta.IS_DEV_MODE
          ? {
              disableAuth: {
                text: "Disable auth (local only)",
                "data-cy": "",
              },
            }
          : undefined),
      }}
      onAction={onRetry}
    >
      <div className="flex flex-1 flex-col justify-center mt-16">
        <div className="text-theme-status-error flex justify-center">
          <ExclamationIcon width="6.625rem" height="6.625rem" />
        </div>
        <div className="pt-8 text-center">
          <Typography>
            {t("gettingStarted.loginConfirmation.loginUnknownErrorText")}
          </Typography>
        </div>
      </div>
    </GettingStartedPage>
  );
};

export default LoginConfirmation;
