import {
  ErrorKeysByCategory,
  Trans,
  useErrorMessage,
  useTranslation,
} from "@ahlsell-group/app-localization";
import {
  Item,
  ItemValidationErrorType,
} from "@ahlsell-group/store20-stock-taking-service";
import { CommentIcon, TrashIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Chip } from "@ahlsell-group/ui-kit/feedback";
import classNames from "classnames";
import React from "react";

import ItemImage from "../../item-image/components/ItemImage";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";

const getErrorKey = (
  error?: ItemValidationErrorType | "ExpectedQuantityDiffers"
): ErrorKeysByCategory<"stockTakingItem"> =>
  error === "PreCheckFailed" ? "unknown" : error ?? "unknown";

export interface ManualStockTakingListItemProps {
  item: Item;
  onSelect(): void;
  error?: ItemValidationErrorType | "ExpectedQuantityDiffers";
}

const ManualStockTakingListItem: React.FC<ManualStockTakingListItemProps> =
  function ({ item, onSelect, error }) {
    const { t, i18n } = useTranslation("common");
    const navigate = useNavigate();
    const errorKey = getErrorKey(error);
    const errorMessage = useErrorMessage("stockTakingItem", errorKey);

    const isExpectedQuantity =
      item.stockTakingQuantity === item.expectedQuantityWhenCounted;

    const handleRemove = () => {
      const { itemId } = item;
      navigate(routes.stockTaking.manual.remove, {
        itemId,
      });
    };

    return (
      <div className="relative">
        <button
          type="button"
          className={classNames(
            "relative flex flex-row items-stretch rounded w-full text-left pl-2 pr-4 py-4 mb-2 bg-white",
            {
              "border-l-8 border-l-theme-status-text-error": error,
              "border-l-8 border-l-transparent": !error,
            }
          )}
          onClick={() => onSelect()}
          data-cy={`ManualStockTakingListItem-button-${item.itemId}`}
        >
          <ItemImage
            assetUrl={item.imagePath ?? ""}
            alt={item.description2 ?? ""}
            size="small"
          />
          <Typography
            variant="body-sm"
            className="ml-4 flex-1 flex flex-col justify-between"
          >
            <div
              className={classNames("text-gray", {
                "pr-3": error,
              })}
            >
              {item.description1}
            </div>
            <div className="flex items-center">
              <div className="grow">
                {t("stockTaking.articleNumberShort")} {item.itemId}
              </div>
              {item.comment && !error && (
                <span
                  className="inline-block"
                  title={item.comment}
                  data-cy="ManualStockTakingListItem-comment"
                >
                  <CommentIcon size="small" className="ml-2" />
                </span>
              )}
              {!error && (
                <Chip
                  color={isExpectedQuantity ? "default" : "attention"}
                  className="ml-2"
                  data-cy="ManualStockTakingListItem-quantity"
                >
                  {Intl.NumberFormat(i18n.language).format(
                    item.stockTakingQuantity ?? 0
                  )}
                  &nbsp;{item.unit}
                </Chip>
              )}
            </div>
            {error && (
              <div className="flex items-center">
                <Typography variant="body-xs" color="error" className="pt-2">
                  <Trans t={t} i18nKey={errorMessage?.detailKey} />
                </Typography>
                {item.comment && (
                  <span className="inline-block" title={item.comment}>
                    <CommentIcon size="small" className="ml-2 -mr-2" />
                  </span>
                )}
              </div>
            )}
          </Typography>
        </button>
        {error && (
          <button
            className="absolute top-2 right-2"
            type="button"
            data-cy={`ManualStockTakingListItem-remove-${item.itemId}`}
            onClick={handleRemove}
          >
            <TrashIcon size="small" />
          </button>
        )}
      </div>
    );
  };

export default ManualStockTakingListItem;
