import { useTranslation } from "@ahlsell-group/app-localization";
import { LoadingPage } from "@ahlsell-group/app-ui/LoadingPage";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { PageSubheadline } from "@ahlsell-group/app-ui/PageSubheadline";
import { Select } from "@ahlsell-group/app-ui/Select";
import { ScannerBarcodeIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Fab, Input } from "@ahlsell-group/ui-kit/inputs";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ErrorModal from "../../error/components/ErrorModal";
import { AppLoadingPage } from "../../page/components/AppLoadingPage";
import { AppPage } from "../../page/components/AppPage";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import {
  selectInventoryLocationItems,
  selectInventoryLocationSubmitState,
  selectIsPickStationsWithLocationsLoading,
  selectLoadLocationsError,
  selectLocationId,
  selectPickStation,
  selectPickStationsWithLocations,
} from "../inventoryLocationSelectors";
import {
  locationIdSelected,
  locationsRequired,
  pickStationSelected,
} from "../inventoryLocationSlice";
import { toLowerIncludes } from "../util";

import InventoryLocationItemCard from "./InventoryLocationItemCard";

const InventoryLocationOverview: React.FC = function () {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pickStationsWithLocations = useSelector(
    selectPickStationsWithLocations
  );
  const isLocationsLoading = useSelector(
    selectIsPickStationsWithLocationsLoading
  );
  const loadLocationsError = useSelector(selectLoadLocationsError);
  const pickStation = useSelector(selectPickStation);
  const stateLocationId = useSelector(selectLocationId);
  const items = useSelector(selectInventoryLocationItems);
  const submitState = useSelector(selectInventoryLocationSubmitState);
  const cannotSubmit = !pickStation || !stateLocationId || items.length === 0;

  const [locationFilterQuery, setLocationFilterQuery] = useState<string>("");

  useEffect(() => {
    dispatch(locationsRequired());
  }, [dispatch]);

  let itemCards: React.ReactNode;

  if (items.length > 3) {
    itemCards = (
      <>
        {items.slice(0, 2).map((item) => (
          <InventoryLocationItemCard
            key={item.itemId}
            item={item}
            onClick={() =>
              navigate(routes.inventoryLocation.change.items.item, {
                itemId: item.itemId,
              })
            }
          />
        ))}
        <InventoryLocationItemCard
          item={items[2]}
          onClick={() => navigate(routes.inventoryLocation.change.items)}
          overlay={{ show: true, value: items.length - 3 }}
        />
      </>
    );
  } else if (items.length > 0) {
    itemCards = items.map((item) => (
      <InventoryLocationItemCard
        key={item.itemId}
        item={item}
        onClick={() =>
          navigate(routes.inventoryLocation.change.items.item, {
            itemId: item.itemId,
          })
        }
      />
    ));
  } else {
    itemCards = null;
  }

  let modal: JSX.Element | undefined;

  if (loadLocationsError) {
    modal = (
      <ErrorModal
        error={loadLocationsError}
        data-cy="InventoryLocationOverview-loadLocationsError"
        category="inventoryLocation"
        onClose={() => navigate.back()}
      />
    );
  }

  if (isLocationsLoading) {
    return (
      <AppLoadingPage onCancel={() => navigate.back()}>
        {t("inventoryLocation.loadingLocations")}
      </AppLoadingPage>
    );
  }

  if (submitState.type === "error") {
    modal = (
      <ErrorModal
        error={submitState.error}
        data-cy-error-reason={submitState.error.reason}
        data-cy="inventory-location-error"
        category="inventoryLocationSubmission"
        onClose={() => navigate.back()}
      />
    );
  }

  if (submitState.type === "submitting") {
    // Don't allow canceling.
    return <LoadingPage>{t("inventoryLocation.submittingItems")}</LoadingPage>;
  }

  const pickStationWithLocations = pickStationsWithLocations?.find(
    (x) => x.pickStationId === pickStation
  );

  const allLocationIds = pickStationWithLocations?.locationIds;

  const filteredLocationIds = locationFilterQuery
    ? allLocationIds?.filter((locationId) =>
        toLowerIncludes(locationId, locationFilterQuery)
      )
    : allLocationIds;

  const maxVisibleLocationsCount = 400;
  const visibleLocationIds =
    filteredLocationIds && filteredLocationIds.length > maxVisibleLocationsCount
      ? filteredLocationIds.slice(0, maxVisibleLocationsCount)
      : filteredLocationIds;

  const afterOptions: React.ReactNode = visibleLocationIds !==
    filteredLocationIds && (
    <Typography
      variant="body-sm"
      color="gray"
      className="p-4"
      data-cy="AfterOptions"
    >
      {t("inventoryLocation.maxLocationsShown", {
        count: maxVisibleLocationsCount,
      })}
    </Typography>
  );

  return (
    <AppPage
      data-cy="InventoryLocationOverview"
      headline={
        <PageSubheadline
          onBack={() => navigate.back()}
          back-data-cy="InventoryLocationOverview-back"
        >
          {t("inventoryLocation.inventoryLocation_one")}
        </PageSubheadline>
      }
    >
      {items.length > 0 ? (
        <PageSection>
          <Typography variant="body-sm" className="mb-2">
            {t("inventoryLocation.scannedItems")}
          </Typography>
          <div className="flex flex-row overflow-auto gap-2">{itemCards}</div>
        </PageSection>
      ) : null}
      <PageSection className="overflow-auto grow">
        <div className="mb-6 mt-1.5">
          <Typography variant="form-label" className="pb-1">
            {t("inventoryLocation.pickStation")}
          </Typography>
          <Select
            data-cy="pickStation-select"
            label={pickStation || t("inventoryLocation.selectPickStation")}
            header={t("inventoryLocation.pickStation")}
            onChange={(x) => dispatch(pickStationSelected(x))}
            value={pickStation}
            options={pickStationsWithLocations?.map((x) => ({
              value: x.pickStationId,
              label: x.pickStationId,
            }))}
          />
        </div>
        <div>
          <Typography variant="form-label" className="pb-1">
            {t("inventoryLocation.inventoryLocation_one")}
          </Typography>
          <Select
            data-cy="locationId-select"
            disabled={!pickStation}
            label={stateLocationId || t("inventoryLocation.selectLocationId")}
            header={t("inventoryLocation.inventoryLocation_one")}
            onChange={(x) => dispatch(locationIdSelected(x))}
            value={stateLocationId}
            options={visibleLocationIds?.map((location) => ({
              value: location,
              label: location,
            }))}
            filterInput={
              allLocationIds &&
              allLocationIds.length > 20 && (
                <Input
                  data-cy="locationId-input"
                  placeholder={t("search")}
                  value={locationFilterQuery}
                  onChange={(event) =>
                    setLocationFilterQuery(event.target.value)
                  }
                  adornmentSuffix={
                    locationFilterQuery
                      ? `${filteredLocationIds?.length} / ${allLocationIds.length}`
                      : t("inventoryLocation.locations", {
                          count: allLocationIds.length,
                        })
                  }
                  showClearButton={!!locationFilterQuery}
                  onClear={() => setLocationFilterQuery("")}
                />
              )
            }
            afterOptions={afterOptions}
            selectedOptionSubHeader={
              locationFilterQuery
                ? undefined
                : {
                    title: t("inventoryLocation.selectedInventoryLocation"),
                    subHeader: t("inventoryLocation.inventoryLocation", {
                      count: filteredLocationIds?.length,
                    }),
                  }
            }
          />
        </div>
      </PageSection>
      <PageSection>
        <div className="flex gap-2">
          {items.length !== 0 && (
            <Fab
              disabled={cannotSubmit}
              data-cy="InventoryLocationOverview-save"
              variant="primary"
              size="large"
              className="grow"
              onClick={() =>
                navigate(routes.inventoryLocation.change, {
                  modal: "confirmSubmission",
                })
              }
            >
              {t("save")}
            </Fab>
          )}
          <Fab
            disabled={!pickStation || !stateLocationId}
            data-cy="InventoryLocationOverview-continue"
            variant="tertiary"
            size="large"
            icon={ScannerBarcodeIcon}
            className="grow"
            onClick={() => navigate(routes.inventoryLocation.change.scan)}
          >
            {items.length > 0
              ? t("inventoryLocation.keepScanning")
              : t("inventoryLocation.scanItem")}
          </Fab>
        </div>
      </PageSection>
      {modal}
    </AppPage>
  );
};

export default InventoryLocationOverview;
