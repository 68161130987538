import { LogService } from "@ahlsell-group/store20-log-service";
import { Middleware } from "redux";

import { selectStateForErrorLogs } from "./errorSelectors";
import logUnhandledError from "./logUnhandledError";

function catchErrorMiddleware(logService: LogService): Middleware {
  return (store) => (next) => (action) => {
    try {
      return next(action);
    } catch (error) {
      logUnhandledError(logService, "unhandled:redux", error, {
        action,
        state: selectStateForErrorLogs(store.getState()),
      });
      return error;
    }
  };
}

export default catchErrorMiddleware;
