import { useTranslation } from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { ArrowLeftIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Fab, IconButton } from "@ahlsell-group/ui-kit/inputs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useItemImageUrl from "../../item-image/useItemImageUrl";
import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import { selectInventoryLocationItems } from "../inventoryLocationSelectors";
import { removeItem } from "../inventoryLocationSlice";

import ItemLocation from "./ItemLocation";

const InventoryLocationItem: React.FC<ViewComponentProps> = function ({
  params,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const assetUrl = useItemImageUrl();
  const dispatch = useDispatch();
  const items = useSelector(selectInventoryLocationItems);
  const warehouseId = useSelector(selectWarehouseId);

  const itemId = params?.itemId;

  if (typeof itemId !== "string") {
    navigate.back();
    throw new Error("Missing params.itemId");
  }

  const item = items.find((x) => x.itemId === itemId);

  // The correct item should always be available. If it isn't, the user has
  // probably navigated to a page where there is a deleted item.
  useEffect(() => {
    if (!item) {
      navigate(routes.inventoryLocation.change, {}, { replace: true });
    }
  }, [item, navigate]);

  if (!item) {
    return null;
  }

  const handleRemove = async (id: string) => {
    await navigate.back();
    dispatch(removeItem({ itemId: id }));
  };

  return (
    <div
      className="bg-white h-full flex flex-col"
      data-cy="InventoryLocationItem"
    >
      <div className="fixed top-0 z-10 p-4">
        <IconButton
          variant="tertiary"
          rounded="full"
          size="large"
          icon={ArrowLeftIcon}
          data-cy="InventoryLocationItem-back"
          onClick={() => {
            navigate.back();
          }}
        />
      </div>
      <PageSection>
        <div className="mx-auto py-9 max-w-[11.25rem] max-h-[11.25rem]">
          <img
            className="object-contain w-full h-full"
            src={assetUrl(item.assetUrl)}
            alt=""
          />
        </div>

        <Typography
          data-cy="InventoryLocationItem-itemId"
          className="mb-3"
          variant="heading-h2"
        >
          {t("inventoryLocation.itemIdShort")} {item.itemId}
        </Typography>
        <Typography variant="heading-h3" color="gray" className="mb-1">
          {item.name}
        </Typography>
        <Typography variant="body" color="gray" className="mb-6">
          {item.description1} {item.description2}
        </Typography>
        <Typography variant="body-sm" color="gray">
          <ItemLocation
            warehouseId={warehouseId}
            itemId={item.itemId}
            includeCurrentPrefix
          />
        </Typography>
      </PageSection>
      <div className="absolute left-0 bottom-0 px-4 pb-4 w-full flex">
        <Fab
          data-cy="InventoryLocationItem-remove"
          variant="primary"
          size="large"
          className="grow"
          onClick={() => handleRemove(item.itemId)}
        >
          {t("remove")}
        </Fab>
      </div>
    </div>
  );
};

export default InventoryLocationItem;
