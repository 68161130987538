import { AppConfig } from "@ahlsell-group/store20-config-service";
import { call } from "redux-saga/effects";

import { stepCompleted } from "../gettingStartedSlice";
import { Step } from "../types";

const languageSelectionStep: Step<"languageSelection"> = {
  type: "languageSelection",
  *preStepSaga({ appConfigService }) {
    const appConfig: AppConfig = yield call([appConfigService, "getAppConfig"]);
    if (appConfig.selectedLanguage) {
      return stepCompleted({
        type: "languageSelection",
        selectedLanguage: appConfig.selectedLanguage,
      });
    }
    return "input-required";
  },
  *postStepSaga({ localizationService }, action) {
    if (action.payload.type !== "languageSelection") return;
    yield call(
      [localizationService, "changeLanguage"],
      action.payload.selectedLanguage
    );
  },
  *saveResultSaga({ appConfigService }, { languageSelection }) {
    if (languageSelection) {
      yield call(
        [appConfigService, "setConfig"],
        "selectedLanguage",
        languageSelection.selectedLanguage
      );
    }
  },
};

export default languageSelectionStep;
