import { useTranslation } from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { ExclamationIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import React from "react";

interface OtherWarehousesErrorProps {
  title: string;
  message: string;
  "data-cy"?: string;
}

const OtherWarehousesError: React.FC<OtherWarehousesErrorProps> = function ({
  title,
  message,
  ...rest
}) {
  const { t } = useTranslation("common");

  return (
    <PageSection {...rest}>
      <Typography variant="form-label" color="gray" className="px-4 pt-4">
        {title}
      </Typography>
      <div className="flex flex-1 flex-col justify-center">
        <div className="text-theme-status-error flex justify-center">
          <ExclamationIcon width="6.625rem" height="6.625rem" />
        </div>
        <div className="pt-8 text-center">
          <Typography>{message}</Typography>
          <Typography>{t("itemStock.tryAgainLater")}</Typography>
        </div>
      </div>
    </PageSection>
  );
};

export default OtherWarehousesError;
