import { FeatureFlag } from "@ahlsell-group/store20-config-service";
import { createSelector } from "@reduxjs/toolkit";

import { Selector, State } from "../../types";

export const selectFeatureFlags: Selector<FeatureFlag[]> = (state) =>
  state.config.featureFlags;

export const selectHasFeatureFlag: (
  state: State,
  flag: FeatureFlag
) => boolean = createSelector(
  selectFeatureFlags,
  (state: State, flag: FeatureFlag) => flag,
  (flags, flag) => flags.includes(flag)
);
