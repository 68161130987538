import {
  GetItemErrorReason,
  Item,
} from "@ahlsell-group/store20-product-service";
import { OperationFailedError } from "@ahlsell-group/store20-service-core";
import { call, put } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import ActionType from "../../../util/ActionType";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import {
  errorOccurred,
  itemReceived,
  itemRequired,
} from "../itemInformationSlice";

import getItemIdByBarcode from "./getItemIdByBarcode";

export default function* loadItemSaga(
  { itemService, itemSearchService, handleSagaError }: ServiceContainer,
  action: ActionType<typeof itemRequired>
): Saga {
  try {
    let itemId: string | undefined;
    if ("barcode" in action.payload) {
      itemId = yield getItemIdByBarcode(
        action.payload.barcode,
        itemSearchService
      );
      if (!itemId) {
        throw new OperationFailedError<GetItemErrorReason>("not-found");
      }
    } else {
      itemId = action.payload.itemId;
    }

    const item: Item = yield call([itemService, "getItem"], itemId);
    yield put(itemReceived(item));
  } catch (e) {
    yield call(
      handleSagaError,
      (errorCode) => `saga:item-information:loadItemSaga:${errorCode}`,
      e,
      put(errorOccurred(toErrorActionPayload(e)))
    );
  }
}
