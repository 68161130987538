import { useTranslation } from "@ahlsell-group/app-localization";
import { InventoryLocationItemInfo } from "@ahlsell-group/store20-inventory-location-service";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import React from "react";

import ItemImage from "../../item-image/components/ItemImage";
import RouteLink from "../../routing/components/RouteLink";
import routes from "../../routing/routes";

interface InventoryLocationFailedListItemProps {
  item: InventoryLocationItemInfo;
}

const InventoryLocationFailedListItem: React.FC<InventoryLocationFailedListItemProps> =
  function ({ item }) {
    const { t } = useTranslation("common");

    return (
      <div
        className="relative flex flex-row items-stretch rounded text-left p-4 mb-2 bg-white"
        data-cy={`InventoryLocationFailedListItem-${item.itemId}`}
      >
        <ItemImage assetUrl={item.assetUrl} alt="" size="small" />
        <div className="ml-4 flex-1 flex flex-col">
          <Typography variant="body-sm">
            <div className="text-gray">{item.description1}</div>
            <div className="flex items-center mt-2">
              <div className="grow">
                {t("inventoryLocation.itemIdShort")} {item.itemId}
              </div>
            </div>
          </Typography>
          <div className="flex mt-2">
            <Typography color="gray-light" className="grow" variant="body-xs">
              {t("inventoryLocation.canNotRelocate")}
            </Typography>
            <RouteLink
              className="flex justify-end"
              data-cy={`InventoryLocationFailedListItem-button-${item.itemId}`}
              variant="inline"
              route={routes.inventoryLocation.change.submitted.item}
              routeParams={{ itemId: item.itemId }}
            >
              <Typography variant="body-xs" className="font-bold">
                {t("inventoryLocation.why")}
              </Typography>
            </RouteLink>
          </div>
        </div>
      </div>
    );
  };

export default InventoryLocationFailedListItem;
