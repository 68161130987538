import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import useSplashScreen from "../../../app/hooks/useSplashScreen";
import useRouter from "../../routing/useRouter";
import { selectHasPreviousStepWithInputRequired } from "../gettingStartedSelectors";
import { StepType } from "../gettingStartedSlice";

import InstallationPrompt from "./InstallationPrompt";
import LanguageSelection from "./LanguageSelection";
import LoginConfirmation from "./LoginConfirmation";
import LoginInstructions from "./LoginInstructions";
import PrivacyPolicy from "./PrivacyPolicy";
import Welcome from "./Welcome";

const GettingStartedRoot: React.FC = function GettingStartedRoot() {
  const router = useRouter();
  const currentState = router.getState();
  const { step } = currentState.params as { step?: StepType };
  const hasPreviousStep = useSelector(
    selectHasPreviousStepWithInputRequired(step)
  );

  const { hideSplashScreen } = useSplashScreen();
  useEffect(() => {
    if (step) {
      hideSplashScreen();
    }
  }, [hideSplashScreen, step]);

  if (!step) return null;

  return (
    <>
      {step === "welcome" && <Welcome hasPreviousStep={hasPreviousStep} />}
      {step === "languageSelection" && (
        <LanguageSelection hasPreviousStep={hasPreviousStep} />
      )}
      {step === "privacyPolicy" && (
        <PrivacyPolicy hasPreviousStep={hasPreviousStep} />
      )}
      {step === "installationPrompt" && (
        <InstallationPrompt hasPreviousStep={hasPreviousStep} />
      )}
      {step === "loginInstructions" && (
        <LoginInstructions hasPreviousStep={hasPreviousStep} />
      )}
      {step === "loginConfirmation" && (
        <LoginConfirmation hasPreviousStep={hasPreviousStep} />
      )}
    </>
  );
};

export default GettingStartedRoot;
