import { SavedInventoryLocationChange } from "@ahlsell-group/store20-inventory-location-service";
import { call, put, select } from "redux-saga/effects";

import { selectVivaldiEmployeeNumber } from "../../../app/appSelectors";
import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import { restoreInventoryLocationChange } from "../inventoryLocationSlice";

export default function* initializeInventoryLocationChangeSaga({
  inventoryLocationService,
}: ServiceContainer): Saga {
  const warehouseId = yield select(selectWarehouseId);
  const vivaldiEmployeeNumber = yield select(selectVivaldiEmployeeNumber);
  const stored: SavedInventoryLocationChange = yield call(
    [inventoryLocationService, "getSavedInventoryLocationChange"],
    warehouseId,
    vivaldiEmployeeNumber
  );

  if (stored.items?.length === 0 && stored.pickStationId === undefined) return;

  yield put(restoreInventoryLocationChange(stored));
}
