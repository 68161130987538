import { CentralWarehouse } from "./types";

const centralWarehouses: CentralWarehouse[] = [
  { warehouseId: 2, name: "Hallsberg" },
  { warehouseId: 8, name: "Gardermoen" },
  { warehouseId: 89, name: "Gjøvik" },
  { warehouseId: 820, name: "Sønderborg" },
  { warehouseId: 910, name: "Hyvinkää" },
];

const [hallsberg, gardermoen, gjovik, sonderborg, hyvinkaa] = centralWarehouses;

const centralWarehousesForLegalEntity: Record<number, CentralWarehouse[]> = {
  10: [hallsberg],
  15: [hallsberg],
  31: [hallsberg, hyvinkaa],
  32: [sonderborg],
  33: [gardermoen, gjovik, hallsberg],
  34: [hyvinkaa, hallsberg],
};

export const centralWarehouseIds = centralWarehouses.map((x) => x.warehouseId);

export const getCentralWarehousesForLegalEntity = (legalEntityId: number) =>
  centralWarehousesForLegalEntity[legalEntityId] ??
  centralWarehousesForLegalEntity[10];
