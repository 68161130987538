import {
  SearchStoresErrorReason,
  Store,
} from "@ahlsell-group/store20-store-service";
import { createReducer, createSlice } from "@reduxjs/toolkit";

import navigatedFromSubTree from "../routing/navigatedFromSubTree";

type NearbyStoresErrorReason =
  | SearchStoresErrorReason
  | "no-coordinates-for-user-warehouse";

export type NearbyStoreNoDataState = {
  state: "idle" | "loading";
};

export type NearbyStoresLoadedState = {
  state: "loaded";
  stores: Store[];
};

export type NearbyStoresErrorState = {
  state: "error";
  error: SearchStoresError;
};

export type NearbyStoresState =
  | NearbyStoreNoDataState
  | NearbyStoresLoadedState
  | NearbyStoresErrorState;

export type SearchStoresError = { reason: NearbyStoresErrorReason };

const initialState = {
  state: "idle",
} satisfies NearbyStoresState as NearbyStoresState;

const name = "nearbyStores";

const subSlices = {
  idle: createSlice({
    name,
    initialState,
    reducers: {
      storesRequired(state) {
        state.state = "loading";
      },
    },
  }),
  loading: createSlice({
    name,
    initialState,
    reducers: {
      nearbyStoresReceived(state, { payload }) {
        state.state = "loaded";
        const newState = state as NearbyStoresLoadedState;
        newState.stores = payload;
      },
      loadNearbyStoresError(state, { payload: error }) {
        state.state = "error";
        const newState = state as NearbyStoresErrorState;
        newState.error = error;
      },
    },
  }),
  error: undefined,
  loaded: undefined,
};

export const nearbyStoresReducer = createReducer(initialState, (builder) =>
  builder
    .addMatcher(
      navigatedFromSubTree("itemInformation.otherWarehousesItemStock"),
      () => initialState
    )
    .addDefaultCase((state, action) =>
      subSlices[state.state]?.reducer(state, action)
    )
);

export const {
  idle: {
    actions: { storesRequired },
  },
  loading: {
    actions: { nearbyStoresReceived, loadNearbyStoresError },
  },
} = subSlices;
