import barcodeScannerRoutes from "../barcode-scanning/barcodeScannerRoutes";
import itemSearchRoutes from "../item-search/itemSearchRoutes";
import { createRoutes } from "../routing/utils";

const inventoryLocationRoutes = createRoutes({
  inventoryLocation: {
    path: "/inventory-location",
    children: {
      change: {
        path: "/change?modal", // modal=submitConfirmation
        children: {
          ...itemSearchRoutes,
          ...barcodeScannerRoutes,
          items: {
            path: "/items?modal", // modal=clearConfirmation
            children: {
              new: { path: "/new" },
              item: { path: "/:itemId" },
            },
          },
          submitted: {
            path: "/submitted?failed&modal",
            children: {
              item: { path: "/items/:itemId" },
            },
          },
        },
      },
    },
  },
} as const);

export default inventoryLocationRoutes;
