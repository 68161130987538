import * as SDCBarcode from "scandit-web-datacapture-barcode";
import * as SDCCore from "scandit-web-datacapture-core";

import PromiseSource from "../../util/PromiseSource";

export type CameraState = keyof typeof SDCCore.FrameSourceState;
export type BarcodeCaptureListener = SDCBarcode.BarcodeCaptureListener;

export interface ScanningContext {
  enableBarcodeCapture(): Promise<void>;
  disableBarcodeCapture(): Promise<void>;
  addBarcodeCaptureListener(listener: BarcodeCaptureListener): void;
  removeBarcodeCaptureListener(listener: BarcodeCaptureListener): void;
  switchCameraToDesiredState(state: CameraState): Promise<void>;
}

export interface ScanningContextInternal {
  initPromiseSource: PromiseSource;
  defaultElement: HTMLElement;
  view: SDCCore.DataCaptureView;
  context: SDCCore.DataCaptureContext;
  camera: SDCCore.Camera;
  cameraSettings: SDCCore.CameraSettings;
  settings: SDCBarcode.BarcodeCaptureSettings;
  barcodeCapture: SDCBarcode.BarcodeCapture;
  barcodeCaptureOverlay: SDCBarcode.BarcodeCaptureOverlay;
  viewfinder: SDCCore.RectangularViewfinder;
}

export class ScanningContextImpl implements ScanningContext {
  private internalContext: ScanningContextInternal;

  constructor(context: ScanningContextInternal) {
    this.internalContext = context;
  }

  async enableBarcodeCapture(): Promise<void> {
    await this.internalContext.barcodeCapture.setEnabled(true);
  }

  async disableBarcodeCapture(): Promise<void> {
    await this.internalContext.barcodeCapture.setEnabled(false);
  }

  addBarcodeCaptureListener(listener: BarcodeCaptureListener): void {
    this.internalContext.barcodeCapture.addListener(listener);
  }

  removeBarcodeCaptureListener(listener: BarcodeCaptureListener): void {
    this.internalContext.barcodeCapture.removeListener(listener);
  }

  async switchCameraToDesiredState(state: CameraState): Promise<void> {
    await this.internalContext.camera.switchToDesiredState(
      SDCCore.FrameSourceState[state]
    );
  }
}
