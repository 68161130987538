import { useTranslation } from "@ahlsell-group/app-localization";
import {
  Modal,
  ModalAction,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@ahlsell-group/ui-kit/feedback";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import useNavigate from "../../routing/useNavigate";
import {
  selectManualStockTakingStatus,
  selectItems,
} from "../manualStockTakingSelectors";
import { submitStockTakesRequested } from "../manualStockTakingSlice";

import ManualStockTakingLoadingModal from "./ManualStockTakingLoadingModal";
import ManualStockTakingSubmitEmptyModal from "./ManualStockTakingSubmitEmptyModal";

const ManualStockTakingSubmitModal: React.FC = function () {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector(selectItems);
  const status = useSelector(selectManualStockTakingStatus);

  if (status === "submittingStockTakes") {
    return (
      <ManualStockTakingLoadingModal
        headerText={t("stockTaking.submittingStockTakeHeader")}
        detailText={t("stockTaking.submittingStockTakeDetail")}
        onClose={() => navigate.back()}
      />
    );
  }

  if (items.length === 0) {
    return <ManualStockTakingSubmitEmptyModal />;
  }

  return (
    <Modal open variant="card" onCancel={() => navigate.back()}>
      <ModalHeader data-cy="ManualStockTakingSubmitModal">
        {t("stockTaking.submitStockTakeHeader")}
      </ModalHeader>
      <ModalContent>
        <p>
          {t("stockTaking.submitStockTakeDetails", { count: items.length })}
        </p>
      </ModalContent>
      <ModalActions>
        <ModalAction
          mode="button"
          variant="tertiary"
          onClick={() => navigate.back()}
          data-cy="ManualStockTakingSubmitModal-cancel"
        >
          {t("cancel")}
        </ModalAction>
        <ModalAction
          mode="button"
          variant="secondary"
          onClick={() =>
            dispatch(
              submitStockTakesRequested({ enableAutomaticCompletion: false })
            )
          }
          data-cy="ManualStockTakingSubmitModal-submit"
        >
          {t("yes")}
        </ModalAction>
      </ModalActions>
    </Modal>
  );
};

export default ManualStockTakingSubmitModal;
