import { createRoutes } from "../routing/utils";

const installModeRoutes = createRoutes({
  install: {
    path: "/install?ref",
    children: {
      ios: {
        path: "/ios",
      },
      android: {
        path: "/android",
      },
    },
  },
} as const);

export default installModeRoutes;
