import { AppConfig } from "@ahlsell-group/store20-config-service";
import { call } from "redux-saga/effects";

import { PRIVACY_POLICY_CURRENT_VERSION } from "../../privacy-policy/PrivacyPolicyList";
import { stepCompleted } from "../gettingStartedSlice";
import { Step } from "../types";

type AppConfigAccepted = AppConfig & {
  acceptedPrivacyPolicyVersion: string;
  acceptedPrivacyPolicyAt: string;
};

function hasAcceptedPrivacyPolicy(
  appConfig: AppConfig
): appConfig is AppConfigAccepted {
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  return (
    // Must have accepted the current version...
    appConfig.acceptedPrivacyPolicyVersion === PRIVACY_POLICY_CURRENT_VERSION &&
    // ...within the last 6 months.
    appConfig.acceptedPrivacyPolicyAt !== undefined &&
    sixMonthsAgo < new Date(appConfig.acceptedPrivacyPolicyAt)
  );
}

const privacyPolicyStep: Step<"privacyPolicy"> = {
  type: "privacyPolicy",
  *preStepSaga({ appConfigService }) {
    const appConfig: AppConfig = yield call([appConfigService, "getAppConfig"]);
    if (hasAcceptedPrivacyPolicy(appConfig)) {
      return stepCompleted({
        type: "privacyPolicy",
        acceptedAt: appConfig.acceptedPrivacyPolicyAt,
      });
    }
    return "input-required";
  },
  *saveResultSaga({ appConfigService }, { privacyPolicy }) {
    if (privacyPolicy) {
      yield call(
        [appConfigService, "setConfig"],
        "acceptedPrivacyPolicyAt",
        privacyPolicy.acceptedAt
      );
      yield call(
        [appConfigService, "setConfig"],
        "acceptedPrivacyPolicyVersion",
        PRIVACY_POLICY_CURRENT_VERSION
      );
    }
  },
};

export default privacyPolicyStep;
