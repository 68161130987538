import { put } from "redux-saga/effects";

import { Saga } from "../../../types";
import { iosUpdateIsRequired } from "../homeSlice";
import shouldShowUpdateAlert from "../shouldShowUpdateAlert";

export default function* updateIosAlertSaga(): Saga {
  const isUpdateRequired = shouldShowUpdateAlert(navigator.userAgent);

  if (isUpdateRequired) {
    yield put(iosUpdateIsRequired());
  }
}
