import { InventoryLocationItem } from "@ahlsell-group/store20-inventory-location-service";
import { Item } from "@ahlsell-group/store20-product-service";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import {
  InventoryLocationItemWithDetails,
  receivedInventoryLocationChangeErrors,
} from "../inventoryLocationSlice";

export default function* loadInventoryLocationChangeErrorsSaga({
  handleSagaError,
  inventoryLocationService,
  itemService,
}: ServiceContainer): Saga<boolean> {
  try {
    const stockId: number = yield select(selectWarehouseId);
    const inventoryLocationItems: InventoryLocationItem[] = yield call(
      [inventoryLocationService, "getItemLocationsWithErrors"],
      stockId
    );

    if (inventoryLocationItems.length) {
      const items: Item[] = yield call(
        [itemService, "getItems"],
        inventoryLocationItems.map((x) => x.itemId),
        false
      );

      const withDetails =
        inventoryLocationItems.map<InventoryLocationItemWithDetails>(
          (x, i) => ({ ...x, details: items[i] })
        );

      yield put(receivedInventoryLocationChangeErrors(withDetails));
    } else {
      yield put(receivedInventoryLocationChangeErrors([]));
    }

    return true;
  } catch (e) {
    yield call(
      handleSagaError,
      (errorCode) =>
        `saga:inventory-location:loadInventoryLocationChangeErrorsSaga:${errorCode}`,
      e
    );
    return false;
  }
}
