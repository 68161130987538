import { InventoryLocationItem } from "@ahlsell-group/store20-inventory-location-service";
import {
  GetItemErrorReason,
  PurchaseHoldInfo,
} from "@ahlsell-group/store20-product-service";
import { GenericOperationError } from "@ahlsell-group/store20-service-core";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface PurchaseHoldRequiredPayload {
  stockId: number;
  itemId: string;
}

export interface PurchaseHoldLoadedPayload extends PurchaseHoldInfo {
  stockId: number;
  itemId: string;
  loadedAt: string;
}

export interface PurchaseHoldLoadFailedPayload {
  stockId: number;
  itemId: string;
  reason: GetItemErrorReason | GenericOperationError;
}

const name = "purchaseHold";

export type PurchaseHoldWithState =
  | ({
      state: "loaded";
      itemId: string;
    } & PurchaseHoldInfo & {
        loadedAt: string;
      })
  | ({
      state: "loading";
    } & Partial<InventoryLocationItem>)
  | {
      state: "error";
      itemId: string;
      reason: GetItemErrorReason | GenericOperationError;
    };

export interface PurchaseHoldState {
  items: Record<string, PurchaseHoldWithState>;
}

const initialState: PurchaseHoldState = {
  items: {},
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    purchaseHoldRequired(
      state,
      { payload }: PayloadAction<PurchaseHoldRequiredPayload>
    ) {
      const { itemId, stockId } = payload;
      state.items[`${stockId}-${itemId}`] ??= {
        state: "loading",
      };
      state.items[`${stockId}-${itemId}`].state = "loading";
    },

    purchaseHoldLoaded(
      state,
      { payload }: PayloadAction<PurchaseHoldLoadedPayload>
    ) {
      const { stockId, itemId, ...rest } = payload;
      state.items[`${stockId}-${itemId}`] = {
        state: "loaded",
        itemId,
        ...rest,
      };
    },

    purchaseHoldLoadFailed(
      state,
      { payload }: PayloadAction<PurchaseHoldLoadFailedPayload>
    ) {
      const { stockId, itemId, reason } = payload;
      state.items[`${stockId}-${itemId}`] = {
        state: "error",
        itemId,
        reason,
      };
    },
  },
});

export const {
  reducer: purchaseHoldReducer,
  actions: { purchaseHoldRequired, purchaseHoldLoaded, purchaseHoldLoadFailed },
} = slice;
