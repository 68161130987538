import { Store } from "@ahlsell-group/store20-store-service";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import { WarehouseCoordinates } from "../../warehouse/types";
import { selectWarehouseCoordinates } from "../../warehouse/warehouseSelectors";
import { centralWarehouseIds } from "../centralWarehousesForLegalEntity";
import {
  loadNearbyStoresError,
  nearbyStoresReceived,
} from "../nearbyStoresSlice";

export default function* loadNearbyStoresSaga({
  storeSearchService,
  handleSagaError,
}: ServiceContainer): Saga {
  try {
    const warehouseCoordinates: WarehouseCoordinates | undefined = yield select(
      selectWarehouseCoordinates
    );

    if (!warehouseCoordinates) {
      yield put(
        loadNearbyStoresError({ reason: "no-coordinates-for-user-warehouse" })
      );
      return;
    }

    const stores: Store[] = yield call(
      [storeSearchService, "getStoresByProximity"],
      warehouseCoordinates.latitude,
      warehouseCoordinates.longitude,
      // Skip the first one since it should be the user's current warehouse.
      { skip: 1, top: 10 }
    );

    const storesWithoutCentralWarehouses = stores
      .filter((store) => !centralWarehouseIds.includes(store.storeId))
      .slice(0, 5);

    yield put(nearbyStoresReceived(storesWithoutCentralWarehouses));
  } catch (e) {
    yield call(
      handleSagaError,
      (errorCode) => `saga:item-stock:loadNearbyStoresSaga:${errorCode}`,
      e,
      put(loadNearbyStoresError(toErrorActionPayload(e)))
    );
  }
}
