import { ConfigManager } from "@ahlsell-group/store20-service-core";

import { ApplicationInsightsTelemetryProvider } from "./ApplicationInsightsTelemetryProvider";
import GoogleAnalyticsTelemetryProvider from "./GoogleAnalyticsTelemetryProvider";
import LogRocketTelemetryProvider from "./LogRocketTelemetryProvider";
import { AppTelemetrySessionInfo } from "./types";

export interface AppTelemetryService {
  updateSessionInfo(props: Partial<AppTelemetrySessionInfo>): void;
  readonly applicationInsightsProvider?: ApplicationInsightsTelemetryProvider;
}

export const nullAppTelemetryService: AppTelemetryService = {
  updateSessionInfo() {},
};

export class AppTelemetryServiceImpl implements AppTelemetryService {
  public readonly applicationInsightsProvider?: ApplicationInsightsTelemetryProvider;
  private readonly googleAnalyticsProvider: GoogleAnalyticsTelemetryProvider;
  private readonly logRocketTelemetryProvider: LogRocketTelemetryProvider;

  public constructor(
    configManager: ConfigManager<BootstrapConfig>,
    initialProps: AppTelemetrySessionInfo
  ) {
    if (configManager.getConfig().applicationInsightsConnectionString) {
      this.applicationInsightsProvider =
        new ApplicationInsightsTelemetryProvider(
          configManager.getConfig().applicationInsightsConnectionString,
          initialProps
        );
    }
    this.googleAnalyticsProvider = new GoogleAnalyticsTelemetryProvider(
      initialProps
    );
    this.logRocketTelemetryProvider = new LogRocketTelemetryProvider(
      configManager,
      initialProps
    );
  }

  public updateSessionInfo(props: Partial<AppTelemetrySessionInfo>): void {
    this.applicationInsightsProvider?.updateSessionInfo(props);
    this.googleAnalyticsProvider.updateSessionInfo(props);
    this.logRocketTelemetryProvider.updateSessionInfo(props);
  }
}
