import * as SDCBarcode from "scandit-web-datacapture-barcode";
import * as SDCCore from "scandit-web-datacapture-core";

import ScanningContextService, {
  ErrorScanningContextState,
  InitializingScanningContextState,
  ReadyScanningContextState,
  ScanningContextServiceBase,
  UninitializedScanningContextState,
} from "./ScanningContextService";
import { CameraError } from "./types";

const getDefaultBarcodeSettings = () => {
  const settings = new SDCBarcode.BarcodeCaptureSettings();
  settings.enableSymbologies([
    SDCBarcode.Symbology.EAN8,
    SDCBarcode.Symbology.EAN13UPCA,
    SDCBarcode.Symbology.UPCE,
    SDCBarcode.Symbology.Code39,
    SDCBarcode.Symbology.Code128,
    SDCBarcode.Symbology.QR,
  ]);
  settings.codeDuplicateFilter = 2000;
  return settings;
};

interface InternalWebContext {
  view: SDCCore.DataCaptureView;
  camera: SDCCore.Camera;
  barcodeCapture: SDCBarcode.BarcodeCapture;
  overlay: SDCBarcode.BarcodeCaptureOverlay;
}

export type InternalWebScanningContextState =
  | UninitializedScanningContextState
  | (InitializingScanningContextState & Pick<InternalWebContext, "view">)
  | (ReadyScanningContextState & InternalWebContext)
  | ErrorScanningContextState;

export default class WebScanningContextService
  extends ScanningContextServiceBase
  implements ScanningContextService
{
  private internalState: InternalWebScanningContextState = {
    state: "uninitialized",
  };

  constructor(private readonly licenseKey: string) {
    super();
  }

  public get state(): InternalWebScanningContextState {
    return this.internalState;
  }

  private set state(state: InternalWebScanningContextState) {
    const previousState = this.internalState;
    this.internalState = state;
    this.triggerStateChange(state, previousState);
  }

  private readonly listener: SDCBarcode.BarcodeCaptureListener = {
    didScan: (
      _: SDCBarcode.BarcodeCapture,
      session: SDCBarcode.BarcodeCaptureSession
    ) => {
      this.triggerScan(session.newlyRecognizedBarcodes);
    },
  };

  public get cameraError(): CameraError | undefined {
    if (this.state.state === "error") return this.state.error;
    return undefined;
  }

  private log(message: string) {
    // eslint-disable-next-line no-console
    console.debug("[WebScanningContextService]", message);
  }

  public async initialize() {
    const view = new SDCCore.DataCaptureView();
    this.state = {
      state: "initializing",
      view,
    };
    this.log("Initializing");

    await SDCCore.configure({
      licenseKey: this.licenseKey,
      libraryLocation: new URL("scandit/", document.baseURI).toString(),
      moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
    });
    this.log("Configured");

    const captureContext = await SDCCore.DataCaptureContext.create();
    await view.setContext(captureContext);
    this.log("Context set");

    const camera = SDCCore.Camera.default;
    await camera.applySettings(
      SDCBarcode.BarcodeCapture.recommendedCameraSettings
    );
    this.log("Settings applied");

    await captureContext.setFrameSource(camera);
    this.log("Frame source set");

    const settings = getDefaultBarcodeSettings();

    settings.locationSelection = new SDCCore.RadiusLocationSelection(
      new SDCCore.NumberWithUnit(5.0, SDCCore.MeasureUnit.Pixel)
    );
    const barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(
      captureContext,
      getDefaultBarcodeSettings()
    );
    this.log("Got barcode capture");
    await barcodeCapture.setEnabled(false);
    this.log("Barcode capture disabled");
    barcodeCapture.addListener(this.listener);
    const overlay =
      await SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
        barcodeCapture,
        view,
        SDCBarcode.BarcodeCaptureOverlayStyle.Frame
      );
    this.log("Overlay created");

    const viewfinder = new SDCCore.LaserlineViewfinder(
      SDCCore.LaserlineViewfinderStyle.Animated
    );
    view.pointOfInterest = new SDCCore.PointWithUnit(
      new SDCCore.NumberWithUnit(0.5, SDCCore.MeasureUnit.Fraction),
      new SDCCore.NumberWithUnit(0.5, SDCCore.MeasureUnit.Fraction)
    );
    view.scanAreaMargins = new SDCCore.MarginsWithUnit(
      new SDCCore.NumberWithUnit(92.0, SDCCore.MeasureUnit.Pixel),
      new SDCCore.NumberWithUnit(0.33, SDCCore.MeasureUnit.Fraction),
      new SDCCore.NumberWithUnit(92.0, SDCCore.MeasureUnit.Pixel),
      new SDCCore.NumberWithUnit(0.33, SDCCore.MeasureUnit.Fraction)
    );
    await overlay.setViewfinder(viewfinder);
    this.log("Viewfinder set");

    // Set to true for debugging
    await overlay.setShouldShowScanAreaGuides(false);
    this.log("Scan area guides hidden");

    this.state = {
      state: "ready",
      view,
      camera,
      barcodeCapture,
      overlay,
    };
  }

  public connectElement(element: HTMLElement) {
    if (this.state.state === "uninitialized") {
      throw new Error(
        "Scanning Context is uninitialized. Call initialize first"
      );
    }
    if (this.state.state !== "error") {
      this.state.view.connectToElement(element);
      this.log("Connected to element");
    }
  }

  public disconnectElement() {
    if (this.state.state === "uninitialized") {
      return;
    }
    if (this.state.state !== "error") {
      this.state.view.detachFromElement();
      this.log("Detached from element");
    }
  }

  public async startCamera() {
    if (this.state.state === "camera-active" || this.state.state === "error") {
      return;
    }
    if (this.state.state !== "ready") {
      throw new Error(
        "Scanning context not ready yet. Wait for state to change to ready"
      );
    }
    try {
      await this.state.camera.switchToDesiredState(SDCCore.FrameSourceState.On);
      this.state.state = "camera-active";
      this.log("Camera active");
    } catch (e: any) {
      this.state = {
        state: "error",
        error: e.name ?? "UnknownError",
      };
      this.log("Camera failed to start");
    }
  }

  public async startScanning() {
    if (this.state.state !== "camera-active") {
      throw new Error(
        "Scanning context not ready yet. State must first be initialized, and the camera must then be started"
      );
    }
    if (this.state.barcodeCapture.isEnabled()) {
      this.log("Scanning already started");
      return;
    }

    await this.state.barcodeCapture.setEnabled(true);
    this.log("Scanning started");
  }

  public async stopScanning() {
    if (
      (this.state.state !== "camera-active" && this.state.state !== "ready") ||
      !this.state.barcodeCapture.isEnabled()
    ) {
      this.log("Scanning already stopped");
      return;
    }

    await this.state.barcodeCapture.setEnabled(false);
    this.log("Scanning stopped");
  }

  public async stopCamera() {
    if (this.state.state !== "camera-active" && this.state.state !== "ready") {
      this.log("Camera already stopped");
      return;
    }

    await this.stopScanning();
    await this.state.camera.switchToDesiredState(SDCCore.FrameSourceState.Off);
    this.state.state = "ready";
    this.log("Camera stopped");
  }
}
