import { Selector } from "../../types";

import { ItemSearchError, ItemSearchResult } from "./types";

export const selectItemSearchResult: Selector<ItemSearchResult | undefined> = (
  state
) => state.itemSearch.result;

export const selectItemSearchError: Selector<ItemSearchError | undefined> = (
  state
) => state.itemSearch.error;
