import { useTranslation } from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { PageSubheadline } from "@ahlsell-group/app-ui/PageSubheadline";
import React from "react";

import getConfig from "../../../app/config";
import { AppPage } from "../../page/components/AppPage";
import NotFound from "../../routing/components/NotFound";
import useNavigate from "../../routing/useNavigate";
import { getPage } from "../content";

import AppBoxedArea from "./AppBoxedArea";

const MyPageAbout: React.FC = function () {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { versionDisplay } = getConfig();
  const page = getPage("general", "about");

  if (!page) {
    return <NotFound />;
  }

  return (
    <AppPage
      data-cy="MyPageAbout"
      headline={
        <PageSubheadline
          onBack={() => navigate.back()}
          back-data-cy="MyPageAbout-back"
        >
          {page.header}
        </PageSubheadline>
      }
    >
      <PageSection className="gap-4">
        <div>
          <AppBoxedArea>
            {t("myPage.version", { version: versionDisplay })}
          </AppBoxedArea>
        </div>

        <div>
          <AppBoxedArea>{page.detail}</AppBoxedArea>
        </div>
      </PageSection>
    </AppPage>
  );
};

export default MyPageAbout;
