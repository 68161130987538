import { autonyms, useTranslation } from "@ahlsell-group/app-localization";
import { CheckIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { BoxedLinkButton } from "@ahlsell-group/ui-kit/navigation";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import useServiceContainer from "../../../app/components/ServiceContainerContext";
import { stepCompleted } from "../gettingStartedSlice";
import { StepComponent } from "../types";

import GettingStartedPage from "./GettingStartedPage";

const LanguageSelection: StepComponent = function LanguageSelection({
  hasPreviousStep,
}) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("common");
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { localizationService } = useServiceContainer();

  // Do not dispatch language change, as we don't want it committed until `onContinue`.
  const onChangeLanguage = (lang: string) => {
    setSelectedLanguage(lang);
    localizationService.changeLanguage(lang);
  };

  const onContinue = () => {
    dispatch(
      stepCompleted({
        type: "languageSelection",
        selectedLanguage,
      })
    );
  };

  return (
    <GettingStartedPage
      title={t("gettingStarted.languageSelection.title")}
      actions={{
        continue: {
          text: t("continue"),
          primary: true,
          "data-cy": "LanguageSelection-continue",
        },
      }}
      onAction={onContinue}
      data-cy="LanguageSelection"
      backButton={hasPreviousStep}
    >
      <div>
        <Typography variant="heading-h5">
          {t("gettingStarted.languageSelection.subheader")}
        </Typography>

        {Object.entries(autonyms).map(([code, name]) => (
          <BoxedLinkButton
            key={code}
            data-cy={`LanguageSelection-${code}`}
            className="mt-2"
            icon={selectedLanguage === code ? CheckIcon : undefined}
            onClick={() => onChangeLanguage(code)}
          >
            {name}
          </BoxedLinkButton>
        ))}
      </div>
    </GettingStartedPage>
  );
};

export default LanguageSelection;
