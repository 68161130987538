import { State } from "router5";

import { Selector } from "../../types";

export const selectCurrentRoute: Selector<State | undefined> = (state) =>
  state.router.route ?? undefined;

export const selectIsRouteNotFound: Selector<boolean> = (state) =>
  state.router.transitionError?.code === "ROUTE_NOT_FOUND";

/** Autofocus is enabled if the param exists. */
export const selectRouteParamAutofocus: Selector<boolean> = (state) =>
  state.router.route?.params.autofocus !== undefined;

export const selectPreviousRoute: Selector<State | undefined> = (state) =>
  state.router.previousRoute ?? undefined;
