/// <reference types="webpack/module" />
import "./polyfills";

import { SingletonConfigManager } from "@ahlsell-group/store20-service-core";

import * as bootstrap from "./app/bootstrap";
import e2eSetup from "./e2eSetup";
import { createDebugObject } from "./features/debug";

export const main = async () => {
  const configManager = SingletonConfigManager.getInstance(
    bootstrap.loadConfig()
  );
  const serviceContainer = bootstrap.createServiceContainer(configManager);
  bootstrap.updateTelemetryViewportOnResize(serviceContainer);
  await bootstrap.localization(serviceContainer);
  const [store, startStore] = await bootstrap.redux(
    configManager,
    serviceContainer
  );
  const renderAppRoot = bootstrap.createAppRoot(store, serviceContainer);
  e2eSetup(store);
  const debugObject = createDebugObject(false);
  bootstrap.assignServicesToDebugObject(serviceContainer, store, debugObject);
  await bootstrap.removeUnusedLocallyStoredItems(serviceContainer);

  serviceContainer.routingService.start();
  serviceContainer.serviceWorkerService.registerServiceWorker();
  renderAppRoot();
  startStore();
};

await main();

if (import.meta.IS_DEV_MODE) {
  // On iOS with Capacitor, the app's background is black, while it is white on
  // other platforms. In order to make it less likely that we accidentally mess
  // up the design, make the app's background red during local development.
  document.documentElement.style.setProperty("background", "red", "important");
}
