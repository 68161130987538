import { DebugSafeArea } from "@ahlsell-group/app-ui/DebugSafeArea";
import { HtmlMetaThemeColor } from "@ahlsell-group/ui-kit/util";
import React from "react";
import { Provider } from "react-redux";
import { Store } from "redux";

import { getDebugObject } from "../../features/debug";
import ErrorBoundaryRoot from "../../features/error/components/ErrorBoundaryRoot";
import { ServiceContainer } from "../serviceContainer";

import App from "./App";
import { ServiceContainerProvider } from "./ServiceContainerContext";

const Root = function (store: Store, serviceContainer: ServiceContainer) {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ServiceContainerProvider serviceContainer={serviceContainer}>
          <ErrorBoundaryRoot>
            <HtmlMetaThemeColor color="bg-gray-100">
              <App />
              {import.meta.IS_DEV_MODE && (
                <DebugSafeArea
                  onInit={({ setDirection }) => {
                    const debug = getDebugObject();
                    debug.safeArea = {
                      x: () => setDirection("x"),
                      y: () => setDirection("y"),
                      all: () => setDirection("all"),
                      none: () => setDirection("none"),
                    };
                  }}
                />
              )}
            </HtmlMetaThemeColor>
          </ErrorBoundaryRoot>
        </ServiceContainerProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default Root;
