import { Selector } from "../types";

import { AppState, UserLoginError } from "./appSlice";

export const selectAppInitializationState: Selector<AppState["state"]> = (
  state
) => state.app.state;

export const selectImageBaseUrl: Selector<string> = (state) =>
  state.app.state === "ready" ? state.app.config.imageBaseUrl : "";

export const selectConfig: Selector<BootstrapConfig> = (state) => {
  if (
    state.app.state !== "bootstrapping" &&
    state.app.state !== "install-mode"
  ) {
    return state.app.config;
  }
  throw new Error(
    "Tried to select config before app was bootstrapped. This indicates that a saga is running, or a component is mounted too early"
  );
};

export const selectUserLoginError: Selector<UserLoginError | undefined> = (
  state
) => (state.app.state === "starting" ? state.app.loginError : undefined);

export const selectUserName: Selector<string | undefined> = (state) =>
  state.app.state !== "bootstrapping" && state.app.state !== "install-mode"
    ? state.app.user?.name
    : undefined;

export const selectUserEmail: Selector<string | undefined> = (state) =>
  state.app.state !== "bootstrapping" && state.app.state !== "install-mode"
    ? state.app.user?.email
    : undefined;

export const selectUserFirstName: Selector<string | undefined> = (state) =>
  state.app.state !== "bootstrapping" && state.app.state !== "install-mode"
    ? state.app.user?.name?.split(" ")[0]
    : undefined;

export const selectLegalEntityId: Selector<number | undefined> = (state) =>
  state.app.state !== "bootstrapping" && state.app.state !== "install-mode"
    ? state.app.user?.legalEntityId
    : undefined;

export const selectVivaldiEmployeeNumber: Selector<string> = (state) =>
  state.app.state !== "bootstrapping" && state.app.state !== "install-mode"
    ? state.app.user?.vivaldiEmployeeNumber || ""
    : "";

export const selectCurrentLanguage: Selector<string> = (state) =>
  state.app.state !== "bootstrapping" && state.app.state !== "install-mode"
    ? state.app.language
    : "";
