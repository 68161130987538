/* eslint-disable no-console */

import { IServiceWorkerService } from "./ServiceWorkerService";

class NullServiceWorkerService implements IServiceWorkerService {
  async registerServiceWorker() {
    console.log("[SW] Service Worker not enabled in dev mode");

    // If we have some stray SW registration, remove it.
    if (navigator.serviceWorker) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      registrations.forEach((x) => x.unregister());
    }
  }

  allowUpdate() {}
}

export default NullServiceWorkerService;
