import { Store } from "redux";

import getConfig from "./app/config";
import { selectFeatureFlags } from "./features/config/configSelectors";
import { State } from "./types";

/** Setup for E2E Cypress tests. */
const e2eSetup = (store: Store<State>) => {
  if (!window.Cypress) {
    return;
  }

  const { dataset } = document.documentElement;

  // Expose feature flags as `[data-cy-feature-flags]`.
  const updateDom = () => {
    dataset.cyFeatureFlags = selectFeatureFlags(store.getState()).join();
  };

  updateDom();
  store.subscribe(updateDom);

  // Expose update interval as `[data-cy-update-interval-minutes]`.
  dataset.cyUpdateIntervalMinutes = `${getConfig().updateIntervalMinutes}`;
};

export default e2eSetup;
