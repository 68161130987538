import { Config, FeatureFlag } from "@ahlsell-group/store20-config-service";
import { GenericOperationError } from "@ahlsell-group/store20-service-core";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type ConfigLoadFailedReason = GenericOperationError;

export interface ConfigUpdatedPayload {
  config: Config;
}

export interface ConfigState {
  featureFlags: FeatureFlag[];
}

const initialState: ConfigState = {
  featureFlags: [],
};

const slice = createSlice({
  name: "config",
  initialState,
  reducers: {
    configUpdated(state, { payload }: PayloadAction<ConfigUpdatedPayload>) {
      state.featureFlags = payload.config.featureFlags;
    },
  },
});

export const {
  reducer: configReducer,
  actions: { configUpdated },
} = slice;
