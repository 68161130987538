import { AppTelemetrySessionInfo } from "./types";

export const getWarehouseLabel = (
  props: Pick<AppTelemetrySessionInfo, "warehouseId" | "warehouseName">
): string | undefined =>
  [props.warehouseId, props.warehouseName].filter(Boolean).join(" ") ||
  undefined;

export const deleteUndefined = <V>(
  obj: Record<string, V | undefined>
): Record<string, V> => {
  Object.entries(obj).forEach(([key, value]) => {
    if (value === undefined) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });

  return obj as Record<string, V>;
};
