import { useTranslation } from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { ArrowLeftIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Fab, IconButton } from "@ahlsell-group/ui-kit/inputs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import typescriptAssertNever from "../../../util/typescriptAssertNever";
import ErrorModal from "../../error/components/ErrorModal";
import useItemImageUrl from "../../item-image/useItemImageUrl";
import { AppLoadingPage } from "../../page/components/AppLoadingPage";
import { AppPage } from "../../page/components/AppPage";
import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import { selectInventoryLocationNewItemState } from "../inventoryLocationSelectors";
import { itemAdded } from "../inventoryLocationSlice";

import ItemLocation from "./ItemLocation";

const InventoryLocationNewItem: React.FC<ViewComponentProps> = function () {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const assetUrl = useItemImageUrl();
  const dispatch = useDispatch();
  const itemState = useSelector(selectInventoryLocationNewItemState);
  const warehouseId = useSelector(selectWarehouseId);

  useEffect(() => {
    if (itemState.type === "none") {
      navigate(routes.inventoryLocation.change, {}, { replace: true });
    }
  }, [itemState, navigate]);

  if (itemState.type === "none") {
    throw new Error("[internal error] Did not get a new item to add");
  }

  if (itemState.type === "error") {
    return (
      <ErrorModal
        data-cy="inventory-location-error"
        data-cy-error-reason={itemState.error.reason}
        category="inventoryLocationItemSearch"
        error={itemState.error}
        onClose={() => navigate.back()}
      />
    );
  }

  if (itemState.type === "loading") {
    return (
      <AppLoadingPage onCancel={() => navigate.back()}>
        {t("inventoryLocation.loadingItem")}
      </AppLoadingPage>
    );
  }

  if (itemState.type === "loaded") {
    const { item } = itemState;

    return (
      <AppPage backgroundColor="white" data-cy="InventoryLocationItem">
        <PageSection>
          <IconButton
            className="absolute"
            variant="tertiary"
            rounded="full"
            size="large"
            icon={ArrowLeftIcon}
            data-cy="InventoryLocationItem-back"
            onClick={() => navigate.back()}
          />

          <div className="mx-auto py-9 max-w-[11.25rem] max-h-[11.25rem]">
            <img
              className="object-contain w-full h-full"
              src={assetUrl(item.assetUrl)}
              alt=""
            />
          </div>

          <Typography
            data-cy="InventoryLocationItem-itemId"
            className="mb-3"
            variant="heading-h2"
          >
            {t("inventoryLocation.itemIdShort")} {item.itemId}
          </Typography>
          <Typography variant="heading-h3" color="gray" className="mb-1">
            {item.name}
          </Typography>
          <Typography variant="body" color="gray" className="mb-6">
            {item.description1} {item.description2}
          </Typography>
          <Typography variant="body-sm" color="gray">
            <ItemLocation
              warehouseId={warehouseId}
              itemId={item.itemId}
              includeCurrentPrefix
            />
          </Typography>
        </PageSection>
        <div className="absolute left-0 bottom-0 w-full">
          <PageSection>
            <Fab
              data-cy="InventoryLocationItem-add"
              variant="primary"
              size="large"
              onClick={() => {
                dispatch(itemAdded(item));
                navigate.back();
              }}
            >
              {t("inventoryLocation.add")}
            </Fab>
          </PageSection>
        </div>
      </AppPage>
    );
  }

  typescriptAssertNever(itemState);
  return null;
};

export default InventoryLocationNewItem;
