import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { State } from "router5";
import { startsWithSegment } from "router5-helpers";

import { State as AppState } from "../../../types";
import { AppRoute } from "../utils";

export interface ViewComponentProps<
  R extends Record<string, string | boolean | undefined> = Record<
    string,
    string | undefined
  >
> {
  route: string;
  params: R;
}

export interface AlwaysRenderViewComponentProps<
  R extends Record<string, any> = Record<string, any>
> extends ViewComponentProps<R> {
  active: boolean;
}

interface ViewPropsBase {
  route: AppRoute | AppRoute[];
  exact?: boolean;
  element?: React.ReactNode;
}

interface MountedViewProps<P extends Record<string, any>> {
  renderMode?: "mount";
  component?: React.ComponentType<ViewComponentProps<P>>;
}

interface AlwaysRenderViewProps<P extends Record<string, any>> {
  renderMode: "always";
  component?: React.ComponentType<AlwaysRenderViewComponentProps<P>>;
}

type ViewProps<P extends Record<string, any> = Record<string, any>> =
  ViewPropsBase & (MountedViewProps<P> | AlwaysRenderViewProps<P>);

interface RouteState {
  route: State | null;
  previousRoute: State | null;
}

const compareCurrentRoute = (left: RouteState, right: RouteState) =>
  left.route?.path === right.route?.path;

function View<P extends Record<string, any> = Record<string, any>>({
  route,
  renderMode,
  component: Component,
  exact = false,
  element,
  ...rest
}: ViewProps<P>): React.ReactElement<ViewProps<P>> | null {
  const { route: currentRoute } = useSelector<AppState, RouteState>(
    (state) => state.router,
    compareCurrentRoute
  );
  const routes = useMemo(
    () => (Array.isArray(route) ? route : [route]),
    [route]
  );

  if (currentRoute === null) return null;

  const isActive = exact
    ? routes.some((x) => currentRoute.name === x.route)
    : routes.some((x) => startsWithSegment(currentRoute, x.route));

  if (isActive || renderMode === "always") {
    if (element) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{element}</>;
    }

    if (Component) {
      return (
        <Component
          route={currentRoute.name}
          params={currentRoute.params as P}
          active={isActive}
          {...rest}
        />
      );
    }
  }

  return null;
}

export default View;
