import { actions } from "redux-router5";
import { put } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import getItemIdByBarcode from "../../item-information/sagas/getItemIdByBarcode";
import routes from "../../routing/routes";
import { TransitionSuccessAction } from "../../routing/types";
import {
  itemLoadInvalidBarcode,
  itemLoadFailed,
} from "../manualStockTakingSlice";

export function* getItemByBarcodeSaga(
  { itemSearchService, handleSagaError }: ServiceContainer,
  action: TransitionSuccessAction
): Saga {
  const { itemId: barcode } = action.payload.route.params;

  if (typeof barcode !== "string") throw new Error("Missing params.itemId");

  try {
    const itemId: string | undefined = yield getItemIdByBarcode(
      barcode,
      itemSearchService
    );

    if (itemId === undefined) {
      yield put(itemLoadInvalidBarcode(barcode));
    } else {
      yield put(
        actions.navigateTo(
          routes.stockTaking.manual.item.route,
          { itemId, autofocus: null, continueTo: "scanner" },
          { replace: true }
        )
      );
    }
  } catch (e) {
    yield* handleSagaError(
      (errorCode) => `saga:stock-taking:getItemByBarcodeSaga:${errorCode}`,
      e,
      put(itemLoadFailed(toErrorActionPayload(e, { itemId: barcode })))
    );
  }
}
