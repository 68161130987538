import { createSelector } from "@reduxjs/toolkit";

import { Selector, State } from "../../types";

import { ItemStockInfo } from "./itemStockSlice";

export const selectItemStock =
  (warehouseId: number, itemId: string) =>
  (state: State): ItemStockInfo | undefined =>
    state.itemStock[warehouseId]?.[itemId];

export interface ItemInWarehouse extends ItemStockInfo {
  warehouseId: number;
  itemId: string;
}

export const selectItemsToExpire = (date: Date): Selector<ItemInWarehouse[]> =>
  createSelector(
    (state: State) => state.itemStock,
    (itemStockState) =>
      Object.entries(itemStockState).reduce<ItemInWarehouse[]>(
        (result, [warehouseId, items]) => [
          ...result,
          ...Object.entries(items)
            .filter(
              ([, item]) =>
                (item.state === "loaded" || item.state === "expired") &&
                item.updatedAt &&
                new Date(item.updatedAt) < date
            )
            .map(([itemId, item]) => ({
              warehouseId: Number(warehouseId),
              itemId,
              ...item,
            })),
        ],
        []
      )
  );
