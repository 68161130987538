import { HtmlMetaThemeColor } from "@ahlsell-group/ui-kit/util";
import React from "react";

import useNavigate from "../../routing/useNavigate";

import BarcodeScannerPage, {
  BarcodeScannerPageProps,
} from "./BarcodeScannerPage";

export interface BarcodeScannerModalProps extends BarcodeScannerPageProps {}

const BarcodeScannerModal: React.FC<BarcodeScannerModalProps> =
  function BarcodeScannerModal({ onClose, ...props }) {
    const navigate = useNavigate();
    const handleClose = onClose ?? (() => navigate.back());

    return (
      <HtmlMetaThemeColor color="bg-black">
        <div className="absolute flex flex-col inset-0 h-full w-full">
          <BarcodeScannerPage onClose={handleClose} {...props} />
        </div>
      </HtmlMetaThemeColor>
    );
  };

export default BarcodeScannerModal;
