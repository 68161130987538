import { AppConfig } from "@ahlsell-group/store20-config-service";
import { call, put } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { ScanningMode, scanningModeChanged } from "../barcodeScannerSlice";

const isValid = (
  scanningMode: string | undefined
): scanningMode is ScanningMode =>
  ["continuous", "manual"].includes(scanningMode ?? "");

export default function* loadScanningModeSaga({
  appConfigService,
}: ServiceContainer) {
  const appConfig: AppConfig = yield call([appConfigService, "getAppConfig"]);
  const { scanningMode } = appConfig;
  const finalScanningMode = isValid(scanningMode) ? scanningMode : "manual";
  yield put(scanningModeChanged(finalScanningMode));
}
