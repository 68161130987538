import {
  useTranslation,
  useErrorMessage,
  Trans,
} from "@ahlsell-group/app-localization";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Button } from "@ahlsell-group/ui-kit/inputs";
import { Center } from "@ahlsell-group/ui-kit/layout";
import React from "react";

import routes from "../routes";
import useNavigate from "../useNavigate";

const NotFound: React.FC = function () {
  const { t } = useTranslation("common");
  const { headerKey, detailKey } = useErrorMessage("general", "404")!;
  const navigate = useNavigate();

  return (
    <Center variant="center" className="px-12">
      <Typography variant="body" className="pb-2">
        <Trans t={t} i18nKey={headerKey} />
      </Typography>
      <Typography variant="body-sm" className="pb-8">
        <Trans t={t} i18nKey={detailKey} />
      </Typography>
      <div className="pb-12">
        <Button
          variant="tertiary"
          size="large"
          width="content"
          onClick={() => navigate(routes.home)}
        >
          {t("navigation.backToStart")}
        </Button>
      </div>
      <Typography variant="body-sm" color="gray">
        {window.location.pathname}
      </Typography>
    </Center>
  );
};

export default NotFound;
