import {
  AllErrorCategories,
  ErrorKeysByCategory,
  Trans,
  useErrorMessage,
  useTranslation,
} from "@ahlsell-group/app-localization";
import React from "react";

export interface ErrorPageSectionProps<TCategory extends AllErrorCategories> {
  category: TCategory;
  error:
    | {
        reason: ErrorKeysByCategory<TCategory>;
        data?: Record<string, unknown>;
      }
    | undefined;
  fallbackErrorKey?: ErrorKeysByCategory<TCategory>;
  "data-cy"?: string;
  extraContent?: React.ReactNode;
}

const ErrorPageSection = function <TCategory extends AllErrorCategories>({
  category,
  error,
  fallbackErrorKey,
  extraContent,
  ...rest
}: ErrorPageSectionProps<TCategory>) {
  const { t } = useTranslation("common");
  const errorMessage = useErrorMessage(
    category,
    error?.reason,
    fallbackErrorKey
  );

  if (!error || !errorMessage) return null;

  return (
    <>
      {/* Inherit font size and other properties. */}
      <h3 className="font-bold" {...rest}>
        <Trans t={t} i18nKey={errorMessage.headerKey} tOptions={error.data} />
      </h3>
      <div>
        <Trans t={t} i18nKey={errorMessage.detailKey} tOptions={error.data} />
      </div>
      {extraContent && <div className="mt-2">{extraContent}</div>}
    </>
  );
};

export default ErrorPageSection;
