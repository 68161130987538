import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { NavigationOptions, State } from "router5";
import { startsWithSegment } from "router5-helpers";

import { selectCurrentRoute } from "./routingSelectors";
import useRouter from "./useRouter";
import { AppRoute } from "./utils";

export type NavigateTo = (
  params?: Record<string, any>,
  options?: NavigationOptions
) => Promise<void>;

const useRoute = (
  { route }: AppRoute,
  { exact = false } = {}
): [boolean, NavigateTo] => {
  const router = useRouter();
  const matchesRoute = useCallback(
    (routerState: State) => {
      if (exact) {
        return routerState.name === route;
      }

      return startsWithSegment(routerState.name, route);
    },
    [exact, route]
  );

  const currentRoute = useSelector(selectCurrentRoute);
  const active = useMemo(
    () => !!currentRoute && matchesRoute(currentRoute),
    [currentRoute, matchesRoute]
  );

  const navigateTo = useCallback<NavigateTo>(
    (params = {}, options = {}) =>
      new Promise<void>((resolve) => {
        router.navigate(route, params, options, () => resolve());
      }),
    [route, router]
  );

  return [active, navigateTo];
};

export default useRoute;
