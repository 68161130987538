export type Unsubscribe = () => void;

export default class Listeners<T extends (...args: any[]) => void> {
  private listeners: T[] = [];

  add(listener: T): Unsubscribe {
    this.listeners.push(listener);
    return () => {
      this.listeners.splice(this.listeners.indexOf(listener), 1);
    };
  }

  invoke(...args: Parameters<T>): void {
    this.listeners.forEach((listener) => listener(...args));
  }
}
