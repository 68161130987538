import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { selectRouteParamAutofocus } from "./routingSelectors";
import useRouter from "./useRouter";

const useAutofocus = () => {
  const routeParamAutofocus = useSelector(selectRouteParamAutofocus);
  const router = useRouter();

  const handleAutofocus = useCallback(() => {
    const currentState = router.getState();
    if ("autofocus" in currentState.params) {
      const { autofocus, ...otherParams } = currentState.params;
      router.navigate(currentState.name, otherParams, { replace: true });
    }
  }, [router]);

  const result = useMemo(
    () => ({ autofocus: routeParamAutofocus, handleAutofocus }),
    [handleAutofocus, routeParamAutofocus]
  );

  return result;
};

export default useAutofocus;
