import {
  ErrorKeysByCategory,
  Trans,
  useErrorMessage,
  useTranslation,
} from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { PageSubheadline } from "@ahlsell-group/app-ui/PageSubheadline";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import React from "react";
import { useSelector } from "react-redux";

import { AppPage } from "../../page/components/AppPage";
import { ViewComponentProps } from "../../routing/components/View";
import useNavigate from "../../routing/useNavigate";
import { selectInventoryLocationSubmittedItems } from "../inventoryLocationSelectors";
import { FailedItemStatuses, isFailedItemStatus } from "../util";

type InventoryLocationFailedStatusInfoParams = {
  itemId: string;
};

const InventoryLocationFailedStatusInfo: React.FC<
  ViewComponentProps<InventoryLocationFailedStatusInfoParams>
> = function ({ params }) {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const submittedItems = useSelector(selectInventoryLocationSubmittedItems);
  const items = submittedItems?.filter((item) =>
    isFailedItemStatus(item.status)
  );

  const status = items?.find((item) => item.itemId === params.itemId)
    ?.status as FailedItemStatuses | undefined;

  const statusMap: Record<
    FailedItemStatuses,
    ErrorKeysByCategory<"inventoryLocationSubmit">
  > = {
    InvalidItemId: "invalid-item",
    UpdateFailed: "update-failed",
    UnknownError: "unknown",
  };

  const errorKey = status && (statusMap[status] ?? "unknown");
  const error = useErrorMessage("inventoryLocationSubmit", errorKey);

  if (!error) return null;

  return (
    <AppPage
      backgroundColor="white"
      data-cy="InventoryLocationFailedStatusInfo"
      headline={
        <PageSubheadline onBack={() => navigate.back()}>
          {t("inventoryLocation.inventoryLocation_one")}
        </PageSubheadline>
      }
    >
      <PageSection>
        <Typography variant="heading-h3">
          <Trans t={t} i18nKey={error.headerKey} />
        </Typography>

        <Typography variant="body-sm" className="mt-4">
          <Trans t={t} i18nKey={error.detailKey} />
        </Typography>
      </PageSection>
    </AppPage>
  );
};

export default InventoryLocationFailedStatusInfo;
