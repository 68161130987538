import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectPurchaseHold } from "./purchaseHoldSelectors";
import { purchaseHoldRequired } from "./purchaseHoldSlice";

export const usePurchaseHold = (stockId: number, itemId: string) => {
  const dispatch = useDispatch();
  const purchaseHold = useSelector(selectPurchaseHold(stockId, itemId));

  useEffect(() => {
    const twentyMinutesAgo = Date.now() - 20 * 60 * 1000;
    if (
      !purchaseHold ||
      (purchaseHold.state === "loaded" &&
        new Date(purchaseHold.loadedAt).getTime() < twentyMinutesAgo)
    ) {
      dispatch(purchaseHoldRequired({ stockId, itemId }));
    }
  }, [purchaseHold, dispatch, itemId, stockId]);

  return purchaseHold;
};
