import { useTranslation } from "@ahlsell-group/app-localization";
import { Page } from "@ahlsell-group/app-ui/Page";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Link, LinkButton } from "@ahlsell-group/ui-kit/navigation";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectVivaldiEmployeeNumber } from "../../../app/appSelectors";
import useSplashScreen from "../../../app/hooks/useSplashScreen";
import { UnhandledErrorInfo } from "../errorSlice";

export interface UnhandledErrorProps {
  error: UnhandledErrorInfo;

  // Don't use `?`, in order to force users to explicitly set `undefined` when
  // this is not relevant.
  onTryAgain: (() => void) | undefined;
}

interface ErrorDetailProps {
  header: string;
  details?: string;
}

const ErrorDetail: React.FC<ErrorDetailProps> = function ({ header, details }) {
  if (!details) {
    return null;
  }

  return (
    <>
      <Typography variant="heading-h5">{header}</Typography>
      <pre className="whitespace-pre-wrap break-words !font-[monospace] text-body-detail">
        {details}
      </pre>
    </>
  );
};

const UnhandledError: React.FC<UnhandledErrorProps> = function ({
  error,
  onTryAgain,
}) {
  const { t } = useTranslation("common");
  const vivaldiEmployeeNumber = useSelector(selectVivaldiEmployeeNumber);
  const now = new Date()
    .toISOString()
    // Remove millisecond.
    .replace(/\.\d+/, "");
  const { hideSplashScreen } = useSplashScreen();
  useEffect(() => {
    hideSplashScreen();
  }, [hideSplashScreen]);

  const isOnHomePage =
    window.location.pathname === "/" &&
    window.location.search === "" &&
    window.location.hash === "";

  return (
    <Page>
      <PageSection>
        <div
          className="p-5 max-w-5xl mx-auto"
          data-cy="UnhandledError"
          data-error-message={error.message}
        >
          <Typography variant="heading-h1">{t("error.header")}</Typography>

          <div className="flex flex-wrap">
            {onTryAgain && (
              <LinkButton variant="standalone" onClick={onTryAgain}>
                {t("tryAgain")}
              </LinkButton>
            )}

            <LinkButton
              variant="standalone"
              onClick={() => window.location.reload()}
            >
              {t("error.reloadPage")}
            </LinkButton>

            {!isOnHomePage && (
              <Link variant="standalone" href="/">
                {t("error.goToHome")}
              </Link>
            )}
          </div>

          <Typography
            variant="body-sm"
            color="gray"
            className="flex flex-wrap mb-2"
          >
            <span className="px-2">{vivaldiEmployeeNumber}</span>
            <span className="px-2">{now}</span>
            <span className="px-2">{window.location.href}</span>
          </Typography>

          <details>
            <summary className="cursor-pointer">
              {t("error.technicalDetails")}
            </summary>

            <ErrorDetail
              header="Error"
              details={`${error.name}: ${error.message}`}
            />
            <ErrorDetail
              header="Component stack"
              details={error.componentStack?.trim()}
            />
            <ErrorDetail header="Saga stack" details={error.sagaStack} />
            <ErrorDetail header="Stack" details={error.stack} />
          </details>
        </div>
      </PageSection>
    </Page>
  );
};

export default UnhandledError;
