import { takeEvery } from "redux-saga/effects";

import { ServiceContainer } from "../../app/serviceContainer";

import { itemRequired } from "./itemInformationSlice";
import loadItemSaga from "./sagas/loadItemSaga";

export default function* itemInformationSagas(
  serviceContainer: ServiceContainer
) {
  yield takeEvery(itemRequired.type, loadItemSaga, serviceContainer);
}
