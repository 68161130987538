import { useTranslation } from "@ahlsell-group/app-localization";
import { InventoryLocationItemInfo } from "@ahlsell-group/store20-inventory-location-service";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import classNames from "classnames";
import React from "react";

import ItemImage from "../../item-image/components/ItemImage";

export interface InventoryLocationItemCardProps {
  item: InventoryLocationItemInfo;
  onClick(): void;
  cameraCard?: boolean;
  overlay?: { show: boolean; value?: number };
}

const InventoryLocationItemCard: React.FC<InventoryLocationItemCardProps> =
  function ({ item, onClick, overlay = { show: false }, cameraCard = false }) {
    const { t } = useTranslation("common");

    return (
      <div
        className={classNames("relative items-stretch", {
          "w-1/4": cameraCard,
          "w-1/3": !cameraCard,
        })}
      >
        <button
          type="button"
          className={classNames("rounded p-4 bg-white", {
            "w-[4.35rem] h-[4.8rem]": cameraCard,
            "w-[5.55rem] h-[6.45rem]": !cameraCard,
          })}
          onClick={onClick}
          data-cy={
            overlay.show
              ? `InventoryLocationItemCardToList-button`
              : `InventoryLocationItemCard-button-${item.itemId}`
          }
        >
          <ItemImage
            assetUrl={item.assetUrl}
            alt=""
            size={cameraCard ? "tiny" : "small"}
          />
          <div className="flex justify-center">
            <Typography
              variant={cameraCard ? "heading-h6" : "body-sm"}
              className="absolute bottom-0 pb-1.5 flex"
            >
              {item.itemId}
            </Typography>
          </div>
          {overlay.show && (
            <div
              className={classNames(
                "absolute inset-0 bg-black opacity-80 flex items-center justify-center rounded p-4",
                {
                  "w-[4.35rem] h-[4.8rem]": cameraCard,
                  "w-[5.55rem] h-[6.45rem]": !cameraCard,
                }
              )}
            >
              <Typography color="white">+{overlay.value}</Typography>
              <Typography
                variant="body-sm"
                color="white"
                className="absolute bottom-0 pb-1.5 flex"
              >
                {t("inventoryLocation.showAll")}
              </Typography>
            </div>
          )}
        </button>
      </div>
    );
  };

export default InventoryLocationItemCard;
