const infoBasedOnLegalEntity: {
  [legalEntityId: number]: { emergencyContactNumber: string; language: string };
} = {
  // Ahlsell Sverige
  10: {
    emergencyContactNumber: "0456 477 99",
    language: "sv",
  },
  // Prevex
  15: { emergencyContactNumber: "0456 477 99", language: "sv" },
  // Ahlsell Åland
  31: {
    emergencyContactNumber: "+46 456 477 99",
    language: "sv",
  },
  // Ahlsell Danmark
  32: {
    emergencyContactNumber: "448 27 930",
    language: "da",
  },
  // Ahlsell Norge
  33: {
    emergencyContactNumber: "225 77 121",
    language: "nb",
  },
  // Ahlsell Finland
  34: {
    emergencyContactNumber: "020 584 5900",
    language: "fi",
  },
};

const fallbackNumber: string = "+46 456 477 99";
const fallbackLanguage: undefined = undefined;

const getEmergencyContactNumberByLegalEntity = (legalEntityId?: number) => {
  const number =
    legalEntityId &&
    infoBasedOnLegalEntity[legalEntityId]?.emergencyContactNumber;
  if (!number) {
    return fallbackNumber;
  }

  return number;
};

const getLanguageByLegalEntity = (legalEntityId?: number) => {
  const language =
    legalEntityId && infoBasedOnLegalEntity[legalEntityId]?.language;
  if (!language) {
    return fallbackLanguage;
  }

  return language;
};

export { getEmergencyContactNumberByLegalEntity, getLanguageByLegalEntity };
