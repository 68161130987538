import React, { ErrorInfo } from "react";

type ErrorBoundaryProps = React.PropsWithChildren<{
  onDidCatch(error: Error, errorInfo: ErrorInfo): void;
}>;

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { onDidCatch } = this.props;
    onDidCatch(error, errorInfo);
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    // Even though the error already has been propagated with
    // `props.onDidCatch` to the parent component (`ErrorBoundaryRoot`), any
    // `children` that have an inherent error must never be rendered. If there
    // is an error here, just render `null`.
    return hasError ? null : children;
  }
}

export default ErrorBoundary;
