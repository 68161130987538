import { useTranslation } from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { PageSubheadline } from "@ahlsell-group/app-ui/PageSubheadline";
import { Tab, Tabs } from "@ahlsell-group/ui-kit/navigation";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { selectLegalEntityId } from "../../../app/appSelectors";
import { AppPage } from "../../page/components/AppPage";
import { ViewComponentProps } from "../../routing/components/View";
import useNavigate from "../../routing/useNavigate";
import { getCentralWarehousesForLegalEntity } from "../centralWarehousesForLegalEntity";
import { TabType } from "../types";

import CentralWarehousesItemStock from "./CentralWarehousesItemStock";
import NearbyStoresItemStock from "./NearbyStoresItemStock";

type StockBalanceForOtherWarehousesRouteParams = {
  itemId: string;
};

const OtherWarehousesItemStock: React.FC<
  ViewComponentProps<StockBalanceForOtherWarehousesRouteParams>
> = function ({ params }) {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { itemId } = params;
  const [currentTab, setCurrentTab] = useState<TabType>("nearby-stores");
  const legalEntityId = useSelector(selectLegalEntityId);
  const centralWarehousesCount = legalEntityId
    ? getCentralWarehousesForLegalEntity(legalEntityId).length
    : 0;

  return (
    <AppPage
      backgroundColor="white"
      data-cy="StockBalanceForOtherWarehouses"
      headline={
        <PageSubheadline onBack={() => navigate.back()}>
          {t("itemStock.stockBalance")}
        </PageSubheadline>
      }
    >
      <PageSection backgroundColor="white">
        <Tabs
          size="small"
          value={currentTab}
          onChange={(val) => setCurrentTab(val)}
        >
          <Tab
            value="nearby-stores"
            data-cy="StockBalanceForOtherWarehouses-nearbyStores-tab"
          >
            {t("itemStock.stores")}
          </Tab>
          <Tab
            value="central-warehouses"
            data-cy="StockBalanceForOtherWarehouses-centralWarehouses-tab"
          >
            {t("itemStock.centralWarehouses", {
              count: centralWarehousesCount,
            })}
          </Tab>
        </Tabs>
      </PageSection>
      {currentTab === "nearby-stores" ? (
        <NearbyStoresItemStock itemId={itemId} />
      ) : (
        <CentralWarehousesItemStock itemId={itemId} />
      )}
    </AppPage>
  );
};

export default OtherWarehousesItemStock;
