import { UAParser } from "ua-parser-js";

const shouldShowUpdateAlert = (userAgent: string) => {
  const parsedUserAgent = UAParser(userAgent);

  if (parsedUserAgent.os.name?.toLowerCase() !== "ios") {
    return false;
  }

  const versionAsInt = parseInt(
    parsedUserAgent.os.version?.split("_")[0] ?? "",
    10
  );

  return !Number.isNaN(versionAsInt) && versionAsInt < 17;
};

export default shouldShowUpdateAlert;
