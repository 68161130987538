import { Trans, useTranslation } from "@ahlsell-group/app-localization";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Checkbox, FormControlLabel } from "@ahlsell-group/ui-kit/inputs";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import PrivacyPolicyList from "../../privacy-policy/PrivacyPolicyList";
import RouteLink from "../../routing/components/RouteLink";
import useRouter from "../../routing/useRouter";
import { stepCompleted } from "../gettingStartedSlice";
import { StepComponent } from "../types";

import GettingStartedPage from "./GettingStartedPage";

const PrivacyPolicy: StepComponent = function PrivacyPolicy({
  hasPreviousStep,
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const routerState = router.getState();

  const { t } = useTranslation("common");
  const [accepted, setAccepted] = useState(false);
  const onContinue = () => {
    dispatch(
      stepCompleted({
        type: "privacyPolicy",
        acceptedAt: new Date().toISOString(),
      })
    );
  };

  if (routerState.params.modal === "privacy-policies") {
    return <PrivacyPolicyList />;
  }

  return (
    <GettingStartedPage
      data-cy="PrivacyPolicy"
      title={t("gettingStarted.privacyPolicy.title")}
      actions={{
        continue: {
          text: t("continue"),
          disabled: !accepted,
          primary: true,
          "data-cy": "PrivacyPolicy-continue",
        },
      }}
      onAction={onContinue}
      backButton={hasPreviousStep}
    >
      <div className="mx-8">
        <Typography variant="heading-h3" className="mb-2 text-center">
          {t("gettingStarted.privacyPolicy.subheader")}
        </Typography>
        <Typography variant="body-sm" className="mb-6 text-center">
          {t("gettingStarted.privacyPolicy.body")}
        </Typography>
        <Typography variant="body-sm">
          <FormControlLabel
            label={
              <Trans
                t={t}
                i18nKey="gettingStarted.privacyPolicy.acceptCheckbox"
              >
                <RouteLink
                  data-cy="PrivacyPolicy-open"
                  variant="inline"
                  route={{ route: routerState.name, path: routerState.path }}
                  routeParams={{
                    ...routerState.params,
                    modal: "privacy-policies",
                  }}
                />
              </Trans>
            }
            control={
              <Checkbox
                onChange={() => setAccepted(!accepted)}
                checked={accepted}
                data-cy="PrivacyPolicy-accept"
              />
            }
          />
        </Typography>
      </div>
    </GettingStartedPage>
  );
};

export default PrivacyPolicy;
