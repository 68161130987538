import { AppTelemetrySessionInfo, TelemetryProvider } from "./types";

export default class GoogleAnalyticsTelemetryProvider
  implements TelemetryProvider
{
  private hasSentSession: boolean = false;
  private props: AppTelemetrySessionInfo;

  constructor(initialProps: AppTelemetrySessionInfo) {
    this.props = { ...initialProps };
    this.tryUpdateGA();
  }

  updateSessionInfo(props: Partial<AppTelemetrySessionInfo>): void {
    this.props = Object.assign(this.props, props);
    this.tryUpdateGA();
  }

  private tryUpdateGA() {
    if (
      !this.hasSentSession &&
      this.props.employeeId &&
      this.props.warehouseId &&
      this.props.warehouseName &&
      this.props.legalEntityId
    ) {
      const googleAnalyticsEvent: Record<string, string> = {
        event: "user_info",
        employee_id: this.props.employeeId,
        warehouse_id: this.props.warehouseId.toString(),
        warehouse_name: this.props.warehouseName,
        legal_entity_id: this.props.legalEntityId.toString(),
        pwa_mode: this.props.pwaMode,
      };
      window.dataLayer.push(googleAnalyticsEvent);
      this.hasSentSession = true;
    }
  }
}
