import { actionTypes } from "redux-router5";
import { takeEvery } from "redux-saga/effects";

import { ServiceContainer } from "../../app/serviceContainer";

import navErrorSaga from "./sagas/navErrorSaga";

export default function* routingSagas(serviceContainer: ServiceContainer) {
  yield takeEvery(actionTypes.TRANSITION_ERROR, navErrorSaga, serviceContainer);
}
