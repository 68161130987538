import { useTranslation } from "@ahlsell-group/app-localization";
import {
  CircleExclamationIcon,
  InfoIcon,
} from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Alert } from "@ahlsell-group/ui-kit/feedback";
import { LinkButton } from "@ahlsell-group/ui-kit/navigation";
import React, { createContext, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectIsPendingChangeAlertDismissed } from "../inventoryLocationSelectors";
import {
  itemLocationRequired,
  dismissPendingChangeAlert,
} from "../inventoryLocationSlice";
import useItemLocation from "../useItemLocation";

export const ItemLocationContext = createContext<string>("");

export const ItemLocationSlot: React.FC = function () {
  const itemLocation = useContext(ItemLocationContext);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{itemLocation}</>;
};

export interface ItemLocationProps {
  warehouseId: number;
  itemId: string;
  includePendingChange?: boolean;

  /** @deprecated Use `successTemplate` instead. */
  includeCurrentPrefix?: boolean;

  /**
   * Template to use for rendering success. Use `<ItemLocationSlot />` to
   * display the item location.
   */
  successTemplate?: JSX.Element;
}

/**
 * Component that will display item location, fetching it if needed.
 */
const ItemLocation: React.FC<ItemLocationProps> = function ItemLocation({
  warehouseId,
  itemId,
  includePendingChange = false,
  includeCurrentPrefix = false,
  successTemplate,
}) {
  const dispatch = useDispatch();
  const itemLocation = useItemLocation(warehouseId, itemId);
  const { t } = useTranslation("common");
  const pendingChangeAlertDismissed = useSelector(
    selectIsPendingChangeAlertDismissed(itemId)
  );
  const [dismissedChangeError, setDismissedChangeError] = useState(false);

  if (!itemLocation) return null;

  if (itemLocation.state === "error") {
    if (itemLocation.reason === "not-found") {
      return <>{t("inventoryLocation.itemLocationNotFound")}</>;
    }
    return (
      <>
        {t("inventoryLocation.itemLocationError")}{" "}
        <LinkButton
          onClick={() =>
            dispatch(itemLocationRequired({ warehouseId, itemId }))
          }
        >
          {t("tryAgain")}
        </LinkButton>
      </>
    );
  }

  if (itemLocation.state === "loading") {
    return <>{t("inventoryLocation.loadingLocation")}</>;
  }

  let alert: JSX.Element | undefined;

  if (!dismissedChangeError) {
    if (itemLocation.changeError) {
      const { latestErrorCode, fromLocationId, fromPickStationId } =
        itemLocation.changeError;
      let details;
      if (latestErrorCode === "MovementOnCustomerOrder") {
        details = t("inventoryLocation.movementOnCustomerOrder");
      } else if (latestErrorCode === "MovementOnInventoryAudit") {
        details = t("inventoryLocation.movementOnInventoryAudit");
      }
      alert = (
        <Alert
          data-cy="ItemLocation-changeErrorAlert"
          className="basis-full mt-1"
          severity="error"
          icon={CircleExclamationIcon}
          onDismiss={() => setDismissedChangeError(true)}
        >
          <Typography variant="body-sm">
            {t("inventoryLocation.locationChangeFailed", {
              fromLocationId,
              fromPickStationId,
              toLocationId: itemLocation.locationId,
              toPickStationId: itemLocation.pickStationId,
            })}
            {details && ` ${details}`}
          </Typography>
        </Alert>
      );
    } else if (
      !itemLocation.isUpdatedInVivaldi &&
      includePendingChange &&
      !pendingChangeAlertDismissed
    ) {
      alert = (
        <Alert
          data-cy="ItemLocationPendingChangeAlert"
          className="basis-full mt-1"
          severity="info"
          icon={InfoIcon}
          onDismiss={() => dispatch(dismissPendingChangeAlert(itemId))}
        >
          <Typography variant="body-sm">
            {t("inventoryLocation.locationChangeIsPending")}
          </Typography>
        </Alert>
      );
    }
  }

  const success = successTemplate ? (
    <ItemLocationContext.Provider
      value={`${itemLocation.locationId} (${itemLocation.pickStationId})`}
    >
      {successTemplate}
    </ItemLocationContext.Provider>
  ) : (
    <div data-cy="item-location">
      {t(
        includeCurrentPrefix
          ? "inventoryLocation.currentInventoryLocationColon"
          : "inventoryLocation.inventoryLocationColon",
        {
          pickStationId: itemLocation.pickStationId,
          locationId: itemLocation.locationId,
        }
      )}
    </div>
  );

  return (
    <>
      {success}
      {alert}
    </>
  );
};

export default ItemLocation;
