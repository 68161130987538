import {
  GetItemErrorReason,
  Item,
} from "@ahlsell-group/store20-product-service";
import { OperationFailedError } from "@ahlsell-group/store20-service-core";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import ActionType from "../../../util/ActionType";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import getItemIdByBarcode from "../../item-information/sagas/getItemIdByBarcode";
import { selectInventoryLocationItemHasBeenAdded } from "../inventoryLocationSelectors";
import {
  itemAdded,
  itemLoadError,
  itemReceived,
  itemRequired,
  itemScanned,
  setNotification,
} from "../inventoryLocationSlice";

export default function* loadInventoryItemSaga(
  { itemService, itemSearchService, handleSagaError }: ServiceContainer,
  action: ActionType<typeof itemScanned> | ActionType<typeof itemRequired>
): Saga {
  let itemId = "";
  try {
    if ("barcode" in action.payload) {
      const foundItemId: string | undefined = yield getItemIdByBarcode(
        action.payload.barcode,
        itemSearchService
      );
      if (!foundItemId) {
        throw new OperationFailedError<GetItemErrorReason>("not-found");
      }

      itemId = foundItemId;
    } else {
      itemId = action.payload.itemId;
    }

    switch (action.type) {
      case itemRequired.type: {
        const item: Item = yield call([itemService, "getItem"], itemId);
        yield put(
          itemReceived({
            itemId: item.id,
            name: item.name,
            description1: item.partDescription1,
            description2: item.partDescription2,
            assetUrl: item.assetUrl,
          })
        );
        break;
      }
      case itemScanned.type: {
        let item: Item | undefined;
        let hasExistingItem: boolean = yield select(
          selectInventoryLocationItemHasBeenAdded,
          itemId
        );

        if (!hasExistingItem) {
          item = yield call([itemService, "getItem"], itemId);
        }

        // There could be two calls to `itemService.getItem()` in parallel. If
        // the item has been added by the other call, this one is a duplicate.
        hasExistingItem = yield select(
          selectInventoryLocationItemHasBeenAdded,
          itemId
        );

        if (!hasExistingItem) {
          if (!item) {
            throw new Error("Item expected");
          }

          yield put(
            itemAdded({
              itemId: item.id,
              name: item.name,
              description1: item.partDescription1,
              description2: item.partDescription2,
              assetUrl: item.assetUrl,
            })
          );
        } else {
          yield put(
            setNotification({ severity: "info", type: "already-in-list" })
          );
        }
        break;
      }
      default:
        break;
    }
  } catch (e) {
    switch (action.type) {
      case itemRequired.type:
        yield call(
          handleSagaError,
          (errorCode) =>
            `saga:inventory-location:loadInventoryItemSaga:${errorCode}`,
          e,
          put(
            itemLoadError({
              itemId,
              error: toErrorActionPayload<GetItemErrorReason>(e),
            })
          )
        );
        break;
      case itemScanned.type: {
        const notificationType =
          e instanceof OperationFailedError && e.type === "not-found"
            ? "not-found"
            : "unknown";
        yield call(
          handleSagaError,
          (errorCode) =>
            `saga:inventory-location:loadInventoryItemSaga:${errorCode}`,
          e,
          put(setNotification({ severity: "warning", type: notificationType }))
        );
        break;
      }
      default:
        break;
    }
  }
}
