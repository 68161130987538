import { useTranslation } from "@ahlsell-group/app-localization";
import React from "react";

import { AppLoadingPage } from "../../page/components/AppLoadingPage";

interface ManualStockTakingItemLoadingProps {
  onClose: () => void;
}

const ManualStockTakingItemLoading: React.FC<ManualStockTakingItemLoadingProps> =
  function ({ onClose }) {
    const { t } = useTranslation("common");
    return (
      <AppLoadingPage onCancel={onClose}>
        {t("stockTaking.loadingItem")}
      </AppLoadingPage>
    );
  };

export default ManualStockTakingItemLoading;
