import { useTranslation } from "@ahlsell-group/app-localization";
import {
  ArrowRightIcon,
  SearchIcon,
} from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Button } from "@ahlsell-group/ui-kit/inputs";
import React, { useCallback } from "react";

import BarcodeScannerModal from "../../barcode-scanner/components/BarcodeScannerModal";
import { OnBarcodesScannedHandler } from "../../barcode-scanner/types";
import ItemSearch from "../../item-search/components/ItemSearch";
import RouteLink from "../../routing/components/RouteLink";
import View from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";

const ManualStockTakingScan: React.FC = function () {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const handleScanNew: OnBarcodesScannedHandler = useCallback(
    async (scanResult) => {
      await navigate.back();
      navigate(routes.stockTaking.manual.item.barcode, {
        itemId: scanResult[0].data,
      });
    },
    [navigate]
  );
  const handleSearchSelect = async (itemId: string) => {
    await navigate.back(2);
    navigate(routes.stockTaking.manual.item, {
      itemId,
      autofocus: null,
      continueTo: "scanner",
    });
  };

  return (
    <>
      <View
        route={routes.stockTaking.manual.scan}
        renderMode="mount"
        element={
          <BarcodeScannerModal
            onBarcodesScanned={handleScanNew}
            leftActionContent={
              <Typography variant="heading-h6">
                <RouteLink
                  variant="inline"
                  route={routes.stockTaking.manual.itemSearch}
                  routeParams={{
                    mode: "id-only",
                  }}
                  icon={SearchIcon}
                  iconProps={{ size: "small" }}
                  className="text-theme-primary-light"
                  data-cy="scanner-item-search"
                >
                  {t("barcodeScanning.searchItem")}
                </RouteLink>
              </Typography>
            }
            rightActionContent={
              <Button
                variant="secondary"
                size="small"
                onClick={() => navigate.back()}
                className="w-full"
                icon={ArrowRightIcon}
                iconPlacement="end"
                data-cy="scanner-done"
              >
                {t("done")}
              </Button>
            }
            onError={(error) => {
              navigate(
                routes.stockTaking.manual.itemSearch,
                { mode: "id-only", cameraError: error },
                { replace: true }
              );
            }}
          />
        }
        exact
      />

      <ItemSearch
        routes={routes.stockTaking.manual}
        onSelect={handleSearchSelect}
      />
    </>
  );
};

export default ManualStockTakingScan;
