import { AnyAction } from "redux";
import { actionTypes } from "redux-router5";
import { Route } from "router5";

import { TransitionSuccessAction } from "./types";

const mapObject = <TIn, TOut>(
  object: Record<string, TIn>,
  callbackFn: (value: TIn, key: string) => TOut
): Record<string, TOut> =>
  Object.fromEntries(
    Object.entries(object).map(([key, value]) => [key, callbackFn(value, key)])
  );

export interface AppRoute {
  path: string;
  route: string;
}

type AppRouteConfig = Omit<Route, "children" | "name"> & {
  children?: Record<string, AppRouteConfig>;
  name?: never;
};

type CombineRoutes<
  TBefore extends string | undefined,
  TAfter
> = "" extends TAfter
  ? "<unknown>"
  : TAfter extends string
  ? TBefore extends string
    ? `${TBefore}.${TAfter}`
    : TAfter
  : never;
const combineRoutes = (before: string | undefined, after: string) =>
  before ? `${before}.${after}` : after;

type RemoveQueryString<T> = T extends `${infer TFirstPart}?${string}`
  ? TFirstPart
  : T;
const removeQueryString = (url: string) => url.split("?")[0];

type CombinePaths<
  TBefore extends string | undefined,
  TAfter
> = "" extends TAfter
  ? "<unknown>"
  : TAfter extends string
  ? TBefore extends undefined | "/"
    ? RemoveQueryString<TAfter>
    : `${RemoveQueryString<TBefore>}${RemoveQueryString<TAfter>}`
  : never;
/**
 * Removes any query strings and combines the paths. Query strings are only
 * needed for Router5, not for our own components/hooks.
 */
const combinePaths = (before: string | undefined, after: string) =>
  !before || before === "/"
    ? removeQueryString(after)
    : `${removeQueryString(before)}${removeQueryString(after)}`;

type NoConstStrings<T> = T extends string
  ? string
  : T extends Record<string, unknown>
  ? {
      [P in keyof T]: NoConstStrings<T[P]>;
    }
  : never;

export type GetRouteSubTree<T extends Record<string, AppRouteConfig>> =
  NoConstStrings<ToAppRoutes<T, undefined, undefined>>;

type ToAppRoutes<
  T extends Record<string, AppRouteConfig> | undefined,
  TParentRoute extends string | undefined,
  TParentPath extends string | undefined
> = (TParentRoute extends string
  ? {
      path: TParentPath;
      route: TParentRoute;
    }
  : {}) &
  (T extends Record<string, AppRouteConfig>
    ? {
        [P in keyof T]: ToAppRoutes<
          T[P]["children"],
          CombineRoutes<TParentRoute, P>,
          CombinePaths<TParentPath, T[P]["path"]>
        >;
      }
    : {});

const toAppRoutes = (
  routes: Record<string, AppRouteConfig> | undefined,
  parentRoute?: string,
  parentPath?: string
): ToAppRoutes<any, any, any> => ({
  ...(parentRoute
    ? {
        path: parentPath,
        route: parentRoute,
      }
    : {}),
  ...(routes
    ? mapObject(routes, (value, key) =>
        toAppRoutes(
          value.children,
          combineRoutes(parentRoute, key),
          combinePaths(parentPath, value.path)
        )
      )
    : {}),
});

/**
 * Helper function to ensure the correct type of the given routes. Be sure to use `as const`.
 */
export const createRoutes = <T extends Record<string, AppRouteConfig>>(
  routes: T
): T => routes;

/**
 * Create a nested object of all routes and paths. Used for ease of refactoring routes.
 */
export const createAppRoutes = <T extends Record<string, AppRouteConfig>>(
  routes: T
): ToAppRoutes<T, undefined, undefined> => toAppRoutes(routes) as any;

/**
 * Transform the routes for use with Router5.
 */
export const createRouter5Routes = <T extends Record<string, AppRouteConfig>>(
  routes: T
): Route[] =>
  Object.entries(routes).map(([key, value]) => ({
    name: key,
    ...value,
    children: value.children ? createRouter5Routes(value.children) : undefined,
  }));

export const isTransitionSuccessAction = (
  action: AnyAction
): action is TransitionSuccessAction =>
  action.type === actionTypes.TRANSITION_SUCCESS;
