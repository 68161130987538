import { AuthenticationStatus } from "@ahlsell-group/store20-authentication-service";
import { call, put } from "redux-saga/effects";

import { userLoginFailed, userLoginSucceeded } from "../../../app/appSlice";
import { stepCompleted } from "../gettingStartedSlice";
import { Step } from "../types";

const loginConfirmationStep: Step<"loginConfirmation"> = {
  type: "loginConfirmation",
  *preStepSaga({ authenticationService, appConfigService, configManager }) {
    const loginStatus: AuthenticationStatus = yield call([
      authenticationService,
      "ensureLoggedIn",
    ]);
    if (loginStatus.status === "error") {
      yield put(userLoginFailed(loginStatus.errorType));
      return "input-required";
    }

    yield call(
      [appConfigService, "setConfig"],
      "ace_last_used_version",
      configManager.getConfig().version
    );
    yield put(
      userLoginSucceeded({
        email: loginStatus.user.email,
        legalEntityId: loginStatus.user.legalEntityId,
        vivaldiEmployeeNumber: loginStatus.user.employeeId,
        warehouseId: loginStatus.user.warehouseId,
        name: loginStatus.user.name,
      })
    );
    return stepCompleted({ type: "loginConfirmation", hasLoggedIn: true });
  },
  *postStepSaga({ reduxPersistenceService }) {
    yield call([reduxPersistenceService, "remove"], "login-instructions");
  },
};

export default loginConfirmationStep;
