import { useTranslation } from "@ahlsell-group/app-localization";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CrossIcon,
} from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { IconButton, Switch } from "@ahlsell-group/ui-kit/inputs";
import { backgroundContext, useZIndex } from "@ahlsell-group/ui-kit/util";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectScanningMode } from "../barcodeScannerSelectors";
import { scanningModeChanged } from "../barcodeScannerSlice";
import { Barcode, CameraError, OnBarcodesScannedHandler } from "../types";

import ScannerViewport from "./ScannerViewport";

export interface BarcodeScannerPageProps {
  leftActionContent?: React.ReactNode;
  rightActionContent?: React.ReactNode;
  extraContent?: React.ReactNode;
  cameraOverlayContent?: React.ReactNode;
  onBarcodesScanned?: OnBarcodesScannedHandler;
  onClose?: () => void;
  onError?: (e?: CameraError) => void;
}

const BarcodeScannerPage: React.FC<BarcodeScannerPageProps> =
  function BarcodeScannerPage({
    onBarcodesScanned,
    onClose,
    onError,
    extraContent = null,
    leftActionContent = null,
    rightActionContent = null,
    cameraOverlayContent = null,
  }) {
    const dispatch = useDispatch();
    const { t } = useTranslation("common");
    const wasClick = useRef(false);
    const downTimestamp = useRef(0);
    const [cameraOn] = useState(true);
    const [scannerOn, setScannerOn] = useState(false);
    const [scanButtonDown, setScanButtonDown] = useState(false);
    const [isOnBecauseOfClick, setIsOnBecauseOfClick] = useState(false);
    const scanningMode = useSelector(selectScanningMode);
    const [scanningModeSelectorOpen, setScanningModeSelectorOpen] =
      useState(false);
    const [zIndex] = useZIndex(scanningModeSelectorOpen);
    const scanningModeButtonRef = useRef<HTMLButtonElement>(null);
    const scanningModeMenuRef = useRef<HTMLDivElement>(null);

    const onScanDown = () => {
      if (scannerOn) {
        // If the scanner is on, then it's been started via a click
        return;
      }
      downTimestamp.current = Date.now();
      setScanButtonDown(true);
      setScannerOn(true);
    };

    const onScanOut = () => {
      setScanButtonDown(false);
      setTimeout(() => {
        // Up/Out triggers before click, but click doesn't trigger if the button is held down for long enough
        // Queue this handler, so that the click handler can run and set the wasClick ref
        if (wasClick.current) {
          wasClick.current = false;
        } else {
          setScannerOn(false);
        }
      }, 50);
    };

    const onScanClick = () => {
      // On some platforms, a down and up event on an element always result in a click
      // Ensure the down event was within a short period of the click to not handle all ups as clicks
      if (downTimestamp.current > Date.now() - 400) {
        wasClick.current = true;
      }
      if (scannerOn && isOnBecauseOfClick) {
        setScannerOn(false);
        setIsOnBecauseOfClick(false);
      } else {
        setIsOnBecauseOfClick(true);
      }
    };

    const onScan = (barcodes: Barcode[]) => {
      onBarcodesScanned?.(barcodes);
      if (!scanButtonDown && scanningMode === "manual") {
        setScannerOn(false);
      }
    };

    useEffect(() => {
      if (scanningMode === "continuous") {
        setScannerOn(true);
      } else {
        setScannerOn(false);
      }
    }, [scanningMode]);

    const onScanningModeChange = (manual: boolean) => {
      dispatch(scanningModeChanged(manual ? "manual" : "continuous"));
    };

    const toggleScanningModeSelector = () => {
      setScanningModeSelectorOpen(!scanningModeSelectorOpen);
    };

    useEffect(() => {
      if (!scanningModeSelectorOpen) return undefined;

      const eventListener = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          setScanningModeSelectorOpen(false);
        }
      };

      window.addEventListener("keydown", eventListener);

      return () => {
        window.removeEventListener("keydown", eventListener);
      };
    }, [scanningModeSelectorOpen]);

    useEffect(() => {
      const listener = (e: MouseEvent): void => {
        if (e.target) {
          const clickedOutside =
            !scanningModeButtonRef.current?.contains(e.target as Node) &&
            !scanningModeMenuRef.current?.contains(e.target as Node);
          if (clickedOutside && scanningModeSelectorOpen)
            setScanningModeSelectorOpen(false);
        }
      };
      document.addEventListener("click", listener);
      return () => document.removeEventListener("click", listener);
    }, [scanningModeSelectorOpen]);

    return (
      <div
        className="grid grid-rows-[5rem_1fr_7rem_7rem] grid-cols-3 flex-1 p-safe"
        data-cy="BarcodeScanner"
      >
        <backgroundContext.Provider value="white">
          <div className="bg-theme-primary-surface-dark" />
          <div className="bg-theme-primary-surface-dark flex justify-center p-2">
            <div className="flex flex-col self-end items-center relative">
              <Typography variant="heading-h6" color="white">
                {t("barcodeScanner.scanningMode")}
              </Typography>
              <IconButton
                ref={scanningModeButtonRef}
                style={scanningModeSelectorOpen ? { zIndex } : undefined}
                icon={
                  scanningModeSelectorOpen ? ChevronUpIcon : ChevronDownIcon
                }
                className="mt-2"
                rounded="full"
                size="small"
                variant="tertiary"
                onClick={toggleScanningModeSelector}
              />
              {scanningModeSelectorOpen && (
                <>
                  <div
                    ref={scanningModeMenuRef}
                    className="absolute top-[100%] bg-theme-primary-surface-main py-4 px-8 z-50 rounded-b-lg flex flex-row justify-evenly items-center"
                  >
                    <Typography
                      variant="heading-h6"
                      color="white"
                      className="mr-4"
                    >
                      {t("barcodeScanner.manual")}
                    </Typography>
                    <Switch
                      checked={scanningMode === "manual"}
                      onChange={(e) => onScanningModeChange(e.target.checked)}
                    />
                  </div>
                  {/* Disables clicks on close/continue-buttons when scanning mode menu is open */}
                  <div className="h-full w-full fixed" />
                </>
              )}
            </div>
          </div>
          <div className="bg-theme-primary-surface-dark flex justify-end items-center px-4">
            <IconButton
              variant="tertiary"
              rounded="full"
              size="medium"
              icon={CrossIcon}
              onClick={onClose}
              data-cy="BarcodeScannerPage-close"
            />
          </div>
          <div className="grid grid-cols-[1rem_1fr_1rem] col-span-full">
            <div className="bg-theme-primary-surface-dark" />
            <div
              className={classNames(
                "relative rounded border-2 border-solid overflow-hidden h-full shadow-[0_0_0_0.25rem] shadow-theme-primary-surface-dark",
                {
                  "shadow-[0px_0px_6px_6px_#4ABBFD] border-theme-primary-main":
                    scannerOn,
                  "border-white": !scannerOn,
                }
              )}
            >
              <ScannerViewport
                cameraOn={cameraOn}
                scannerOn={scannerOn}
                onBarcodeScanned={onScan}
                onError={onError}
                initializationClassName="bg-theme-primary-surface-dark"
              />
              {cameraOverlayContent ? (
                <div className="absolute px-1 pb-1 flex items-center w-full bottom-0">
                  {cameraOverlayContent}
                </div>
              ) : null}
            </div>
            <div className="bg-theme-primary-surface-dark" />
          </div>

          <div className="bg-theme-primary-surface-dark col-span-3 px-4 py-2">
            {/* Disables clicks on extraContent when scanning mode menu is open */}
            {scanningModeSelectorOpen && (
              <div className="h-full w-full fixed" style={{ zIndex }} />
            )}
            {extraContent}
          </div>
          <div className="bg-theme-primary-surface-dark pl-4 flex justify-center items-center">
            {leftActionContent}
          </div>
          <div className="bg-theme-primary-surface-dark flex justify-center items-center">
            <button
              aria-label="Scan"
              type="button"
              // We only want to support touch start/end here, not mouse down/up. Only click is supported when using a mouse.
              onTouchStart={onScanDown}
              onTouchEnd={onScanOut}
              onClick={onScanClick}
              onSelect={(e) => e.preventDefault()}
              onContextMenu={(e) => e.preventDefault()}
              className={classNames(
                "rounded-full w-14 h-14 bg-theme-primary-main ring-[6px] ring-offset-[6px] ring-offset-theme-primary-surface-dark ring-theme-primary-main",
                {
                  "shadow-[0px_0px_21px_24px_#4ABBFD]": scannerOn,
                  hidden: scanningMode === "continuous",
                }
              )}
              style={{
                WebkitUserSelect: "none",
                WebkitTouchCallout: "none",
              }}
            />
          </div>
          <div className="bg-theme-primary-surface-dark flex items-center pr-4">
            {rightActionContent}
          </div>
        </backgroundContext.Provider>
      </div>
    );
  };

export default BarcodeScannerPage;
