import { PurchaseHoldInfo } from "@ahlsell-group/store20-product-service";
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import {
  purchaseHoldLoaded,
  purchaseHoldLoadFailed,
  PurchaseHoldRequiredPayload,
} from "../purchaseHoldSlice";

export default function* loadPurchaseHoldSaga(
  { itemService, handleSagaError }: ServiceContainer,
  { payload: { itemId, stockId } }: PayloadAction<PurchaseHoldRequiredPayload>
) {
  try {
    const result: PurchaseHoldInfo = yield call(
      [itemService, "getPurchaseHold"],
      itemId,
      stockId
    );
    yield put(
      purchaseHoldLoaded({
        ...result,
        itemId,
        stockId,
        loadedAt: new Date().toISOString(),
      })
    );
  } catch (e) {
    yield call(
      handleSagaError,
      (errorCode) =>
        `saga:inventory-location:loadItemLocationsSaga:${errorCode}`,
      e,
      put(purchaseHoldLoadFailed(toErrorActionPayload(e, { itemId, stockId })))
    );
  }
}
