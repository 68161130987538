import { Warehouse } from "@ahlsell-group/store20-store-service";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import { memoAndUpdateSaga } from "../../../util/sagaHelpers/memoAndUpdateSaga";
import { selectWarehouseId } from "../warehouseSelectors";
import { userWarehouseInfoUpdated } from "../warehouseSlice";

const stockCacheKey = "myStock";

/** This type is stored in LocalStorage and must be backwards compatible. */
interface CachedStock {
  stockId: number;
  name: string;
  coordinates?: {
    latitude: number;
    longitude: number;
  };
}

function* loadUserWarehouseInfoSaga({
  appTelemetryService,
  handleSagaError,
  storageService,
  storeService,
}: ServiceContainer): Saga {
  const stockId: number = yield select(selectWarehouseId);

  yield* memoAndUpdateSaga<CachedStock>({
    cacheKey: stockCacheKey,
    storageService,
    canUseValue: (x) => x?.stockId === stockId,

    *getValueSaga() {
      const stock: Warehouse = yield call(
        [storeService, "getWarehouseById"],
        stockId
      );

      const lat = Number.parseFloat(stock.geoLat ?? "");
      const long = Number.parseFloat(stock.geoLong ?? "");

      const coordinates: CachedStock["coordinates"] | undefined = Number.isNaN(
        lat + long
      )
        ? undefined
        : {
            latitude: lat,
            longitude: long,
          };

      return { stockId, name: stock.storeName, coordinates };
    },

    *onValueSaga(value) {
      yield put(
        userWarehouseInfoUpdated({
          name: value.name,
          coordinates: value.coordinates,
        })
      );

      yield call([appTelemetryService, "updateSessionInfo"], {
        warehouseName: value.name,
      });
    },

    *onErrorSaga(error) {
      yield* handleSagaError(
        (errorCode) => `saga:warehouse:loadUserWarehouseInfoSaga:${errorCode}`,
        error
      );
    },
  });
}

export default loadUserWarehouseInfoSaga;
