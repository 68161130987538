import { ItemStock } from "@ahlsell-group/store20-inventory-service";
import { call, put } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import ActionType from "../../../util/ActionType";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import {
  stockBalanceLoadFailed,
  stockBalanceReceived,
  stockBalanceRequired,
} from "../itemStockSlice";

export default function* loadItemStockSaga(
  { inventoryService, handleSagaError }: ServiceContainer,
  { payload: { warehouseId, itemId } }: ActionType<typeof stockBalanceRequired>
): Saga {
  try {
    const { unit, stockBalanceAvailableNow, notAvailableState }: ItemStock =
      yield call([inventoryService, "getStock"], warehouseId, itemId);

    yield put(
      stockBalanceReceived({
        warehouseId,
        itemId,
        stockBalance: stockBalanceAvailableNow,
        unit,
        notAvailableState,
        updatedAt: new Date().toISOString(),
      })
    );
  } catch (e) {
    yield call(
      handleSagaError,
      (errorCode) => `saga:item-stock:loadItemStock:${errorCode}`,
      e,
      put(
        stockBalanceLoadFailed(
          toErrorActionPayload(e, {
            warehouseId,
            itemId,
          })
        )
      )
    );
  }
}
