import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";

declare global {
  interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
      outcome: "accepted" | "dismissed";
      platform: string;
    }>;
    prompt(): Promise<void>;
  }
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

export interface EmptyInstallModeState {
  state: "empty";
  installPromptResult?: Event;
}

export type Platform = "ios" | "android" | "unknown";
export type Browser = "safari" | "chrome" | "other";

export interface LoadedInstallModeState {
  state: "loaded";
  platform: Platform;
  browser: Browser;
  isInPwaMode: boolean;
  installPromptResult?: Event;
}

export type InstallModeState = EmptyInstallModeState | LoadedInstallModeState;

const name = "installMode";

export const installDataRequired = createAction(`${name}/dataRequired`);

const initialState = {
  state: "empty",
  installPromptResult: undefined,
} satisfies InstallModeState as InstallModeState;

const slice = createSlice({
  name,
  initialState,
  reducers: {
    installDataLoaded(
      state,
      {
        payload,
      }: PayloadAction<{
        platform: Platform;
        browser: Browser;
        isInPwaMode: boolean;
      }>
    ) {
      return {
        ...state,
        state: "loaded",
        platform: payload.platform,
        browser: payload.browser,
        isInPwaMode: payload.isInPwaMode,
      };
    },
    setInstallPrompt(state, { payload }: PayloadAction<Event | undefined>) {
      state.installPromptResult = payload;
    },
    triggerInstallPrompt(state) {
      if (
        state.installPromptResult &&
        typeof state.installPromptResult === "object"
      ) {
        (state.installPromptResult as BeforeInstallPromptEvent).prompt();
      }
    },
  },
});

export const {
  reducer: installModeReducer,
  actions: { installDataLoaded, setInstallPrompt, triggerInstallPrompt },
} = slice;
