import { call } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";

export default function* clearUserAndReloadSaga({
  authenticationService,
}: ServiceContainer) {
  yield call([authenticationService, "clearSession"]);
  window.location.reload();
}
