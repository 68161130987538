import {
  AllErrorCategories,
  Trans,
  useErrorMessage,
  useTranslation,
} from "@ahlsell-group/app-localization";
import {
  Modal,
  ModalAction,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@ahlsell-group/ui-kit/feedback";
import { HtmlMetaThemeColorValue } from "@ahlsell-group/ui-kit/util";
import React from "react";

import { ErrorPageSectionProps } from "./ErrorPageSection";

export interface ErrorModalProps<TCategory extends AllErrorCategories>
  extends ErrorPageSectionProps<TCategory> {
  onClose?: () => void;
  modalActions?: React.ReactNode;
  overrideHtmlMetaThemeColor?: HtmlMetaThemeColorValue;
}

const ErrorModal = function <TCategory extends AllErrorCategories>({
  category,
  error,
  fallbackErrorKey,
  onClose,
  extraContent,
  modalActions,
  overrideHtmlMetaThemeColor,
  ...rest
}: ErrorModalProps<TCategory>) {
  const { t } = useTranslation("common");
  const errorMessage = useErrorMessage(
    category,
    error?.reason,
    fallbackErrorKey
  );

  if (!error || !errorMessage) return null;

  return (
    <Modal
      open
      onCancel={onClose}
      variant="card"
      overrideHtmlMetaThemeColor={overrideHtmlMetaThemeColor}
    >
      <ModalHeader {...rest}>
        <Trans t={t} i18nKey={errorMessage.headerKey} tOptions={error.data} />
      </ModalHeader>
      <ModalContent>
        <div>
          <Trans t={t} i18nKey={errorMessage.detailKey} tOptions={error.data} />
        </div>
        {extraContent && <div className="mt-2">{extraContent}</div>}
      </ModalContent>
      {modalActions ??
        (onClose && (
          <ModalActions>
            <ModalAction
              mode="button"
              onClick={onClose}
              data-cy="ErrorModal-ok"
            >
              {t("ok")}
            </ModalAction>
          </ModalActions>
        ))}
    </Modal>
  );
};

export default ErrorModal;
