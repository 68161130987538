import { actionTypes } from "redux-router5";
import { call, spawn, take } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";

import { backgroundUpdateSaga } from "./backgroundUpdateSaga";

export default function* startBackgroundUpdateLoopSaga(
  serviceContainer: ServiceContainer
) {
  yield spawn(function* updateConfigOnTransitionSuccess() {
    while (true) {
      yield take(actionTypes.TRANSITION_SUCCESS);
      yield call(backgroundUpdateSaga, serviceContainer, "*");
    }
  });
}
