import {
  UpdatedInventoryLocationItem,
  InventoryLocationItemInfo,
} from "@ahlsell-group/store20-inventory-location-service";
import { actions } from "redux-router5";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import routes from "../../routing/routes";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import {
  selectInventoryLocationItems,
  selectLocationId,
  selectPickStation,
} from "../inventoryLocationSelectors";
import { submissionsReceived, submitError } from "../inventoryLocationSlice";
import { isSuccessItemStatus } from "../util";

export default function* submitInventoryItemsSaga({
  handleSagaError,
  inventoryLocationService,
}: ServiceContainer): Saga {
  try {
    const warehouseId: number = yield select(selectWarehouseId);
    const items: InventoryLocationItemInfo[] = yield select(
      selectInventoryLocationItems
    );
    const pickStation: string = yield select(selectPickStation);
    const locationId: string = yield select(selectLocationId);
    const itemIds = items.map((x) => x.itemId);

    const response: UpdatedInventoryLocationItem[] = yield call(
      [inventoryLocationService, "updateInventoryLocation"],
      itemIds,
      pickStation,
      locationId,
      warehouseId
    );

    yield put(submissionsReceived(response));

    const allItemsSucceeded = response.every((itemResult) =>
      isSuccessItemStatus(itemResult.status)
    );

    if (allItemsSucceeded) {
      yield put(
        actions.navigateTo(
          routes.inventoryLocation.change.submitted.route,
          {},
          { replace: true }
        )
      );
    } else {
      yield put(
        actions.navigateTo(
          routes.inventoryLocation.change.submitted.route,
          {},
          { replace: true }
        )
      );
      yield put(
        actions.navigateTo(routes.inventoryLocation.change.submitted.route, {
          modal: "confirmation",
        })
      );
    }
  } catch (e) {
    yield call(
      handleSagaError,
      (errorCode) =>
        `saga:inventory-location:submitInventoryItemsSaga:${errorCode}`,
      e,
      put(submitError(toErrorActionPayload(e)))
    );
  }
}
