import { takeEvery } from "redux-saga/effects";

import { ServiceContainer } from "../../app/serviceContainer";

import { installDataRequired } from "./installModeSlice";
import loadInstallModeData from "./sagas/loadInstallModeData";

export default function* installModeSagas(serviceContainer: ServiceContainer) {
  yield takeEvery(installDataRequired, loadInstallModeData, serviceContainer);
}
