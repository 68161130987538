import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface StepPayloadMap {
  welcome: {
    type: "welcome";
  };
  languageSelection: {
    type: "languageSelection";
    selectedLanguage: string;
  };
  privacyPolicy: {
    type: "privacyPolicy";
    acceptedAt: string;
  };
  installationPrompt: {
    type: "installationPrompt";
    hasDismissed: boolean;
  };
  loginInstructions: {
    type: "loginInstructions";
    hasViewedLoginInstructions: boolean;
    clearError?: boolean;
  };
  loginConfirmation: {
    type: "loginConfirmation";
    hasLoggedIn: boolean;
  };
}

export type StepType = keyof StepPayloadMap;
export type StepPayload = StepPayloadMap[StepType];

export interface GettingStartedState {
  lastCompletedStep: "none" | StepType;
  stepsWithInputRequired: StepType[];
  steps: Partial<StepPayloadMap>;
}

const initialState: GettingStartedState = {
  lastCompletedStep: "none",
  stepsWithInputRequired: [],
  steps: {},
};

const slice = createSlice({
  name: "gettingStarted",
  initialState,
  reducers: {
    stepCompleted: (state, action: PayloadAction<StepPayload>) => {
      const { type } = action.payload;
      state.lastCompletedStep = type;
      // @ts-expect-error TypeScript cannot handle this.
      state.steps[type] = action.payload;
    },
    updateStepsWithInputRequired: (
      state,
      action: PayloadAction<StepType[]>
    ) => {
      state.stepsWithInputRequired = action.payload;
    },
  },
});

export const {
  reducer: gettingStartedReducer,
  actions: { stepCompleted, updateStepsWithInputRequired },
} = slice;
