import { useCallback, useEffect, useLayoutEffect, useState } from "react";

import useServiceContainer from "../../app/components/ServiceContainerContext";

import ScanningContextService, {
  OnScanListener,
  ScanningContextState,
} from "./ScanningContextService";
import { CameraError } from "./types";

export type BarcodeScannerHook = {
  scanningState: Pick<ScanningContextState, "state">;
  scannerViewRef: (element: HTMLElement | null) => void;
  error?: CameraError;
};

export type ScannerState =
  | {
      camera: false;
    }
  | {
      camera: true;
      scanner: boolean;
    };

let desiredStateQueue = Promise.resolve();

async function applyDesiredState(
  scanningContextService: ScanningContextService,
  desiredState: ScannerState
) {
  desiredStateQueue = desiredStateQueue.then(async () => {
    if (desiredState.camera) {
      await scanningContextService.startCamera();
      if (desiredState.scanner) {
        await scanningContextService.startScanning();
      } else {
        await scanningContextService.stopScanning();
      }
    } else {
      await scanningContextService.stopCamera();
    }
  });
  await desiredStateQueue;
}

const useBarcodeScanner = (
  desiredState: ScannerState,
  onScan: OnScanListener
): BarcodeScannerHook => {
  const { scanningContextService } = useServiceContainer();
  if (scanningContextService.state.state === "uninitialized") {
    scanningContextService.initialize();
  }

  const [scanningContextState, setScanningContextState] = useState(
    scanningContextService.state
  );

  useEffect(
    () =>
      scanningContextService.onStateChange((state, previousState) => {
        if (previousState.state === "initializing") {
          applyDesiredState(scanningContextService, desiredState);
        }
        setScanningContextState(state);
      }),
    [scanningContextService, desiredState]
  );

  useLayoutEffect(() => {
    if (
      scanningContextState.state === "ready" ||
      scanningContextState.state === "camera-active"
    ) {
      applyDesiredState(scanningContextService, desiredState);
    }
  }, [scanningContextService, desiredState, scanningContextState]);

  const refCallback = useCallback(
    (element: HTMLElement | null) => {
      if (element === null) {
        scanningContextService.disconnectElement();
      } else {
        scanningContextService.connectElement(element);
      }
    },
    [scanningContextService]
  );

  useEffect(
    () => scanningContextService.onScan(onScan),
    [scanningContextService, onScan]
  );

  useLayoutEffect(
    () => () => {
      scanningContextService.stopCamera();
    },
    [scanningContextService]
  );

  return {
    scanningState: scanningContextState,
    scannerViewRef: refCallback,
    error: scanningContextService.cameraError,
  };
};

export default useBarcodeScanner;
