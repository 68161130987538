import { ItemSearchResultItem as ProductServiceItem } from "@ahlsell-group/store20-product-service";
import { call, put } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import ActionType from "../../../util/ActionType";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import {
  itemSearchFailed,
  itemSearchQueryEntered,
  itemSearchResultReceived,
} from "../itemSearchSlice";
import { ItemSearchResultItem } from "../types";

export default function* itemSearchSaga(
  { itemSearchService, handleSagaError }: ServiceContainer,
  action: ActionType<typeof itemSearchQueryEntered>
): Saga {
  const searchQuery = action.payload.term.trim();
  if (searchQuery === "") return;

  try {
    let searchResult: ProductServiceItem[];
    if (action.payload.searchMode === "id-only") {
      searchResult = yield call(
        [itemSearchService, "suggestByItemId"],
        searchQuery
      );
    } else {
      searchResult = yield call([itemSearchService, "search"], searchQuery, {
        searchMode: action.payload.searchMode,
      });
    }

    const items = searchResult.map<ItemSearchResultItem>((result) => ({
      id: result.id,
      name: result.variantName,
      description: result.partDescription1 ?? "",
      assetUrl: result.assetUrl,
    }));

    yield put(itemSearchResultReceived({ searchQuery, items }));
  } catch (e) {
    yield* handleSagaError(
      "saga:item-search:itemSearchSaga:unknown",
      e,
      put(itemSearchFailed(toErrorActionPayload(e)))
    );
  }
}
