import { useTranslation } from "@ahlsell-group/app-localization";
import { CheckIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import {
  Modal,
  ModalAction,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@ahlsell-group/ui-kit/feedback";
import React from "react";

import { ViewComponentProps } from "../../routing/components/View";
import useNavigate from "../../routing/useNavigate";

const ManualStockTakingSubmitCompleteModal: React.ComponentType<ViewComponentProps> =
  function ({ params: { stockTakeId } }) {
    if (typeof stockTakeId !== "string")
      throw new Error("Missing params.stockTakeId");

    const { t } = useTranslation("common");
    const navigate = useNavigate();

    return (
      <Modal open variant="card">
        <ModalHeader data-cy="ManualStockTakingSubmitCompleteModal">
          {t("stockTaking.submitStockTakeComplete")}
        </ModalHeader>
        <ModalContent>
          <Typography
            variant="heading-h4"
            data-cy="ManualStockTakingSubmitCompleteModal-content"
          >
            #{stockTakeId}
          </Typography>
        </ModalContent>
        <ModalActions>
          <ModalAction
            mode="button"
            variant="primary"
            icon={CheckIcon}
            onClick={() => navigate.back()}
            data-cy="ManualStockTakingSubmitCompleteModal-ok"
          >
            {t("ok")}
          </ModalAction>
        </ModalActions>
      </Modal>
    );
  };

export default ManualStockTakingSubmitCompleteModal;
