import { useTranslation } from "@ahlsell-group/app-localization";
import {
  Modal,
  ModalAction,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@ahlsell-group/ui-kit/feedback";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ViewComponentProps } from "../../routing/components/View";
import useNavigate from "../../routing/useNavigate";
import {
  selectInventoryLocationItems,
  selectLocationId,
  selectPickStation,
} from "../inventoryLocationSelectors";
import { submitInventoryLocationItems } from "../inventoryLocationSlice";

const InventoryLocationConfirmSubmitModal: React.FC<ViewComponentProps> =
  function ({ params }) {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const items = useSelector(selectInventoryLocationItems);
    const pickStation = useSelector(selectPickStation);
    const locationId = useSelector(selectLocationId);

    if (params.modal !== "confirmSubmission") {
      return null;
    }

    return (
      <Modal onCancel={() => navigate.back()} open variant="card">
        <ModalHeader data-cy="InventoryLocationConfirmSubmitModal">
          {t("inventoryLocation.submitRelocationHeader")}
        </ModalHeader>
        <ModalContent>
          {t("inventoryLocation.submitRelocationDetail", {
            count: items.length,
            pickStation,
            locationId,
          })}
        </ModalContent>
        <ModalActions>
          <ModalAction
            mode="button"
            variant="tertiary"
            onClick={() => navigate.back()}
            data-cy="InventoryLocationConfirmSubmitModal-cancel"
          >
            {t("cancel")}
          </ModalAction>
          <ModalAction
            mode="button"
            variant="secondary"
            onClick={() => {
              dispatch(submitInventoryLocationItems());
              navigate.back();
            }}
            data-cy="InventoryLocationConfirmSubmitModal-submit"
          >
            {t("yes")}
          </ModalAction>
        </ModalActions>
      </Modal>
    );
  };

export default InventoryLocationConfirmSubmitModal;
