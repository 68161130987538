import { all, takeEvery, takeLatest } from "redux-saga/effects";

import { appStarted } from "../../app/appSlice";
import { ServiceContainer } from "../../app/serviceContainer";

import {
  itemLocationRequired,
  clearInventoryLocationItems,
  itemAdded,
  itemRequired,
  itemScanned,
  locationIdSelected,
  locationsRequired,
  pickStationSelected,
  removeItem,
  submissionsReceived,
  submitInventoryLocationItems,
} from "./inventoryLocationSlice";
import initializeInventoryLocationChangeSaga from "./sagas/initializeInventoryLocationChangeSaga";
import loadInventoryItemSaga from "./sagas/loadInventoryItemSaga";
import loadItemLocationSaga from "./sagas/loadItemLocationSaga";
import loadLocationsSaga from "./sagas/loadLocationsSaga";
import persistInventoryLocationChangeSaga from "./sagas/persistInventoryLocationChangeSaga";
import submitInventoryItemsSaga from "./sagas/submitInventoryItemsSaga";

export default function* inventoryLocationSagas(
  serviceContainer: ServiceContainer
) {
  yield all([
    takeEvery(locationsRequired.type, loadLocationsSaga, serviceContainer),
    takeEvery(itemScanned.type, loadInventoryItemSaga, serviceContainer),
    takeEvery(
      submitInventoryLocationItems.type,
      submitInventoryItemsSaga,
      serviceContainer
    ),
    takeEvery(itemRequired.type, loadInventoryItemSaga, serviceContainer),
    takeEvery(
      itemLocationRequired.type,
      loadItemLocationSaga,
      serviceContainer
    ),
    takeEvery(
      [
        pickStationSelected.type,
        locationIdSelected.type,
        itemScanned.type,
        itemAdded.type,
        clearInventoryLocationItems.type,
        removeItem.type,
        submissionsReceived.type,
      ],
      persistInventoryLocationChangeSaga,
      serviceContainer
    ),
    takeLatest(
      appStarted.type,
      initializeInventoryLocationChangeSaga,
      serviceContainer
    ),
  ]);
}
