import i18n, { useTranslation } from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { PageSubheadline } from "@ahlsell-group/app-ui/PageSubheadline";
import { CircleExclamationIcon } from "@ahlsell-group/ui-kit-imagery-react/icons";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Alert, Chip } from "@ahlsell-group/ui-kit/feedback";
import { Tab, Tabs } from "@ahlsell-group/ui-kit/navigation";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ErrorModal from "../../error/components/ErrorModal";
import ItemLocation, {
  ItemLocationSlot,
} from "../../inventory-location/components/ItemLocation";
import ItemImage from "../../item-image/components/ItemImage";
import { AppLoadingPage } from "../../page/components/AppLoadingPage";
import { AppPage } from "../../page/components/AppPage";
import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import { selectStockTakenSubmissionState } from "../stockTakenSubmissionSelectors";
import { submissionsRequested } from "../stockTakenSubmissionSlice";

import { StockTakeQuantity } from "./StockTakeQuantity";

type SubmittedStockTakeProps = ViewComponentProps<{
  stockTakeId: string;
  tab: "diff" | "ok";
}>;

export const SubmittedStockTake: React.FC<SubmittedStockTakeProps> = function ({
  params,
}) {
  const dispatch = useDispatch();
  const state = useSelector(selectStockTakenSubmissionState);

  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const currentStockId = useSelector(selectWarehouseId);

  useEffect(() => {
    if (state.type === "idle") {
      dispatch(submissionsRequested());
    }
  }, [dispatch, state.type]);

  if (state.type === "idle") {
    return null;
  }

  if (state.type === "loading") {
    return (
      <AppLoadingPage onCancel={() => navigate.back()}>
        {t("stockTaking.loadingStockTakenSubmissions")}
      </AppLoadingPage>
    );
  }

  if (state.type === "error") {
    return (
      <AppPage>
        <ErrorModal
          category="stockTakenSubmission"
          error={{ reason: state.error }}
          onClose={() => navigate.back()}
        />
      </AppPage>
    );
  }

  const stockTake = state.submissions.find(
    (x) => x.stockTakeId === params.stockTakeId
  );

  if (!stockTake) {
    return (
      <AppPage>
        <ErrorModal
          category="submittedStockTake"
          error={{ reason: "StockTakeNotFound" }}
          onClose={() => navigate.back()}
        />
      </AppPage>
    );
  }

  const { items, vivaldiId, status, slowCompletion } = stockTake;
  const diffCount = items.filter((x) => x.quantityDiff).length;
  const okCount = items.length - diffCount;
  const itemsToShow = items.filter((x) => {
    const type = x.quantityDiff ? "diff" : "ok";
    return type === params.tab;
  });

  let problemMessage: string | undefined;
  if (status === "Hj0505Errored") {
    problemMessage = t("stockTaking.hj0505Errored", { vivaldiId });
  } else if (status === "Hj0506Errored") {
    problemMessage = t("stockTaking.hj0506Errored", { vivaldiId });
  } else if (slowCompletion) {
    problemMessage = t("stockTaking.slowCompletion", { vivaldiId });
  }

  return (
    <AppPage
      headline={
        <PageSubheadline
          onBack={() => navigate.back()}
        >{`#${vivaldiId}`}</PageSubheadline>
      }
      data-cy="SubmittedStockTake"
    >
      <PageSection className="gap-4">
        {problemMessage && (
          <Alert
            icon={CircleExclamationIcon}
            severity="error"
            className="mb-2"
            data-cy="SubmittedStockTake-problemAlert"
          >
            <Typography variant="body-sm">{problemMessage}</Typography>
          </Alert>
        )}
        <Tabs
          value={params.tab}
          onChange={(x) => {
            navigate(
              routes.stockTaking.submitted,
              { stockTakeId: params.stockTakeId, tab: x },
              { replace: true }
            );
          }}
        >
          <Tab value="diff">
            {t("stockTaking.diff")} ({diffCount})
          </Tab>
          <Tab value="ok">
            {t("stockTaking.stockBalanceOk")} ({okCount})
          </Tab>
        </Tabs>

        {itemsToShow.length === 0 && (
          <Typography variant="body-sm" color="gray">
            {t("stockTaking.noItemsUnderTab")}
          </Typography>
        )}
        {itemsToShow.map((item) => (
          <Typography
            key={item.itemId}
            variant="body-sm"
            className="flex flex-col gap-5 rounded p-4 bg-theme-primary-surface-light"
            data-cy={`SubmittedStockTake-${item.itemId}`}
          >
            <div className="flex flex-row items-stretch">
              <ItemImage
                assetUrl={item.imagePath ?? ""}
                alt={item.description2 ?? ""}
                size="small"
              />
              <div className="ml-4 flex-1 flex flex-col justify-between">
                <div className="text-gray">{item.description1}</div>
                <div className="flex items-center">
                  <div className="grow">
                    {t("stockTaking.articleNumberShort")} {item.itemId}
                  </div>
                  <Chip
                    color={item.quantityDiff ? "attention" : "default"}
                    className="ml-2"
                    data-cy={`SubmittedStockTake-stockTakingQuantity-${item.stockTakingQuantity}`}
                  >
                    {Intl.NumberFormat(i18n.language).format(
                      item.stockTakingQuantity ?? 0
                    )}
                    &nbsp;{item.unit}
                  </Chip>
                </div>
              </div>
            </div>
            <div>
              {item.quantityDiff !== undefined && item.quantityDiff !== 0 && (
                <div className="flex gap-4">
                  <span className="basis-0 grow">{t("stockTaking.diff")}</span>
                  <StockTakeQuantity
                    number={item.totalWeightedAverageCostDiff}
                    unit={item.currency}
                    diff
                    warn
                  />
                  <StockTakeQuantity
                    number={item.quantityDiff}
                    unit={item.unit}
                    diff
                    warn
                  />
                </div>
              )}
              <ItemLocation
                warehouseId={currentStockId}
                itemId={item.itemId}
                successTemplate={
                  <div className="flex justify-between">
                    <div>{t("itemLocation")}</div>
                    <div>
                      <ItemLocationSlot />
                    </div>
                  </div>
                }
              />
              {item.comment && (
                <div className="whitespace-pre-wrap">
                  <Typography color="gray" variant="body-sm" component="span">
                    {t("stockTakingReview.commentColon")}
                  </Typography>{" "}
                  {item.comment}
                </div>
              )}
            </div>
          </Typography>
        ))}
      </PageSection>
    </AppPage>
  );
};
