import React from "react";

import View from "../../routing/components/View";
import routes from "../../routing/routes";

import InventoryLocationClearItemsModal from "./InventoryLocationClearItemsModal";
import InventoryLocationConfirmSubmitModal from "./InventoryLocationConfirmSubmitModal";
import InventoryLocationItem from "./InventoryLocationItem";
import InventoryLocationList from "./InventoryLocationList";
import InventoryLocationMenu from "./InventoryLocationMenu";
import InventoryLocationNewItem from "./InventoryLocationNewItem";
import InventoryLocationOverview from "./InventoryLocationOverview";
import InventoryLocationScan from "./InventoryLocationScan";
import InventoryLocationSubmittedRoot from "./InventoryLocationSubmittedRoot";

const InventoryLocationRoot: React.FC = function () {
  return (
    <>
      <View
        component={InventoryLocationMenu}
        route={routes.inventoryLocation}
        exact
      />
      <View
        component={InventoryLocationOverview}
        route={routes.inventoryLocation.change}
        exact
      />
      <View
        component={InventoryLocationScan}
        route={routes.inventoryLocation.change}
      />
      <View
        component={InventoryLocationList}
        route={routes.inventoryLocation.change.items}
        exact
      />
      <View
        component={InventoryLocationItem}
        route={routes.inventoryLocation.change.items.item}
        exact
      />
      <View
        component={InventoryLocationNewItem}
        route={routes.inventoryLocation.change.items.new}
        exact
      />
      <View
        component={InventoryLocationSubmittedRoot}
        route={routes.inventoryLocation.change.submitted}
      />
      <View
        component={InventoryLocationClearItemsModal}
        route={routes.inventoryLocation.change.items}
        exact
      />
      <View
        component={InventoryLocationConfirmSubmitModal}
        route={routes.inventoryLocation.change}
        exact
      />
    </>
  );
};

export default InventoryLocationRoot;
