import { Store } from "redux";

import { State } from "../../types";

export default class StoreAccessor {
  public constructor(private store?: Store<State>) {}

  public setStore(newStore: Store): void {
    this.store = newStore;
  }

  public getStore(): Store<State> {
    if (!this.store) {
      throw new Error(
        "No store available. This cannot be called during app bootstrapping"
      );
    }
    return this.store;
  }
}
