import i18n from "@ahlsell-group/app-localization";

export const getTime = (dateTimeIso: string) =>
  new Intl.DateTimeFormat(i18n.language, {
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(dateTimeIso));

/**
 * Gets the date formatted with the current locale (as set by `i18n`).
 *
 * If the date is in the current year, only day and month are returned.
 */
export const getDate = (dateTimeIso: string) => {
  const now = new Date();
  const sameYear: boolean =
    now.getFullYear() === new Date(dateTimeIso).getFullYear();

  return new Intl.DateTimeFormat(i18n.language, {
    day: "numeric",
    month: "long",
    year: sameYear ? undefined : "numeric",
  }).format(new Date(dateTimeIso));
};

export const isToday = (dateTimeIso: string) => {
  const formatter = new Intl.DateTimeFormat(i18n.language, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  return formatter.format(new Date(dateTimeIso)) === formatter.format();
};

const startOfDay = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const getDaysSince = (dateTimeIso: string) => {
  const inputStart = startOfDay(new Date(dateTimeIso));
  const today = startOfDay(new Date());

  const msDiff = today.getTime() - inputStart.getTime();
  return Math.round(msDiff / (1000 * 60 * 60 * 24));
};
