import { useTranslation } from "@ahlsell-group/app-localization";
import {
  Modal,
  ModalAction,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@ahlsell-group/ui-kit/feedback";
import React from "react";

export type ManualStockTakingConfirmBalanceModalResult =
  | "cancel"
  | "yes"
  | "no";

export interface ManualStockTakingConfirmBalanceModalProps {
  stockTakingQuantity: number;
  unit: string;
  onResult: (result: ManualStockTakingConfirmBalanceModalResult) => void;
}

export const ManualStockTakingConfirmBalanceModal: React.FC<ManualStockTakingConfirmBalanceModalProps> =
  function ({ stockTakingQuantity, unit, onResult }) {
    const { t } = useTranslation("common");

    return (
      <Modal
        open
        onCancel={() => onResult("cancel")}
        variant="card"
        overrideHtmlMetaThemeColor="backdrop-on-primary-surface-light"
      >
        <ModalHeader data-cy="ManualStockTakingConfirmBalanceModal">
          {t("stockTaking.confirmStockBalance")}
        </ModalHeader>
        <ModalContent className="text-left">
          <div>
            {t("stockTaking.stockTakingQuantity")}{" "}
            <strong>
              <span data-cy="ManualStockTakingConfirmBalanceModal-quantity">
                {stockTakingQuantity}
              </span>{" "}
              {unit}
            </strong>
          </div>
          <p>{t("stockTaking.isStockTakenQuantityCorrect")}</p>
        </ModalContent>
        <ModalActions orientation="horizontal">
          <ModalAction
            mode="button"
            variant="secondary"
            onClick={() => onResult("yes")}
            data-cy="ManualStockTakingConfirmBalanceModal-yes"
          >
            {t("yes")}
          </ModalAction>
          <ModalAction
            mode="button"
            onClick={() => onResult("no")}
            data-cy="ManualStockTakingConfirmBalanceModal-no"
          >
            {t("no")}
          </ModalAction>
        </ModalActions>
      </Modal>
    );
  };
