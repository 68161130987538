import React, {
  RefCallback,
  useContext,
  useLayoutEffect,
  useState,
} from "react";

const ScrollContext = React.createContext<Record<string, number>>({});

/**
 * React hook that stores and restores the scroll position of an element.
 *
 * ```tsx
 * // Example
 * function MyScrollableComponent() {
 *  const scrollContainerRef = useScrollPosition("MyScrollableComponent");
 *  return <div ref={scrollContainerRef}>...</div>;
 * }
 * ```
 *
 * @param id A unique identifier for the scrollable element that will be reused
 * when the element is rerendered in the future. For an element that only has a
 * single instance in the whole app, just use the component name.
 *
 * @returns A callback as a ref of the scrollable element.
 */
export const useScrollPosition = (id: string): RefCallback<HTMLElement> => {
  const scrollContext = useContext(ScrollContext);
  const [scrollContainer, setScrollContainer] = useState<HTMLElement | null>();

  useLayoutEffect(() => {
    if (!scrollContainer) return undefined;

    // After mounting, scroll to the previous value.
    scrollContainer.scrollTop = scrollContext[id];

    // Before unmounting, store the current value.
    return () => {
      scrollContext[id] = scrollContainer.scrollTop;
    };
  }, [id, scrollContainer, scrollContext]);

  return setScrollContainer;
};
