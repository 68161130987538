import React from "react";

import View from "../../routing/components/View";
import routes from "../../routing/routes";

import MyPageAbout from "./MyPageAbout";
import MyPageLanguage from "./MyPageLanguage";
import MyPageMenu from "./MyPageMenu";
import MyPageQuestionsAndAnswers from "./MyPageQuestionsAndAnswers";

const MyPageRoot: React.FC = function () {
  return (
    <>
      <View component={MyPageMenu} route={routes.myPage} exact />
      <View component={MyPageLanguage} route={routes.myPage.language} exact />
      <View
        component={MyPageQuestionsAndAnswers}
        route={routes.myPage.questionsAnswers}
        exact
      />
      <View component={MyPageAbout} route={routes.myPage.about} />
    </>
  );
};

export default MyPageRoot;
