import { useSelector } from "react-redux";

import { selectImageBaseUrl } from "../../app/appSelectors";

const useItemImageUrl = () => {
  const baseUrl = useSelector(selectImageBaseUrl);

  const creatorFunc = (assetPath?: string) =>
    assetPath
      ? `${baseUrl}/${assetPath}?preset=large`
      : "https://www.ahlsell.se/globalassets/site-images/kamera-400x400.jpg";

  return creatorFunc;
};

export default useItemImageUrl;
