import { useTranslation } from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import React from "react";
import { useSelector } from "react-redux";

import { selectLegalEntityId } from "../../../app/appSelectors";
import { getCentralWarehousesForLegalEntity } from "../centralWarehousesForLegalEntity";

import ItemStock from "./ItemStock";
import OtherWarehousesError from "./OtherWarehousesError";

interface CentralWarehousesItemStockProps {
  itemId: string;
}

const CentralWarehousesItemStock: React.FC<CentralWarehousesItemStockProps> =
  function ({ itemId }) {
    const { t } = useTranslation("common");
    const legalEntityId = useSelector(selectLegalEntityId);

    if (legalEntityId) {
      const centralWarehouses =
        getCentralWarehousesForLegalEntity(legalEntityId);

      return (
        <div data-cy="CentralWarehouses">
          <PageSection padding="x" className="pb-2">
            <Typography variant="form-label" color="gray">
              {t("itemStock.centralWarehouses", {
                count: centralWarehouses.length,
              })}
            </Typography>
          </PageSection>
          <ul data-cy="CentralWarehouses-list">
            {centralWarehouses.map((centralWarehouse) => (
              <li
                key={centralWarehouse.warehouseId}
                data-cy={`CentralWarehouses-warehouseId-${centralWarehouse.warehouseId}`}
              >
                <PageSection className="border-b">
                  <Typography
                    variant="body"
                    className="pb-1"
                    data-cy="CentralWarehouses-warehouseName"
                  >
                    {centralWarehouse.name}
                  </Typography>
                  <div className="pb-1">
                    <ItemStock
                      warehouseId={centralWarehouse.warehouseId}
                      itemId={itemId}
                      size="small"
                    />
                  </div>
                </PageSection>
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return (
      <div>
        <OtherWarehousesError
          title={t("itemStock.centralWarehouses", { count: 0 })}
          message={t("itemStock.fetchCentralWarehousesError")}
          data-cy="CentralWarehouses-error"
        />
      </div>
    );
  };

export default CentralWarehousesItemStock;
