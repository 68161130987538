import { call } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import ActionType from "../../../util/ActionType";
import { scanningModeChanged } from "../barcodeScannerSlice";

export default function* saveScanningModeSaga(
  { appConfigService }: ServiceContainer,
  { payload }: ActionType<typeof scanningModeChanged>
) {
  yield call([appConfigService, "setConfig"], "scanningMode", payload);
}
