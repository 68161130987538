import * as SDCBarcode from "scandit-web-datacapture-barcode";
import * as SDCCore from "scandit-web-datacapture-core";

import PromiseSource from "../../util/PromiseSource";

import {
  ScanningContext,
  ScanningContextImpl,
  ScanningContextInternal,
} from "./ScanningContext";

export interface BarcodeScannerService {
  getOrCreateScanningContext({
    element,
  }?: ScanningContextOptions): Promise<ScanningContext>;

  setElement(element: HTMLElement | null): void;
}

const isContextInitialized = (
  context: Partial<ScanningContextInternal>
): context is ScanningContextInternal => !!context.initPromiseSource;

export type ScanningContextOptions = {
  element?: HTMLElement;
};

export class BarcodeScannerService2Impl implements BarcodeScannerService {
  private context: Partial<ScanningContextInternal> = {};

  constructor(private readonly licenseKey: string) {}

  async getOrCreateScanningContext({
    element,
  }: ScanningContextOptions = {}): Promise<ScanningContext> {
    if (isContextInitialized(this.context)) {
      await this.context.initPromiseSource.promise;
      if (element) {
        this.context.view.connectToElement(element);
      }
      return new ScanningContextImpl(this.context);
    }
    this.context.initPromiseSource = new PromiseSource<void>();
    this.context.defaultElement = document.createElement("div");
    this.context.defaultElement.className = "hidden";
    document.body.appendChild(this.context.defaultElement);
    this.context.view = new SDCCore.DataCaptureView();
    this.context.view.connectToElement(element ?? this.context.defaultElement);

    this.context.view.setProgressBarMessage("Loading...");
    this.context.view.showProgressBar();

    await SDCCore.configure({
      licenseKey: this.licenseKey,
      libraryLocation: new URL("scandit/", document.baseURI).toString(),
      moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
    });

    this.context.view.setProgressBarPercentage(null);
    this.context.view.setProgressBarMessage("Accessing Camera...");

    this.context.context = await SDCCore.DataCaptureContext.create();
    await this.context.view.setContext(this.context.context);

    this.context.camera = SDCCore.Camera.default;
    this.context.cameraSettings =
      SDCBarcode.BarcodeCapture.recommendedCameraSettings;
    await this.context.camera.applySettings(this.context.cameraSettings);
    await this.context.context.setFrameSource(this.context.camera);

    this.context.settings = new SDCBarcode.BarcodeCaptureSettings();
    this.context.settings.enableSymbologies([
      SDCBarcode.Symbology.EAN8,
      SDCBarcode.Symbology.EAN13UPCA,
      SDCBarcode.Symbology.UPCE,
      SDCBarcode.Symbology.Code39,
      SDCBarcode.Symbology.Code128,
      SDCBarcode.Symbology.QR,
    ]);
    this.context.settings.codeDuplicateFilter = 500;

    this.context.barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(
      this.context.context,
      this.context.settings
    );
    await this.context.barcodeCapture.setEnabled(false);

    this.context.view.addControl(new SDCCore.CameraSwitchControl());

    this.context.barcodeCaptureOverlay =
      await SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
        this.context.barcodeCapture,
        this.context.view,
        SDCBarcode.BarcodeCaptureOverlayStyle.Frame
      );
    this.context.viewfinder = new SDCCore.RectangularViewfinder(
      SDCCore.RectangularViewfinderStyle.Square,
      SDCCore.RectangularViewfinderLineStyle.Light
    );
    await this.context.barcodeCaptureOverlay.setViewfinder(
      this.context.viewfinder
    );

    this.context.view.hideProgressBar();

    this.context.initPromiseSource.resolve();

    // At this point, we know it's fully initialized and ready
    return new ScanningContextImpl(this.context as ScanningContextInternal);
  }

  setElement(element: HTMLElement | null) {
    if (isContextInitialized(this.context)) {
      if (element !== null) {
        this.context.view.connectToElement(element);
      } else {
        this.context.view.detachFromElement();
      }
    } else {
      this.getOrCreateScanningContext({ element: element ?? undefined });
    }
  }
}
