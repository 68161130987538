import barcodeScannerRoutes from "../barcode-scanning/barcodeScannerRoutes";
import itemSearchRoutes from "../item-search/itemSearchRoutes";
import { createRoutes } from "../routing/utils";

const children = createRoutes({
  warehouse: { path: "/warehouse" },
  ...itemSearchRoutes,
  ...barcodeScannerRoutes,
} as const);

const homeRoutes = createRoutes({
  home: {
    path: "/",
    children,
  },
  // Weird quirk of Router5. When navigating to a route that's a direct child of the path '/', it won't find it.
  // By adding the same routes as siblings to '/' and forwarding them to its children makes it work
  ...Object.fromEntries(
    Object.entries(children).map(([key, value]) => [
      key,
      {
        ...value,
        forwardTo: `home.${key}`,
      },
    ])
  ),
} as const);

export default homeRoutes;
