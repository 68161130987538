import { AppConfig } from "@ahlsell-group/store20-config-service";
import { call } from "redux-saga/effects";

import { stepCompleted } from "../gettingStartedSlice";
import { Step } from "../types";

const welcomeStep: Step<"welcome"> = {
  type: "welcome",
  *preStepSaga({ appConfigService }) {
    const appConfig: AppConfig = yield call([appConfigService, "getAppConfig"]);
    if (appConfig.hasSeenWelcomePage) {
      return stepCompleted({ type: "welcome" });
    }
    return "input-required";
  },
  *saveResultSaga({ appConfigService }, { welcome }) {
    if (welcome) {
      yield call([appConfigService, "setConfig"], "hasSeenWelcomePage", true);
    }
  },
};

export default welcomeStep;
