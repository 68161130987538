import React from "react";
import { useDispatch } from "react-redux";

import ErrorModal from "../../error/components/ErrorModal";
import {
  clearDeleteItemError,
  DeleteItemError,
} from "../manualStockTakingSlice";

interface ManualStockTakingItemDeleteErrorModalProps {
  error: DeleteItemError;
}

const ManualStockTakingItemDeleteErrorModal: React.FC<ManualStockTakingItemDeleteErrorModalProps> =
  function ({ error }) {
    const dispatch = useDispatch();

    return (
      <ErrorModal
        category="stockTakingDeleteItem"
        error={{ reason: error }}
        onClose={() => dispatch(clearDeleteItemError())}
        data-cy="ManualStockTakingItemDeleteErrorModal"
      />
    );
  };

export default ManualStockTakingItemDeleteErrorModal;
