import { put } from "redux-saga/effects";
import { UAParser } from "ua-parser-js";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import { Browser, installDataLoaded, Platform } from "../installModeSlice";

function parsePlatform(uaOsName: string | undefined): Platform {
  switch (uaOsName?.toLowerCase()) {
    case "ios":
      return "ios";
    case "android":
      return "android";
    default:
      return "unknown";
  }
}

function parseBrowser(uaBrowserName: string | undefined): Browser {
  switch (uaBrowserName?.toLowerCase()) {
    case "mobile safari":
      return "safari";
    case "mobile chrome":
      return "chrome";
    default:
      return "other";
  }
}

export default function* loadInstallModeData({
  appInstallationService,
}: ServiceContainer): Saga {
  const ua = UAParser(navigator.userAgent);

  yield put(
    installDataLoaded({
      platform: parsePlatform(ua.os.name),
      browser: parseBrowser(ua.browser.name),
      isInPwaMode: appInstallationService.isInstalled(),
    })
  );
}
