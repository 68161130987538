import { useTranslation } from "@ahlsell-group/app-localization";
import { ScannerBarcodeIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { SearchField } from "@ahlsell-group/ui-kit/inputs";
import React from "react";

import routes from "../../routing/routes";
import useRoute from "../../routing/useRoute";

const HomeItemSearch: React.FC = function () {
  const { t } = useTranslation("common");
  const [, navigateToItemSearch] = useRoute(routes.home.itemSearch);
  const [, navigateToHomeScan] = useRoute(routes.home.scan);

  return (
    <>
      <Typography variant="heading-h3" className="mb-4">
        {t("itemInformation.itemDescription")}
      </Typography>
      <div className="flex">
        <SearchField
          active={false}
          color="white"
          className="w-full"
          placeholder={t("itemSearch.searchItem")}
          onClick={() => navigateToItemSearch()}
          readOnly
          data-cy="item-search"
          submitButtonProps={{
            "data-cy": "item-scan",
            type: "button",
            onClick: () => navigateToHomeScan(),
          }}
          submitIcon={ScannerBarcodeIcon}
        />
      </div>
    </>
  );
};

export default HomeItemSearch;
