import {
  PickStationWithLocations,
  UpdatedInventoryLocationItem,
  InventoryLocationItemInfo,
} from "@ahlsell-group/store20-inventory-location-service";
import { GenericOperationError } from "@ahlsell-group/store20-service-core";
import { createSelector } from "@reduxjs/toolkit";

import { Selector, State } from "../../types";

import {
  InventoryLocationItemWithDetails,
  InventoryLocationState,
  ItemLocationWithState,
} from "./inventoryLocationSlice";
import { Notification } from "./types";

export const selectLocationId: Selector<string | undefined> = (state) =>
  state.inventoryLocation.selectedLocationId;

export const selectPickStation: Selector<string | undefined> = (state) =>
  state.inventoryLocation.selectedPickStation;

export const selectPickStationsWithLocations: Selector<
  PickStationWithLocations[] | undefined
> = (state) => state.inventoryLocation.pickStationsWithLocations;

export const selectIsPickStationsWithLocationsLoading: Selector<boolean> = (
  state
) => state.inventoryLocation.isLocationsLoading;

export const selectLoadLocationsError: Selector<
  { reason: GenericOperationError } | undefined
> = (state) => state.inventoryLocation.loadLocationsError;

export const selectInventoryLocationItems: Selector<
  InventoryLocationItemInfo[]
> = (state) => state.inventoryLocation.addedItems;

export const selectInventoryLocationItemHasBeenAdded: (
  state: State,
  itemId: string
) => boolean = createSelector(
  selectInventoryLocationItems,
  (state: State, itemId: string) => itemId,
  (items, itemId) => items.some((x) => x.itemId === itemId)
);

export const selectInventoryLocationNewItemState: Selector<
  InventoryLocationState["newItem"]
> = (state) => state.inventoryLocation.newItem;

export const selectInventoryLocationNotification: Selector<
  Notification | undefined
> = (state) => state.inventoryLocation.notification;

export const selectInventoryLocationSubmitState: Selector<
  InventoryLocationState["submitState"]
> = (state) => state.inventoryLocation.submitState;

export const selectInventoryLocationSubmittedItems: Selector<
  UpdatedInventoryLocationItem[] | undefined
> = (state) =>
  state.inventoryLocation.submitState.type === "submitted"
    ? state.inventoryLocation.submitState.items
    : undefined;

export const selectItemLocation =
  (
    warehouseId: number,
    itemId: string
  ): Selector<ItemLocationWithState | undefined> =>
  (state) =>
    state.inventoryLocation.itemLocations[`${warehouseId}-${itemId}`];

export const selectIsPendingChangeAlertDismissed =
  (itemId: string): Selector<boolean> =>
  (state) =>
    state.inventoryLocation.dismissedPendingChangeAlerts.includes(itemId);

export const selectInventoryLocationChangeErrors: Selector<
  InventoryLocationItemWithDetails[]
> = (state) => state.inventoryLocation.changeErrors;
