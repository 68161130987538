import { Selector } from "../../types";

import { WarehouseCoordinates } from "./types";

export const selectWarehouseId: Selector<number> = (state) =>
  state.warehouse.warehouseId;

export const selectWarehouseName: Selector<string> = (state) =>
  state.warehouse.warehouseName ?? state.warehouse.warehouseId.toString();

export const selectWarehouseCoordinates: Selector<
  WarehouseCoordinates | undefined
> = (state) => state.warehouse.warehouseCoordinates;
