import React from "react";
import { useSelector } from "react-redux";

import { selectCurrentRoute } from "../../routing/routingSelectors";
import useRoute from "../../routing/useRoute";
import { ItemSearchRoutes } from "../itemSearchRoutes";
import { SearchMode } from "../types";

import ItemSearchInner from "./ItemSearchInner";

export interface ItemSearchProps {
  onSelect: (itemId: string) => void;
  routes: ItemSearchRoutes;
}

const ItemSearch: React.FC<ItemSearchProps> = function ({ onSelect, routes }) {
  const [isOpen] = useRoute(routes.itemSearch);
  const currentRoute = useSelector(selectCurrentRoute);
  const searchMode: SearchMode = currentRoute?.params?.mode ?? "free-text";
  const cameraError = currentRoute?.params?.cameraError;

  if (!isOpen) {
    return null;
  }

  return (
    <ItemSearchInner
      searchMode={searchMode}
      onSelect={onSelect}
      cameraError={cameraError}
    />
  );
};

export default ItemSearch;
