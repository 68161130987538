import React from "react";
import { useSelector } from "react-redux";

import ErrorModal from "../../error/components/ErrorModal";
import useNavigate from "../../routing/useNavigate";
import { selectSubmitStockTakesError } from "../manualStockTakingSelectors";

const ManualStockTakingSubmitErrorModal: React.FC = function () {
  const navigate = useNavigate();
  const submitError = useSelector(selectSubmitStockTakesError);

  if (!submitError) {
    return null;
  }

  return (
    <ErrorModal
      category="submitStockTake"
      error={submitError}
      fallbackErrorKey="unknown"
      onClose={() => navigate.back()}
      data-cy="ManualStockTakingSubmitErrorModal"
      data-error-reason={submitError.reason}
    />
  );
};

export default ManualStockTakingSubmitErrorModal;
