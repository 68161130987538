import i18n from "@ahlsell-group/app-localization";
import React from "react";
import { Store } from "redux";

import { ServiceContainer } from "../../app/serviceContainer";

export type DebugObject = {
  /** A service container with all the services used by the app. */
  serviceContainer?: ServiceContainer;

  /** The Redux store. */
  store?: Store;

  /** Whether to show preview features. */
  showPreview: boolean;

  /** The `i18next` object. */
  i18n: unknown;

  /** Trigger a globally uncaught error. */
  triggerGlobalError?: () => void;

  /** Trigger an error in a React render method. */
  triggerReactError?: () => void;

  /** Trigger an error in a saga. */
  triggerSagaError?: () => void;

  safeArea?: {
    /** Show safe areas on the left and right sides. */
    x(): void;
    /** Show safe areas on the top and bottom. */
    y(): void;
    /** Show safe areas on all sides. */
    all(): void;
    /** Don't show safe areas. */
    none(): void;
  };
};

export function createDebugObject(showPreview: boolean): DebugObject {
  const debugObject = {
    i18n,
    showPreview,
  };

  window.debug = debugObject;

  return debugObject;
}

export function getDebugObject(): DebugObject {
  return window.debug;
}

export function useDebugObject() {
  return React.useRef(getDebugObject());
}
