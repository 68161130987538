import { useTranslation } from "@ahlsell-group/app-localization";
import {
  Modal,
  ModalAction,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@ahlsell-group/ui-kit/feedback";
import React from "react";
import { useDispatch } from "react-redux";

import { triggerLoginRedirect } from "../../../app/appSlice";

interface ErrorAuthInteractionRequiredModalProps {
  scopes: string[];
}

const ErrorAuthInteractionRequiredModal: React.FC<ErrorAuthInteractionRequiredModalProps> =
  function ({ scopes }) {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();

    return (
      <Modal open variant="card">
        <ModalHeader>
          {t("errors.general.auth-interaction-required__header")}
        </ModalHeader>
        <ModalContent>
          {t("errors.general.auth-interaction-required__detail")}
        </ModalContent>
        <ModalActions>
          <ModalAction
            mode="button"
            onClick={() => dispatch(triggerLoginRedirect({ scopes }))}
            data-cy="ErrorModal-logIn"
          >
            {t("logIn")}
          </ModalAction>
        </ModalActions>
      </Modal>
    );
  };

export default ErrorAuthInteractionRequiredModal;
