import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectInventoryLocationNotification } from "../inventoryLocationSelectors";
import { hideNotification } from "../inventoryLocationSlice";

import InventoryLocationAlert from "./InventoryLocationAlert";

const InventoryLocationNotification: React.FC = function () {
  const notification = useSelector(selectInventoryLocationNotification);
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      if (notification) {
        dispatch(hideNotification());
      }
    },
    [notification, dispatch]
  );

  return notification ? (
    <InventoryLocationAlert notification={notification} />
  ) : null;
};

export default InventoryLocationNotification;
