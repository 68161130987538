import { useTranslation } from "@ahlsell-group/app-localization";
import { PageNav } from "@ahlsell-group/app-ui/PageNav";
import {
  HomeIcon,
  ListViewIcon,
  SalesContactIcon,
  ShelfLocationIcon,
} from "@ahlsell-group/ui-kit-imagery-react";
import React from "react";
import { useSelector } from "react-redux";

import { selectUserFirstName } from "../../../app/appSelectors";
import CoachMark from "../../home/components/CoachMark";
import { selectInventoryLocationChangeErrors } from "../../inventory-location/inventoryLocationSelectors";
import RoutingPageNavItem, {
  RoutingPageNavItemProps,
} from "../../routing/components/RoutingPageNavItem";
import routes from "../../routing/routes";
import useRoute from "../../routing/useRoute";
import { selectIsStockTakeInProgress } from "../../stock-taking/manualStockTakingSelectors";
import { selectHasStockTakeSubmissionWithProblem } from "../../stock-taking/stockTakenSubmissionSelectors";

const NavBar: React.FC = function () {
  const { t } = useTranslation("common");

  const name = useSelector(selectUserFirstName);
  const inventoryLocationErrors = useSelector(
    selectInventoryLocationChangeErrors
  );

  const [isShowingManualStockTake] = useRoute(routes.stockTaking.manual);
  const isStockTakeInProgress = useSelector(selectIsStockTakeInProgress);
  const hasStockTakeSubmissionWithProblem = useSelector(
    selectHasStockTakeSubmissionWithProblem
  );

  let stockTakingBadge: RoutingPageNavItemProps["badge"];

  if (hasStockTakeSubmissionWithProblem) {
    stockTakingBadge = "error";
  } else if (isStockTakeInProgress && !isShowingManualStockTake) {
    stockTakingBadge = "info";
  }

  return (
    <PageNav>
      <RoutingPageNavItem route={routes.home} icon={HomeIcon} data-cy="home">
        {t("navigation.start")}
      </RoutingPageNavItem>
      <CoachMark>
        <RoutingPageNavItem
          route={routes.stockTaking}
          icon={ListViewIcon}
          data-cy={`NavBar-stock-taking NavBar-stock-taking-badge-${
            stockTakingBadge ?? ""
          }`}
          badge={stockTakingBadge}
        >
          {t("navigation.stockTaking")}
        </RoutingPageNavItem>
      </CoachMark>
      <RoutingPageNavItem
        route={routes.inventoryLocation}
        icon={ShelfLocationIcon}
        data-cy="inventory-location-menu"
        data-cy-badge={inventoryLocationErrors.length !== 0 ? true : undefined}
        badge={inventoryLocationErrors.length !== 0 ? "error" : undefined}
      >
        {t("inventoryLocation.inventoryLocation_one")}
      </RoutingPageNavItem>
      <RoutingPageNavItem
        route={routes.myPage}
        icon={SalesContactIcon}
        data-cy="my-page"
      >
        {name ?? t("navigation.myPage")}
      </RoutingPageNavItem>
    </PageNav>
  );
};

export default NavBar;
