import {
  GetItemErrorReason,
  Item,
} from "@ahlsell-group/store20-product-service";
import { PayloadAction, createReducer, createSlice } from "@reduxjs/toolkit";

import navigatedFromSubTree from "../routing/navigatedFromSubTree";

type ItemRequiredPayload = { itemId: string } | { barcode: string };
export type LoadItemError = { reason: GetItemErrorReason };

export type IdleItemInformationState = {
  state: "idle";
};

export type LoadingItemInformationState = {
  state: "loading";
  search: ItemRequiredPayload;
};

export type ItemOpenItemInformationState = {
  state: "item-open";
  currentItem: Item;
};

export type ErrorItemInformationState = {
  state: "error";
  error: LoadItemError;
};

export type ItemInformationState =
  | IdleItemInformationState
  | LoadingItemInformationState
  | ItemOpenItemInformationState
  | ErrorItemInformationState;

const initialState = {
  state: "idle",
} satisfies ItemInformationState as ItemInformationState;

const name = "itemInformation";

const subSlices = {
  idle: createSlice({
    name,
    initialState,
    reducers: {
      itemRequired(state, { payload }: PayloadAction<ItemRequiredPayload>) {
        return {
          state: "loading",
          search: payload,
        };
      },
    },
  }),
  loading: createSlice({
    name,
    initialState,
    reducers: {
      itemReceived(_state, { payload }: PayloadAction<Item>) {
        return {
          state: "item-open",
          currentItem: payload,
        };
      },
      errorOccurred(
        _state,
        { payload }: PayloadAction<{ reason: GetItemErrorReason }>
      ) {
        return {
          state: "error",
          error: payload,
        };
      },
    },
  }),
  "item-open": undefined,
  error: undefined,
};

export const itemInformationReducer = createReducer(initialState, (builder) =>
  builder
    .addMatcher(navigatedFromSubTree("itemInformation"), () => initialState)
    .addDefaultCase((state, action) =>
      subSlices[state.state]?.reducer(state, action)
    )
);

export const {
  idle: {
    actions: { itemRequired },
  },
  loading: {
    actions: { itemReceived, errorOccurred },
  },
} = subSlices;
