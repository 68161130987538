import { StockTake } from "@ahlsell-group/store20-stock-taking-service";
import { createSelector } from "@reduxjs/toolkit";

import { Selector, State } from "../../types";

import { StockTakenSubmissionStatus } from "./stockTakenSubmissionSlice";
import { doesStockTakeHaveProblem } from "./util/doesStockTakeHaveProblem";

export const selectStockTakenSubmissionState: Selector<
  StockTakenSubmissionStatus
> = (state) => state.stockTakenSubmission.status;

const selectSubmittedStockTakes: Selector<StockTake[] | undefined> = (state) =>
  state.stockTakenSubmission.status.type === "loaded"
    ? state.stockTakenSubmission.status.submissions
    : undefined;

export const selectSubmittedStockTake: (
  state: State,
  stockTakeId: string
) => StockTake | undefined = createSelector(
  [
    selectSubmittedStockTakes,
    (_state: State, stockTakeId: string) => stockTakeId,
  ],
  (stockTakes, stockTakeId) =>
    stockTakes?.find((x) => x.stockTakeId === stockTakeId)
);

export const selectHasStockTakeSubmissionWithProblem: Selector<boolean> = (
  state
) =>
  state.stockTakenSubmission.status.type === "loaded" &&
  state.stockTakenSubmission.status.submissions.some((x) =>
    doesStockTakeHaveProblem(x)
  );
