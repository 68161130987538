import { Selector } from "../../types";

import { BackgroundUpdateStateLastPerformed } from "./backgroundUpdateSlice";

export const selectIsBackgroundUpdateRunning: Selector<boolean> = (state) =>
  state.backgroundUpdate.isRunning;

export const selectBackgroundUpdateTimestamps: Selector<
  BackgroundUpdateStateLastPerformed
> = (state) => state.backgroundUpdate.lastPerformed;
