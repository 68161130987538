import { useTranslation } from "@ahlsell-group/app-localization";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import React from "react";
import { useDispatch } from "react-redux";

import { stepCompleted } from "../gettingStartedSlice";
import { StepComponent } from "../types";

import GettingStartedPage from "./GettingStartedPage";

const InstallationPrompt: StepComponent = function InstallationPrompt({
  hasPreviousStep,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const onAction = (action: string) => {
    if (action === "install") {
      window.location.replace("/install?ref=getting-started");
    } else {
      dispatch(
        stepCompleted({ type: "installationPrompt", hasDismissed: true })
      );
    }
  };

  return (
    <GettingStartedPage
      data-cy="InstallationPrompt"
      actions={{
        install: {
          text: t("gettingStarted.installationInstructions.installButton"),
          primary: true,
          "data-cy": "InstallationPrompt-install",
        },
        skip: {
          text: t("gettingStarted.installationInstructions.skipButton"),
          "data-cy": "InstallationPrompt-skip",
        },
      }}
      onAction={onAction}
      backButton={hasPreviousStep}
    >
      <Typography variant="heading-h2" className="mt-16 text-center">
        {t("gettingStarted.installationInstructions.text")}
      </Typography>
    </GettingStartedPage>
  );
};

export default InstallationPrompt;
