/**
 * @description Returns unicode-strings for "m2" and "m3"
 */
const squareCubedToUnicode = (inputUnit: string) => {
  if (inputUnit.toLowerCase().trim() === "m2") {
    // Unicode Character 'SQUARE M SQUARED'
    return "\u33A1";
  }

  if (inputUnit.toLowerCase().trim() === "m3") {
    // Unicode Character 'SQUARE M CUBED'
    return "\u33A5";
  }

  return inputUnit;
};

export default squareCubedToUnicode;
