import { useTranslation } from "@ahlsell-group/app-localization";
import { InfoIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Alert } from "@ahlsell-group/ui-kit/feedback";
import React from "react";

import isNotFalse from "../../../util/isNotFalse";
import { usePurchaseHold } from "../usePurchaseHold";

export interface PurchaseHoldProps {
  stockId: number;
  itemId: string;
}

/**
 * Component that will display purchase hold info, fetching it if needed.
 *
 * If the item is not under purchase hold, nothing is shown.
 * If the info fails to load, no error is shown.
 */
export const PurchaseHold: React.FC<PurchaseHoldProps> = function ({
  stockId,
  itemId,
}) {
  const purchaseHold = usePurchaseHold(stockId, itemId);
  const { t } = useTranslation("common");

  if (
    purchaseHold?.state === "loaded" &&
    (purchaseHold.warehouse || purchaseHold.legalEntity || purchaseHold.group)
  ) {
    const locationKeys = [
      purchaseHold.warehouse && "stock",
      purchaseHold.legalEntity && "legalEntity",
      purchaseHold.group && "group",
    ].filter(isNotFalse);

    const locationTexts = [
      purchaseHold.warehouse && t("stockTaking.purchaseHoldWarehouse"),
      purchaseHold.legalEntity && t("stockTaking.purchaseHoldLegalEntity"),
      purchaseHold.group && t("stockTaking.purchaseHoldGroup"),
    ].filter(isNotFalse);

    return (
      <Alert
        severity="error"
        icon={InfoIcon}
        className="mb-2"
        data-cy-purchase-hold
        data-cy={`PurchaseHold-${locationKeys.join(",")}`}
      >
        <Typography variant="body-sm">
          {t("stockTaking.itemHasPurchaseHold", {
            locations: locationTexts,
          })}
        </Typography>
      </Alert>
    );
  }

  return null;
};
