import {
  Trans,
  transObject,
  useTranslation,
} from "@ahlsell-group/app-localization";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import {
  Modal,
  ModalAction,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@ahlsell-group/ui-kit/feedback";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import useNavigate from "../../routing/useNavigate";
import { selectWarehouseName } from "../warehouseSelectors";
import { reloadForWarehouseUpdateRequested } from "../warehouseSlice";

interface WarehouseModalProps {
  active: boolean;
}

const WarehouseModal: React.FC<WarehouseModalProps> = function ({ active }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const warehouseName = useSelector(selectWarehouseName);

  const close = () => navigate.back();

  const reload = () => {
    close().then(() => {
      dispatch(reloadForWarehouseUpdateRequested());
    });
  };

  return (
    <Modal variant="card" open={active} onCancel={close}>
      <ModalHeader data-cy="WarehouseModal">{t("myPage.store")}</ModalHeader>
      <ModalContent>
        <div>
          <Trans t={t} i18nKey="myPage.switchStoreBody">
            <strong className="whitespace-nowrap">
              {transObject({ store: warehouseName })}
            </strong>
          </Trans>
        </div>
        <Typography component="p" variant="body-detail" className="mt-4">
          {t("myPage.ifSwitchedTryReloadBody")}
        </Typography>
      </ModalContent>
      <ModalActions orientation="vertical">
        <ModalAction mode="button" onClick={close} data-cy="WarehouseModal-ok">
          {t("ok")}
        </ModalAction>
        <ModalAction
          mode="link-button"
          data-cy="WarehouseModal-reload"
          onClick={reload}
        >
          {t("reload")}
        </ModalAction>
      </ModalActions>
    </Modal>
  );
};

export default WarehouseModal;
