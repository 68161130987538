type MockServerConfig = Pick<
  RequiredBootstrapConfig,
  "apiBaseUrl" | "apiSubscriptionKey"
>;

export default ({
  env,
}: Pick<RequiredBootstrapConfig, "env">): MockServerConfig => {
  switch (env) {
    case "local":
      // Return dev mock server for debugging purposes.
      return {
        apiBaseUrl: "https://mock-server.ace.dev.azure.ahlsell.com",
        apiSubscriptionKey: "e74356d570734e0c952167eace52fed1;product=store20",
      };
    case "dev":
      return {
        apiBaseUrl: "https://mock-server.ace.dev.azure.ahlsell.com",
        apiSubscriptionKey: "e74356d570734e0c952167eace52fed1;product=store20",
      };
    case "test":
      return {
        apiBaseUrl: "https://mock-server.ace.test.azure.ahlsell.com",
        apiSubscriptionKey: "0263042b2fe946968c24a5b98deefc44;product=store20",
      };
    case "prd":
      return {
        apiBaseUrl: "https://mock-server.ace.azure.ahlsell.com",
        apiSubscriptionKey: "cabdde638e5c4a68ba43ecf9271acb90;product=store20",
      };
    default:
      throw new Error(`No config available for ${env}`);
  }
};
