import { call } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import { TransitionErrorAction } from "../types";

export default function* navErrorSaga(
  { logService }: ServiceContainer,
  action: TransitionErrorAction
): Saga {
  const { transitionError, previousRoute, route } = action.payload;

  const extra = () => ({
    path: window.location.pathname,
    previousRoute,
    route,
  });

  yield call(
    [logService, "log"],
    "error",
    "saga:routing:navErrorSaga:transitionError",
    {
      message: "Transition error",
      transitionErrorCode: transitionError?.code,
      transitionErrorPath: transitionError?.path,
      transitionError,
      // Only log additional info if path is missing.
      extra: transitionError?.path ? undefined : extra(),
    }
  );
}
