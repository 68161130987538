import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import ActionType from "../../../util/ActionType";
import { currentCoachMark, setCoachMark } from "../homeSlice";

export default function* dismissCoachMarkSaga(
  { storageService }: ServiceContainer,
  action: ActionType<typeof setCoachMark>
): Saga {
  if (action.payload === undefined) {
    storageService.setAndStringify({
      key: currentCoachMark.storageName,
      value: true,
    });
  }
}
