import { AccessTokenService } from "@ahlsell-group/store20-authentication-service";

export default class TenantScopeTokenServiceWrapper
  implements AccessTokenService
{
  constructor(
    private readonly inner: AccessTokenService,
    private readonly tenant: string
  ) {}

  private mapScopes(scopes: string[]): string[] {
    return scopes.map((scope) => `https://${this.tenant}/${scope}`);
  }

  getAccessToken(scopes: string[]): Promise<string> {
    return this.inner.getAccessToken(this.mapScopes(scopes));
  }

  ensureAccessToken(scopes: string[]): Promise<void> {
    return this.inner.ensureAccessToken(this.mapScopes(scopes));
  }
}
