import React from "react";

import ErrorModal from "../../error/components/ErrorModal";
import useNavigate from "../../routing/useNavigate";

const ManualStockTakingSubmitEmptyModal: React.FC = function () {
  const navigate = useNavigate();

  return (
    <ErrorModal
      category="stockTakingSubmit"
      error={{ reason: "empty-stock-take" }}
      onClose={() => navigate.back()}
      data-cy="ManualStockTakingSubmitEmptyModal"
    />
  );
};

export default ManualStockTakingSubmitEmptyModal;
