import { useTranslation } from "@ahlsell-group/app-localization";
import { InventoryLocationItemInfo } from "@ahlsell-group/store20-inventory-location-service";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Chip } from "@ahlsell-group/ui-kit/feedback";
import React from "react";

import ItemImage from "../../item-image/components/ItemImage";

interface InventoryLocationListItemProps {
  item: InventoryLocationItemInfo;
  onSelect?(): void;
  chipText?: string;
  disabled?: boolean;
}

const InventoryLocationListItem: React.FC<InventoryLocationListItemProps> =
  function ({ item, onSelect, chipText, disabled = false }) {
    const { t } = useTranslation("common");

    return (
      <div className="relative">
        <button
          disabled={disabled}
          type="button"
          className="relative flex flex-row items-stretch rounded w-full text-left p-4 mb-2 bg-white"
          onClick={() => onSelect?.()}
          data-cy={`InventoryLocationListItem-button-${item.itemId}`}
        >
          {chipText && (
            <div className="absolute top-0 right-0 m-1">
              <Chip color="warning">{chipText}</Chip>
            </div>
          )}

          <ItemImage assetUrl={item.assetUrl} alt="" size="small" />

          <Typography
            variant="body-sm"
            className="ml-4 flex-1 flex flex-col justify-between"
          >
            <div className="text-gray">{item.description1}</div>
            <div className="flex items-center">
              <div className="grow">
                {t("inventoryLocation.itemIdShort")} {item.itemId}
              </div>
            </div>
          </Typography>
        </button>
      </div>
    );
  };

export default InventoryLocationListItem;
