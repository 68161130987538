import { actions, actionTypes } from "redux-router5";
import { all, call, fork, spawn, take } from "redux-saga/effects";

import router from "../../features/routing/router";
import routes from "../../features/routing/routes";
import { TransitionSuccessAction } from "../../features/routing/types";
import allowServiceWorkerUpdate from "../../features/service-worker/sagas/allowServiceWorkerUpdate";
import { getItemByBarcodeSaga } from "../../features/stock-taking/sagas/getItemByBarcodeSaga";
import { getItemSaga } from "../../features/stock-taking/sagas/getItemSaga";
import { getSubmittedStockTakesSaga } from "../../features/stock-taking/sagas/getSubmittedStockTakesSaga";
import { Saga } from "../../types";
import { ServiceContainer } from "../serviceContainer";

type SagaFunc = (
  serviceContainer: ServiceContainer,
  action: TransitionSuccessAction
) => Saga;

const sagas: Record<string, SagaFunc[]> = {
  [routes.stockTaking.manual.item.route]: [getItemSaga],
  [routes.stockTaking.manual.item.barcode.route]: [getItemByBarcodeSaga],
  [routes.stockTaking.route]: [
    getSubmittedStockTakesSaga,
    allowServiceWorkerUpdate,
  ],

  [routes.home.route]: [allowServiceWorkerUpdate],
  [routes.inventoryLocation.route]: [allowServiceWorkerUpdate],
  [routes.myPage.route]: [allowServiceWorkerUpdate],
};

function* runNavSaga(
  serviceContainer: ServiceContainer,
  navigationAction: TransitionSuccessAction
) {
  const routeName = navigationAction.payload.route.name;
  if (sagas[routeName]) {
    yield all(
      sagas[routeName].map((saga) =>
        call(saga, serviceContainer, navigationAction)
      )
    );
  }
}

export function* startNavSaga(serviceContainer: ServiceContainer) {
  // Run any nav saga for the current route
  yield fork(
    runNavSaga,
    serviceContainer,
    actions.transitionSuccess(router.getState(), null)
  );

  // Start listening for transitions
  yield spawn(function* navSaga() {
    let previousRoute: string | null = null;
    while (true) {
      const action: TransitionSuccessAction = yield take(
        actionTypes.TRANSITION_SUCCESS
      );
      if (action.payload.route.name !== previousRoute) {
        yield fork(runNavSaga, serviceContainer, action);
      }
      previousRoute = action.payload.route.name;
    }
  });
}
