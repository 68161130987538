import i18n from "@ahlsell-group/app-localization";
import classNames from "classnames";
import React from "react";

export interface StockTakeQuantityProps {
  number: number | undefined;
  unit: string;
  /** Whether to always display the sign. */
  diff?: boolean;
  /** Whether to show the text in a warning color. */
  warn?: boolean;
  "data-cy"?: string;
}

export const StockTakeQuantity: React.FC<StockTakeQuantityProps> = function ({
  number,
  unit,
  diff,
  warn,
  "data-cy": dataCy = `StockTakeQuantity-${number}`,
}) {
  const formattedNumber =
    number === undefined
      ? "???"
      : Intl.NumberFormat(
          i18n.language,
          diff ? { signDisplay: "exceptZero" } : undefined
        ).format(number);

  return (
    <div
      className={classNames("min-w-[3.5rem] text-right tabular-nums", {
        "text-theme-status-attention": warn,
      })}
      data-cy={dataCy}
    >
      {formattedNumber} {unit}
    </div>
  );
};
