import i18n from "@ahlsell-group/app-localization";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Link } from "@ahlsell-group/ui-kit/navigation";
import classNames from "classnames";
import React from "react";
import ReactMarkdown from "react-markdown";

import aboutEn from "./content/about.en.md";
import aboutFi from "./content/about.fi.md";
import aboutNb from "./content/about.nb.md";
import aboutSv from "./content/about.sv.md";
import allowCameraAndroidEn from "./content/questionsAndAnswers/allowCameraAndroid.en.md";
import allowCameraAndroidFi from "./content/questionsAndAnswers/allowCameraAndroid.fi.md";
import allowCameraAndroidNb from "./content/questionsAndAnswers/allowCameraAndroid.nb.md";
import allowCameraAndroidSv from "./content/questionsAndAnswers/allowCameraAndroid.sv.md";
import allowCameraIosEn from "./content/questionsAndAnswers/allowCameraIos.en.md";
import allowCameraIosFi from "./content/questionsAndAnswers/allowCameraIos.fi.md";
import allowCameraIosNb from "./content/questionsAndAnswers/allowCameraIos.nb.md";
import allowCameraIosSv from "./content/questionsAndAnswers/allowCameraIos.sv.md";
import cannotScanEn from "./content/questionsAndAnswers/cannotScan.en.md";
import cannotScanFi from "./content/questionsAndAnswers/cannotScan.fi.md";
import cannotScanNb from "./content/questionsAndAnswers/cannotScan.nb.md";
import cannotScanSv from "./content/questionsAndAnswers/cannotScan.sv.md";
import chooseBrowserEn from "./content/questionsAndAnswers/chooseBrowser.en.md";
import chooseBrowserFi from "./content/questionsAndAnswers/chooseBrowser.fi.md";
import chooseBrowserNb from "./content/questionsAndAnswers/chooseBrowser.nb.md";
import chooseBrowserSv from "./content/questionsAndAnswers/chooseBrowser.sv.md";
import mfaEn from "./content/questionsAndAnswers/mfa.en.md";
import mfaFi from "./content/questionsAndAnswers/mfa.fi.md";
import mfaNb from "./content/questionsAndAnswers/mfa.nb.md";
import mfaSv from "./content/questionsAndAnswers/mfa.sv.md";
import updateBrowserEn from "./content/questionsAndAnswers/updateBrowser.en.md";
import updateBrowserFi from "./content/questionsAndAnswers/updateBrowser.fi.md";
import updateBrowserNb from "./content/questionsAndAnswers/updateBrowser.nb.md";
import updateBrowserSv from "./content/questionsAndAnswers/updateBrowser.sv.md";

type Language = "en" | "fi" | "nb" | "sv";

type LanguageMap = {
  [P in Language]: string;
};

// If a text hasn't been translated yet, add an automatic translation.
const questionsAndAnswers: Record<string, LanguageMap> = {
  mfa: {
    en: mfaEn,
    fi: mfaFi,
    nb: mfaNb,
    sv: mfaSv,
  },
  "update-browser": {
    en: updateBrowserEn,
    fi: updateBrowserFi,
    nb: updateBrowserNb,
    sv: updateBrowserSv,
  },
  "choose-browser": {
    en: chooseBrowserEn,
    fi: chooseBrowserFi,
    nb: chooseBrowserNb,
    sv: chooseBrowserSv,
  },
  "cannot-scan": {
    en: cannotScanEn,
    fi: cannotScanFi,
    nb: cannotScanNb,
    sv: cannotScanSv,
  },
  "allow-camera-android": {
    en: allowCameraAndroidEn,
    fi: allowCameraAndroidFi,
    nb: allowCameraAndroidNb,
    sv: allowCameraAndroidSv,
  },
  "allow-camera-ios": {
    en: allowCameraIosEn,
    fi: allowCameraIosFi,
    nb: allowCameraIosNb,
    sv: allowCameraIosSv,
  },
};

const general: Record<string, LanguageMap> = {
  about: {
    en: aboutEn,
    fi: aboutFi,
    nb: aboutNb,
    sv: aboutSv,
  },
};

const pages = { general, questionsAndAnswers };

const extractParts = (markdown: string | undefined) => {
  if (!markdown) return undefined;

  const lineEnd = markdown.indexOf("\n");

  return {
    header: markdown.slice(0, lineEnd).replace("##", "").trim(),
    detailMarkdown: markdown.slice(lineEnd).trim(),
  };
};

export const getQuestionHeaders = () => {
  const lang = i18n.language as Language;
  return Object.entries(questionsAndAnswers)
    .map(([key, value]) => ({
      slug: key,
      header: extractParts(value[lang])?.header,
    }))
    .filter(({ header }) => Boolean(header));
};

export const getPage = (category: keyof typeof pages, slug: string) => {
  const lang = i18n.language as Language;
  const markdown = pages[category][slug]?.[lang];
  const parts = extractParts(markdown);

  if (!parts) {
    return undefined;
  }

  // Prevent top margin in the first Markdown element, bottom margin in the
  // last Markdown element. However, nested elements (e.g. <p> inside <li>)
  // should still keep their vertical margins.
  const blockClasses = classNames(
    "group-[&]/markdown:first:mt-0",
    "group-[&]/markdown:last:mb-0"
  );

  return {
    header: parts.header,
    detail: (
      <div className="group/markdown">
        <ReactMarkdown
          components={{
            h3: ({ children }) => (
              <Typography
                variant="heading-h3"
                className={classNames("mt-6 mb-2", blockClasses)}
              >
                {children}
              </Typography>
            ),
            p: ({ children }) => (
              <p className={classNames("my-4", blockClasses)}>{children}</p>
            ),
            ol: ({ children }) => (
              <ol
                className={classNames("list-decimal pl-6 my-4", blockClasses)}
              >
                {children}
              </ol>
            ),
            ul: ({ children }) => (
              <ul className={classNames("list-disc pl-6 my-4", blockClasses)}>
                {children}
              </ul>
            ),
            a: ({ href, children }) => (
              <Link variant="inline" target="_blank" href={href!}>
                {children}
              </Link>
            ),
          }}
        >
          {parts.detailMarkdown}
        </ReactMarkdown>
      </div>
    ),
  };
};
