import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  DistributedTracingModes,
  IApplicationInsights,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";

import {
  ApplicationInsightsSessionInfo,
  AppTelemetrySessionInfo,
  TelemetryProvider,
} from "./types";
import { getWarehouseLabel } from "./utils";

export class ApplicationInsightsTelemetryProvider implements TelemetryProvider {
  private readonly internalAppInsights: ApplicationInsights;
  private props: ApplicationInsightsSessionInfo;
  private originalProps: AppTelemetrySessionInfo;

  public get applicationInsights(): IApplicationInsights {
    return this.internalAppInsights;
  }

  constructor(
    private readonly connectionString: string,
    initialProps: AppTelemetrySessionInfo
  ) {
    this.originalProps = { ...initialProps };
    this.props = this.toAppInsightsProps(initialProps);
    const appInsightsReactPlugin = new ReactPlugin();
    const applicationInsights = new ApplicationInsights({
      config: {
        connectionString: this.connectionString,
        extensions: [appInsightsReactPlugin],
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        disableFetchTracking: false,
        distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
        enableCorsCorrelation: true,
        excludeRequestFromAutoTrackingPatterns: [
          // `enableCorsCorrelation: true` above adds tracing HTTP headers to
          // every request. LogRocket understandably doesn't support this.
          // This setting is used to prevent App Insights from messing up the
          // LogRocket requests.
          /^https:\/\/r\.lr-in-prod\.com\//,
        ],
        extensionConfig: {
          [appInsightsReactPlugin.identifier]: {},
        },
      },
    });

    applicationInsights.loadAppInsights();

    applicationInsights.context.application.ver = initialProps.appVersion;
    applicationInsights.addTelemetryInitializer((x) =>
      this.addSessionDataTelemetryInitializer(x)
    );
    applicationInsights.addTelemetryInitializer((x) =>
      this.removeSessionObserverLogsTelemetryInitializer(x)
    );

    this.internalAppInsights = applicationInsights;
    this.handleSessionInfoUpdate();
  }

  private toAppInsightsProps(
    props: AppTelemetrySessionInfo
  ): ApplicationInsightsSessionInfo {
    return {
      pwaMode: props.pwaMode,
      viewport: `${props.viewport[0]}x${props.viewport[1]}`,
      employeeId: props.employeeId?.toString(),
      warehouseId: props.warehouseId?.toString(),
      warehouseName: props.warehouseName?.toString(),
      warehouse: getWarehouseLabel(props),
      legalEntityId: props.legalEntityId?.toString(),
    };
  }

  private addSessionDataTelemetryInitializer(item: ITelemetryItem) {
    // Add warehouse data as custom data.
    if (item.data) {
      Object.assign(item.data, this.props);
    } else {
      // eslint-disable-next-line no-param-reassign
      item.data = { ...this.props };
    }

    // Add 'Cloud role', in order to distinguish between telemetry sources.
    // eslint-disable-next-line no-param-reassign
    item.tags ??= [];
    // eslint-disable-next-line no-param-reassign
    item.tags["ai.cloud.role"] = window.location.host;
  }

  private removeSessionObserverLogsTelemetryInitializer(item: ITelemetryItem) {
    const exceptions: any[] | undefined = item.baseData?.exceptions;
    return !(
      exceptions &&
      exceptions.some(
        (e) =>
          typeof e.message === "string" &&
          e.message.includes("ResizeObserver loop limit exceeded")
      )
    );
  }

  updateSessionInfo(props: Partial<AppTelemetrySessionInfo>): void {
    Object.assign(this.originalProps, props ?? {});
    this.props = this.toAppInsightsProps(this.originalProps);
    this.handleSessionInfoUpdate();
  }

  private handleSessionInfoUpdate() {
    if (this.props.employeeId) {
      this.internalAppInsights.setAuthenticatedUserContext(
        this.props.employeeId
      );
    }
  }
}
