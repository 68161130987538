import React, { useContext } from "react";

import { ServiceContainer } from "../serviceContainer";

export const serviceContainerContext = React.createContext<
  ServiceContainer | undefined
>(undefined);

export type ServiceContainerProviderProps = React.PropsWithChildren<{
  serviceContainer: ServiceContainer;
}>;

export const ServiceContainerProvider: React.FC<ServiceContainerProviderProps> =
  function ServiceContainerProvider({ serviceContainer, children }) {
    return (
      <serviceContainerContext.Provider value={serviceContainer}>
        {children}
      </serviceContainerContext.Provider>
    );
  };

export default function useServiceContainer(): ServiceContainer {
  const serviceContainer = useContext(serviceContainerContext);
  if (!serviceContainer) {
    throw new Error(
      "'useServiceContainer' can only be used in components that are children of a 'ServiceContainerProvider'"
    );
  }
  return serviceContainer;
}
