import { ConfigManager } from "@ahlsell-group/store20-service-core";
import LogRocket from "logrocket";

import { AppTelemetrySessionInfo, TelemetryProvider } from "./types";
import { deleteUndefined, getWarehouseLabel } from "./utils";

export default class LogRocketTelemetryProvider implements TelemetryProvider {
  private props: AppTelemetrySessionInfo;

  constructor(
    private readonly configManager: ConfigManager<BootstrapConfig>,
    initialProps: AppTelemetrySessionInfo
  ) {
    this.props = { ...initialProps };
  }

  updateSessionInfo(props: Partial<AppTelemetrySessionInfo>): void {
    this.props = Object.assign(this.props, props);
    this.updateLogRocket();
  }

  private updateLogRocket() {
    const {
      logRocketEnabled,
      appVersion,
      employeeId,
      legalEntityId,
      name,
      email,
      pwaMode,
    } = this.props;
    if (logRocketEnabled) {
      LogRocket.init(this.configManager.getConfig().logRocketAppId, {
        release: appVersion,
        shouldSendData: () => !!this.props?.logRocketEnabled,
      });

      if (employeeId) {
        LogRocket.identify(
          employeeId,
          deleteUndefined({
            name,
            email,
            warehouse: getWarehouseLabel(this.props ?? {}),
            legalEntityId,
            pwaMode,
          })
        );
      }
    }
  }
}
