import { Router } from "router5";

export default interface RoutingService {
  start(): void;
  getRouter(): Router;
}

export class RoutingServiceImplementation implements RoutingService {
  constructor(private readonly router: Router) {}

  start() {
    this.initializeWithoutError();
    this.router.start();
  }

  getRouter() {
    return this.router;
  }

  /**
   * When loading the app, you never want to see an error page. Therefore, we
   * remove the final `/error` segment of the URL.
   *
   * Loading the app from an error URL can happen in the following cases:
   *
   * - The user reloads the page.
   * - An `auth-interaction-error` occurs, and the browser will be redirected
   *   back to the error URL.
   *
   * Example:
   *
   * `/stock-taking/manual/item-search/error` is replaced by
   * `/stock-taking/manual/item-search`.
   */
  private initializeWithoutError() {
    if (window.location.pathname.endsWith("/error")) {
      const newPath = window.location.href.replace(/\/error($|[?#])/, "$1");
      window.history.replaceState({}, "", newPath);
    }
  }
}
