import { AppConfig } from "@ahlsell-group/store20-config-service";
import { Capacitor } from "@capacitor/core";
import { call, select } from "redux-saga/effects";

import {
  selectCompletedSteps,
  selectLastCompletedStep,
  selectStepsWithInputRequired,
} from "../gettingStartedSelectors";
import {
  GettingStartedState,
  stepCompleted,
  StepType,
} from "../gettingStartedSlice";
import { Step } from "../types";

const persistenceKey = "installation-prompt";
const installationPromptStep: Step<"installationPrompt"> = {
  type: "installationPrompt",
  *preStepSaga({
    appConfigService,
    appInstallationService,
    reduxPersistenceService,
  }) {
    const appConfig: AppConfig = yield call([appConfigService, "getAppConfig"]);
    if (
      appInstallationService.isInstalled() ||
      Capacitor.isNativePlatform() ||
      appConfig.hasDismissedInstallationPrompt
    ) {
      return stepCompleted({
        type: "installationPrompt",
        hasDismissed: appConfig.hasDismissedInstallationPrompt,
      });
    }
    const lastCompletedStep: StepType | "none" = yield select(
      selectLastCompletedStep
    );
    const stepsWithInputRequired: StepType[] = yield select(
      selectStepsWithInputRequired
    );
    const steps: GettingStartedState["steps"] = yield select(
      selectCompletedSteps
    );
    yield call([reduxPersistenceService, "persist"], persistenceKey, {
      gettingStarted: { lastCompletedStep, stepsWithInputRequired, steps },
    });
    return "input-required";
  },
  *postStepSaga({ reduxPersistenceService }) {
    yield call([reduxPersistenceService, "remove"], persistenceKey);
  },
  *saveResultSaga({ appConfigService }, { installationPrompt }) {
    if (installationPrompt) {
      yield call(
        [appConfigService, "setConfig"],
        "hasDismissedInstallationPrompt",
        installationPrompt.hasDismissed
      );
    }
  },
};

export default installationPromptStep;
