import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";

import { ItemSearchError, ItemSearchResult, SearchMode } from "./types";

export const itemSearchStatuses = ["idle", "loading"] as const;
export type ItemSearchStatuses = (typeof itemSearchStatuses)[number];
export type ItemSearchQuery = {
  term: string;
  searchMode: SearchMode;
};

export interface ItemSearchState {
  status: ItemSearchStatuses;
  result?: ItemSearchResult;
  error?: ItemSearchError;
}

export const initialState: ItemSearchState = {
  status: "idle",
};

const name = "itemSearch";

export const itemSearchQueryEntered = createAction<ItemSearchQuery>(
  `${name}/queryEntered`
);

const subSlices = {
  idle: createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers(builder) {
      builder.addCase(itemSearchQueryEntered, (state, { payload: query }) => {
        if (query.term === "") {
          state.result = undefined;
        } else {
          state.status = "loading";
        }
      });
    },
  }),
  loading: createSlice({
    name,
    initialState,
    reducers: {
      itemSearchResultReceived(
        state,
        { payload: result }: PayloadAction<ItemSearchResult>
      ) {
        state.status = "idle";
        state.result = result;
        state.error = undefined;
      },
      itemSearchFailed(
        state,
        { payload: error }: PayloadAction<ItemSearchError>
      ) {
        state.status = "idle";
        state.result = undefined;
        state.error = error;
      },
    },
    extraReducers(builder) {
      builder.addCase(itemSearchQueryEntered, (state, { payload: query }) => {
        if (query.term === "") return initialState;

        return state;
      });
    },
  }),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    itemSearchExited() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addDefaultCase((state, action) =>
      subSlices[state.status].reducer(state, action)
    );
  },
});

export const {
  reducer: itemSearchReducer,
  actions: { itemSearchExited },
} = slice;

export const {
  loading: {
    actions: { itemSearchResultReceived, itemSearchFailed },
  },
} = subSlices;
