import { ArrowRightIcon } from "@ahlsell-group/ui-kit-imagery-react";
import classNames from "classnames";
import React from "react";

interface AppBoxedAreaProps extends React.HTMLAttributes<HTMLElement> {
  href?: string;
  padding?: boolean;
}

const AppBoxedArea: React.FC<AppBoxedAreaProps> = function ({
  children,
  href,
  className,
  padding = true,
  ...rest
}) {
  const icon =
    href || rest.onClick ? <ArrowRightIcon size="small" /> : undefined;

  const styles = classNames(
    "min-h-[4rem] rounded bg-white flex items-center justify-between gap-4 text-left",
    { "p-4": padding },
    className
  );

  const wrappedChildren = (
    <div className="basis-0 grow max-w-full">{children}</div>
  );

  if (href) {
    return (
      <a href={href} className={styles} {...rest}>
        {wrappedChildren}
        {icon}
      </a>
    );
  }

  if (rest.onClick) {
    return (
      <button type="button" className={styles} {...rest}>
        {wrappedChildren}
        {icon}
      </button>
    );
  }

  return (
    <div className={styles} {...rest}>
      {wrappedChildren}
    </div>
  );
};

export default AppBoxedArea;
