import { useTranslation } from "@ahlsell-group/app-localization";
import { ExclamationIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Alert } from "@ahlsell-group/ui-kit/feedback";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectShowIosUpdateAlert } from "../homeSelectors";
import { dismissUpdateIosAlert } from "../homeSlice";

const UpdateIosAlert: React.FC = function () {
  const { t } = useTranslation("common");
  const showAlert = useSelector(selectShowIosUpdateAlert);
  const dispatch = useDispatch();

  if (!showAlert) {
    return null;
  }

  return (
    <Alert
      className="mt-4"
      severity="info"
      icon={ExclamationIcon}
      onDismiss={() => dispatch(dismissUpdateIosAlert())}
    >
      <Typography variant="body-sm">
        <strong>{t("temp.havingCameraProblems")}</strong> <br />
        {t("temp.updateIos")}
      </Typography>
    </Alert>
  );
};

export default UpdateIosAlert;
