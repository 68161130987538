export const failedItemStatuses = [
  "InvalidItemId",
  "UpdateFailed",
  "UnknownError",
] as const;

export const successItemStatuses = ["Ok"] as const;

export type SuccessItemStatuses = (typeof successItemStatuses)[number];

export type FailedItemStatuses = (typeof failedItemStatuses)[number];

export function isSuccessItemStatus(
  itemStatus: SuccessItemStatuses | FailedItemStatuses | undefined
) {
  return (
    successItemStatuses as readonly (
      | SuccessItemStatuses
      | FailedItemStatuses
      | undefined
    )[]
  ).includes(itemStatus);
}

export function isFailedItemStatus(
  itemStatus: SuccessItemStatuses | FailedItemStatuses | undefined
) {
  return !(
    successItemStatuses as readonly (
      | SuccessItemStatuses
      | FailedItemStatuses
      | undefined
    )[]
  ).includes(itemStatus);
}

export function toLowerIncludes(str: string, query: string): boolean {
  return str.toLowerCase().includes(query.toLowerCase());
}
