import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useSplashScreen from "../../../app/hooks/useSplashScreen";
import View from "../../routing/components/View";
import routes from "../../routing/routes";
import { selectInstallModeState } from "../installModeSelectors";
import { installDataRequired, setInstallPrompt } from "../installModeSlice";

import InstallAndroid from "./InstallAndroid";
import InstallIos from "./InstallIos";
import InstallLandingPage from "./InstallLandingPage";

const InstallModeRoot: React.FC = function InstallModeRoot() {
  const { hideSplashScreen } = useSplashScreen();
  const dispatch = useDispatch();
  const installModeState = useSelector(selectInstallModeState);

  useEffect(() => {
    hideSplashScreen();
  }, [hideSplashScreen]);

  useEffect(() => {
    if (installModeState.state === "empty") {
      dispatch(installDataRequired());
    }
  }, [installModeState, dispatch]);

  useEffect(() => {
    const handleInstallPrompt = (event: Event) => {
      event.preventDefault();
      dispatch(setInstallPrompt(event));
    };
    window.addEventListener("beforeinstallprompt", handleInstallPrompt);
    return () => {
      window.removeEventListener("beforeinstallprompt", handleInstallPrompt);
    };
  }, [dispatch]);

  return (
    <>
      <View route={routes.install} exact component={InstallLandingPage} />
      <View route={routes.install.ios} exact component={InstallIos} />
      <View route={routes.install.android} exact component={InstallAndroid} />
    </>
  );
};

export default InstallModeRoot;
