import { Image } from "@ahlsell-group/ui-kit/data-display";
import classNames from "classnames";
import React, { ImgHTMLAttributes } from "react";

import useItemImageUrl from "../useItemImageUrl";

export interface ItemImageProps
  extends Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> {
  assetUrl: string;
  alt: string;
  size: "small" | "large" | "real" | "tiny";
  /** Extra classes on the outer `<div>`. */
  className?: string;
}

/**
 * A square item image.
 */
const ItemImage: React.FC<ItemImageProps> = function ({
  assetUrl,
  alt,
  className,
  size,
  ...rest
}) {
  const url = useItemImageUrl()(assetUrl);

  return (
    <div
      className={classNames(
        "inline-flex items-center justify-center overflow-hidden",
        className,
        {
          "w-8 h-8": size === "tiny",
          "w-14 h-14": size === "small",
          "w-28 h-28": size === "large",
        }
      )}
    >
      <Image src={url} alt={alt} className="max-w-full max-h-full" {...rest} />
    </div>
  );
};

export default ItemImage;
