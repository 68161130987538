import { AccessTokenService } from "@ahlsell-group/store20-authentication-service";
import { ItemService } from "@ahlsell-group/store20-product-service";
import {
  ConfigManager,
  StorageService,
} from "@ahlsell-group/store20-service-core";
import {
  StockTake,
  StockTakingService,
  AxiosStockTakingServiceNew,
  AxiosStockTakingServiceLegacy,
} from "@ahlsell-group/store20-stock-taking-service";

import { selectVivaldiEmployeeNumber } from "../../app/appSelectors";
import StoreAccessor from "../../app/redux/StoreAccessor";
import { selectHasFeatureFlag } from "../config/configSelectors";
import { selectWarehouseId } from "../warehouse/warehouseSelectors";

import { stockTakeReviewToggled } from "./manualStockTakingSlice";

const errorFromPreviousCall = "ERROR_FROM_PREV_CALL";

export class StockTakingServiceFacade implements StockTakingService {
  private readonly newService: StockTakingService;
  private readonly oldService: StockTakingService;

  private current?: {
    stockId: number;
    featureFlagEnablesNew: boolean;
    service:
      | StockTakingService
      | Promise<StockTakingService | typeof errorFromPreviousCall>;
  };

  constructor(
    private readonly storeAccessor: StoreAccessor,
    itemService: ItemService,
    storageService: StorageService,
    accessTokenService: AccessTokenService,
    configManager: ConfigManager<BootstrapConfig>
  ) {
    this.newService = new AxiosStockTakingServiceNew(
      accessTokenService,
      configManager
    );

    this.oldService = new AxiosStockTakingServiceLegacy(
      accessTokenService,
      storageService,
      configManager,
      itemService,
      () => selectVivaldiEmployeeNumber(storeAccessor.getStore().getState())
    );
  }

  private getStockId() {
    return selectWarehouseId(this.storeAccessor.getStore().getState());
  }

  private getFeatureFlagEnablesNew() {
    return selectHasFeatureFlag(
      this.storeAccessor.getStore().getState(),
      "StockTaking.Review"
    );
  }

  private async selectService(
    stockId: number,
    featureFlagEnablesNew: boolean
  ): Promise<{ service: StockTakingService; stockTake: StockTake }> {
    const oldStockTake = await this.oldService.getOrCreateManualStockTake(
      stockId
    );
    if (oldStockTake.items.length) {
      return { service: this.oldService, stockTake: oldStockTake };
    }

    const newStockTake = await this.newService.getOrCreateManualStockTake(
      stockId
    );
    if (newStockTake.items.length) {
      return { service: this.newService, stockTake: newStockTake };
    }

    return featureFlagEnablesNew
      ? { service: this.newService, stockTake: newStockTake }
      : { service: this.oldService, stockTake: oldStockTake };
  }

  /**
   * Use mode `recheck-after` for all operations that can result in the current
   * stock-take being empty (current after this call).
   */
  private async call<T>(
    mode: "cache" | "recheck-after",
    fn: (service: StockTakingService) => Promise<T>
  ): Promise<T> {
    const stockId = this.getStockId();
    const featureFlagEnablesNew = this.getFeatureFlagEnablesNew();

    let currentService: StockTakingService | typeof errorFromPreviousCall;

    if (
      this.current?.stockId === stockId &&
      this.current.featureFlagEnablesNew === featureFlagEnablesNew
    ) {
      currentService = await this.current.service;

      if (currentService === errorFromPreviousCall) {
        // Error from previous call. Try once again for this call.
        return this.call(mode, fn);
      }
    } else {
      const currentServicePromise = this.selectService(
        stockId,
        featureFlagEnablesNew
      );

      // Synchronously set the value, so that following calls won't call
      // `selectService` unnecessarily.
      this.current = {
        stockId,
        featureFlagEnablesNew,
        service: currentServicePromise
          .then((x) => x.service)
          .catch(() => {
            // If the current promise fails, unset `this.current`, so that future
            // requests will try to make the request again.
            this.current = undefined;

            // Prevent unhandled promise errors by not throwing an error in
            // this promise. Instead, return `errorFromPreviousCall`, which is
            // manually handled when needed above.
            return errorFromPreviousCall;
          }),
      };

      const selectedService = await currentServicePromise;

      currentService = selectedService.service;

      // Update the value, for easier debugging.
      this.current.service = currentService;

      // eslint-disable-next-line no-console
      console.log("[StockTakingServiceFacade]", this.current);

      this.storeAccessor.getStore().dispatch(
        stockTakeReviewToggled({
          isReviewEnabled: currentService === this.newService,
          stockTake: selectedService.stockTake,
        })
      );
    }

    const result = await fn(currentService);

    if (mode === "recheck-after") {
      this.current = undefined;
    }

    return result;
  }

  getOrCreateManualStockTake(stockId: number) {
    return this.call("cache", (x) => x.getOrCreateManualStockTake(stockId));
  }

  getItem(stockId: number, stockTakeId: string, itemId: string) {
    return this.call("cache", (x) => x.getItem(stockId, stockTakeId, itemId));
  }

  updateItemStockTakingQuantity(request: {
    stockId: number;
    stockTakeId: string;
    itemId: string;
    quantity: number;
    comment?: string | undefined;
  }) {
    return this.call("cache", (x) => x.updateItemStockTakingQuantity(request));
  }

  deleteItem(stockId: number, stockTakeId: string, itemId: string) {
    return this.call("recheck-after", (x) =>
      x.deleteItem(stockId, stockTakeId, itemId)
    );
  }

  reviewStockTake(stockId: number, stockTakeId: string) {
    return this.call("cache", (x) => x.reviewStockTake(stockId, stockTakeId));
  }

  submitStockTake(
    stockId: number,
    stockTakeId: string,
    enableAutomaticCompletion: boolean
  ) {
    return this.call("recheck-after", (x) =>
      x.submitStockTake(stockId, stockTakeId, enableAutomaticCompletion)
    );
  }

  /** Get submitted stock-takes from both the old and new services. */
  async getSubmittedStockTakes(
    stockId: number,
    fromDateTime: Date
  ): Promise<StockTake[]> {
    const newStockTakes = await this.newService.getSubmittedStockTakes(
      stockId,
      fromDateTime
    );

    const oldStockTakes = await this.oldService.getSubmittedStockTakes(
      stockId,
      fromDateTime
    );

    return newStockTakes
      .concat(oldStockTakes)
      .sort(
        (a, b) =>
          new Date(b.submittedDateTimeUtc ?? 0).getTime() -
          new Date(a.submittedDateTimeUtc ?? 0).getTime()
      );
  }

  deleteAllItems(stockId: number, stockTakeId: string): Promise<void> {
    return this.call("recheck-after", (x) =>
      x.deleteAllItems(stockId, stockTakeId)
    );
  }
}
