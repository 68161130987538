import { TypeGuard } from "@reduxjs/toolkit/dist/tsHelpers";

import { TransitionSuccessAction } from "./types";
import { isTransitionSuccessAction } from "./utils";

export default (subTreeRootName: string): TypeGuard<TransitionSuccessAction> =>
  (action): action is TransitionSuccessAction =>
    isTransitionSuccessAction(action) &&
    action.payload.previousRoute?.name.startsWith(subTreeRootName) &&
    !action.payload.route.name.startsWith(subTreeRootName);
