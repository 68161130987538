import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { userLoginSucceeded } from "../../app/appSlice";

import { WarehouseCoordinates } from "./types";

interface StockPayload {
  name: string;
  coordinates?: WarehouseCoordinates;
}

export interface WarehouseState {
  warehouseId: number;
  warehouseName?: string;
  warehouseCoordinates?: WarehouseCoordinates;
}

const initialState: WarehouseState = {
  warehouseId: 0,
};

const slice = createSlice({
  initialState,
  name: "warehouse",
  reducers: {
    reloadForWarehouseUpdateRequested() {},
    userWarehouseInfoUpdated(state, { payload }: PayloadAction<StockPayload>) {
      state.warehouseName = payload.name;
      state.warehouseCoordinates = payload.coordinates;
    },
  },
  extraReducers(builder) {
    builder.addCase(userLoginSucceeded, (state, action) => {
      state.warehouseId = action.payload.warehouseId;
    });
  },
});

export const {
  reducer: warehouseReducer,
  actions: { reloadForWarehouseUpdateRequested, userWarehouseInfoUpdated },
} = slice;
