import { useTranslation } from "@ahlsell-group/app-localization";
import {
  Modal,
  ModalAction,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@ahlsell-group/ui-kit/feedback";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ViewComponentProps } from "../../routing/components/View";
import useNavigate from "../../routing/useNavigate";
import { selectInventoryLocationItems } from "../inventoryLocationSelectors";
import { clearInventoryLocationItems } from "../inventoryLocationSlice";

const InventoryLocationClearItemsModal: React.FC<ViewComponentProps> =
  function ({ params }) {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const items = useSelector(selectInventoryLocationItems);

    if (params.modal !== "clearConfirmation") {
      return null;
    }

    return (
      <Modal onCancel={() => navigate.back()} open variant="card">
        <ModalHeader data-cy="InventoryLocationClearItemsModal">
          {t("inventoryLocation.clearList")}
        </ModalHeader>
        <ModalContent>
          {t("inventoryLocation.clearListDetails", { count: items.length })}
        </ModalContent>
        <ModalActions>
          <ModalAction
            mode="button"
            variant="tertiary"
            onClick={() => navigate.back()}
            data-cy="InventoryLocationClearItemsModal-cancel"
          >
            {t("cancel")}
          </ModalAction>
          <ModalAction
            mode="button"
            variant="secondary"
            onClick={() => {
              dispatch(clearInventoryLocationItems());
              navigate.back();
            }}
            data-cy="InventoryLocationClearItemsModal-clear"
          >
            {t("inventoryLocation.clear")}
          </ModalAction>
        </ModalActions>
      </Modal>
    );
  };

export default InventoryLocationClearItemsModal;
