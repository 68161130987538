import {
  GenericOperationError,
  OperationFailedError,
} from "@ahlsell-group/store20-service-core";

export type ErrorActionPayload<
  TReason extends string,
  TExtra extends Record<string, unknown> = {}
> = {
  reason: TReason | GenericOperationError;
  data?: Record<string, unknown>;
} & TExtra;

function toErrorActionPayload<
  TReason extends string,
  TExtra extends Record<string, unknown> = {}
>(error: unknown, extra?: TExtra): ErrorActionPayload<TReason, TExtra>;
function toErrorActionPayload(error: unknown, extra?: any) {
  if (error instanceof OperationFailedError) {
    return {
      reason: error.type,
      data: error.data,
      ...extra,
    };
  }

  return {
    reason: "unknown",
    ...extra,
  };
}

export default toErrorActionPayload;
