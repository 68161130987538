import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectItemStock } from "./itemStockSelectors";
import { ItemStockInfo, stockBalanceRequired } from "./itemStockSlice";

const useItemStock = (
  warehouseId: number,
  itemId: string
): [ItemStock: ItemStockInfo | undefined, refresh: () => void] => {
  const dispatch = useDispatch();
  const itemStock = useSelector(selectItemStock(warehouseId, itemId));

  const refresh = useCallback(() => {
    dispatch(stockBalanceRequired({ warehouseId, itemId }));
  }, [dispatch, warehouseId, itemId]);

  useEffect(() => {
    if (itemStock === undefined || itemStock.state === "expired") {
      refresh();
    }
  }, [itemStock, refresh]);

  return [itemStock, refresh];
};

export default useItemStock;
