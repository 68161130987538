import { InventoryLocationItemInfo } from "@ahlsell-group/store20-inventory-location-service";
import { call, select } from "redux-saga/effects";

import { selectVivaldiEmployeeNumber } from "../../../app/appSelectors";
import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import ActionType from "../../../util/ActionType";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import {
  selectInventoryLocationItems,
  selectLocationId,
  selectPickStation,
} from "../inventoryLocationSelectors";
import { submissionsReceived } from "../inventoryLocationSlice";

export default function* persistInventoryLocationChangeSaga(
  { inventoryLocationService }: ServiceContainer,
  action: ActionType<typeof submissionsReceived>
): Saga {
  const warehouseId = yield select(selectWarehouseId);
  const vivaldiEmployeeNumber = yield select(selectVivaldiEmployeeNumber);
  const pickStation = yield select(selectPickStation);
  const locationId = yield select(selectLocationId);
  const items: InventoryLocationItemInfo[] = yield select(
    selectInventoryLocationItems
  );

  // Clear localStorage from info on successful submit.
  if (action.type === submissionsReceived.type) {
    yield call(
      [inventoryLocationService, "deleteSavedInventoryLocationChange"],
      warehouseId,
      vivaldiEmployeeNumber
    );
    return;
  }

  yield call(
    [inventoryLocationService, "persistInventoryLocationChange"],
    warehouseId,
    vivaldiEmployeeNumber,
    items,
    pickStation,
    locationId
  );
}
