import { configureStore } from "@reduxjs/toolkit";
import LogRocket from "logrocket";
import { Store } from "redux";
import { router5Middleware } from "redux-router5";
import { SagaMiddleware } from "redux-saga";

import catchErrorMiddleware from "../../features/error/catchErrorMiddleware";
import { State } from "../../types";
import { ServiceContainer } from "../serviceContainer";

import rootReducer from "./rootReducer";

export default async function createStore(
  sagaMiddleware: SagaMiddleware,
  serviceContainer: ServiceContainer
): Promise<Store<State>> {
  return configureStore({
    reducer: rootReducer,
    preloadedState: await serviceContainer.reduxPersistenceService.restore(),
    devTools: true,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({ thunk: false }),
      sagaMiddleware,
      router5Middleware(serviceContainer.routingService.getRouter()),
      catchErrorMiddleware(serviceContainer.logService),
      LogRocket.reduxMiddleware(),
    ],
  });
}
