import { call, put } from "redux-saga/effects";

import ActionType from "../../util/ActionType";
import { languageChanged, languageChangeRequested } from "../appSlice";
import { ServiceContainer } from "../serviceContainer";

export default function* changeLanguageSaga(
  { localizationService, appConfigService }: ServiceContainer,
  action: ActionType<typeof languageChangeRequested>
) {
  yield call([localizationService, "changeLanguage"], action.payload);
  yield call(
    [appConfigService, "setConfig"],
    "selectedLanguage",
    action.payload
  );
  yield put(languageChanged(action.payload));
}
