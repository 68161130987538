import { useTranslation, autonyms } from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { PageSubheadline } from "@ahlsell-group/app-ui/PageSubheadline";
import { CheckIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { BoxedLinkButton } from "@ahlsell-group/ui-kit/navigation";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectCurrentLanguage } from "../../../app/appSelectors";
import { languageChangeRequested } from "../../../app/appSlice";
import { AppPage } from "../../page/components/AppPage";
import useNavigate from "../../routing/useNavigate";

const MyPageLanguage: React.FC = function () {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(selectCurrentLanguage);

  return (
    <AppPage
      data-cy="MyPageLanguage"
      headline={
        <PageSubheadline
          back-data-cy="MyPageLanguage-back"
          onBack={() => navigate.back()}
        >
          {t("myPage.language")}
        </PageSubheadline>
      }
    >
      <PageSection className="gap-4">
        {Object.entries(autonyms).map(([langCode, langName]) => (
          <BoxedLinkButton
            key={langCode}
            onClick={() => dispatch(languageChangeRequested(langCode))}
            size="large"
            data-cy={`MyPageLanguage-${langCode}`}
            icon={currentLanguage === langCode ? CheckIcon : undefined}
            iconProps={
              currentLanguage === langCode
                ? { "data-cy": `MyPageLanguage-CheckIcon-${langCode}` }
                : undefined
            }
          >
            {langName}
          </BoxedLinkButton>
        ))}
      </PageSection>
    </AppPage>
  );
};

export default MyPageLanguage;
